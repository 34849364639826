import React, { useState } from "react";

import {
  Stack,
  Chip,
  Dialog,
  DialogContent,
  DialogActions,
  Typography,
  Divider,
  IconButton,
  Button,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";

import InstagramIcon from "@mui/icons-material/Instagram";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import ContactSupportRoundedIcon from "@mui/icons-material/ContactSupportRounded";

export const HelpChip = () => {
  const [openModal, setOpenModal] = useState(false);
  const handleOpenModal = () => {
    setOpenModal(true);
  };
  const handleCloseModal = () => {
    setOpenModal(false);
  };

  return (
    <div>
      <ListItemButton onClick={handleOpenModal}>
        <ListItemIcon>
          <ContactSupportRoundedIcon />
        </ListItemIcon>
        <ListItemText
          primary={
            <Typography flex={1} variant="body2" sx={{ fontWeight: 600 }}>
              Contact us
            </Typography>
          }
        />
      </ListItemButton>

      <Dialog open={openModal} onClose={handleCloseModal} fullWidth>
        <DialogContent>
          <Stack spacing={2}>
            <Typography variant="h5">Help us improve!</Typography>
            <Typography>
              First, we want to extend a heartfelt thank you for your support in
              our early days. Your feedback has been invaluable in shaping our
              platform, and we couldn't have come this far without your trust
              and encouragement.
            </Typography>
            <Typography>
              Please don't hesitate to reach out to us if you need any
              assistance or have any suggestions. We are always here to help and
              are committed to providing you with the best experience possible.
            </Typography>
            <Typography>
              Once again, thank you for being a part of our journey. We look
              forward to growing your business with you.
            </Typography>

            <Divider />
            <Typography variant="h6">Connect with us</Typography>
            <Typography>
              There are several ways to contact us depending on your
              preferences. If you have specific questions or feedback for us,
              you can reach out to us directly through Instagram DM or email. We
              look forward to hearing from you!
            </Typography>
            <Stack direction="row">
              <IconButton
                size="large"
                onClick={() =>
                  window.open(
                    "https://www.instagram.com/flowly.cookies/",
                    "_blank"
                  )
                }
              >
                <InstagramIcon />
              </IconButton>
              <IconButton
                size="large"
                onClick={() => {
                  window.location.href = "mailto:admin@flowlyapp.com";
                }}
              >
                <AlternateEmailIcon />
              </IconButton>
            </Stack>
          </Stack>
        </DialogContent>
      </Dialog>
    </div>
  );
};
