import { FormikProps } from "formik";
import { BusinessInfo } from "Model/BusinessInfo";

// ui related
import {
  FormControl,
  RadioGroup,
  Radio,
  FormControlLabel,
  FormHelperText,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { FormSection } from "Components/BusinessView/FormSection";
import { CustomerRequestInfo } from "Model/CustomerRequestInfo";

export const CustomerFlavor = ({
  businessInfo,
  customerFormik,
}: {
  businessInfo: BusinessInfo;
  customerFormik: FormikProps<CustomerRequestInfo>;
}) => {
  if (businessInfo.showFlavors) {
    return (
      <FormSection id="flavor" sectionName="Flavor">
        {
          <FormControl
            fullWidth
            required
            error={
              customerFormik.touched.flavors &&
              Boolean(customerFormik.errors.flavors)
            }
          >
            <FormControl fullWidth>
              <Select
                value={customerFormik.values.flavors}
                onChange={(e) => {
                  customerFormik.setFieldValue("flavors", [e.target.value]);
                }}
                sx={{
                  "& .MuiOutlinedInput-input.MuiSelect-select": {
                    whiteSpace: "normal",
                  },
                }}
              >
                {businessInfo.flavors.map((flavor, index) => (
                  <MenuItem
                    value={flavor}
                    key={index}
                    sx={{ whiteSpace: "normal" }}
                  >
                    {flavor}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormHelperText>
              {customerFormik.touched.flavors && customerFormik.errors.flavors}
            </FormHelperText>
          </FormControl>
        }
      </FormSection>
    );
  } else {
    return null;
  }
};
