// Define types for the color palettes
export interface ColorPalette {
  [key: number]: string;
}

export const como: ColorPalette = {
  10: "#F8F8F7",
  20: "hsla(154, 24%, 36%, 5%)",
  50: "#e3f1ed",
  100: "#bcdcd2",
  200: "#94c6b6",
  300: "#71b09b",
  400: "#5e9f88",
  500: "#528e77",
  600: "#4c816c",
  700: "hsl(154, 24%, 36%)",
  800: "#3e6151",
  900: "#31453a",
};
export const ferra: ColorPalette = {
  10: "hsl(347, 28%, 97.7%)",

  20: "hsla(347, 13%, 49%, 5%)",
  500: `hsl(347, 13%, ${49 + 7}%)`,
  700: "hsl(347, 13%, 49%)",
  800: `hsl(347, 13%, ${49 - 4}%)`,
};
export const eclipse: ColorPalette = {
  10: "hsl(0, 0%, 97.7%)",

  20: "hsla(0, 0%, 30%, 5%)",
  500: `hsl(0, 80%, ${30 + 7}%)`,
  700: "hsl(0, 0%, 30%)",
  800: `hsl(0, 0%, ${30 - 4}%)`,
};
export const black: ColorPalette = {
  10: "hsl(0, 0%, 97.7%)",

  20: "hsla(0, 0%, 0%, 5%)",
  500: "hsl(0, 0%, 7%)",
  700: "hsl(0, 0%, 0%)",
  800: "hsl(0, 0%, 0%)",
};

export const sorbus: ColorPalette = {
  10: "hsl(14, 28%, 97.7%)",

  20: "hsla(14, 80%, 55%, 5%)",
  500: `hsl(14, 80%, ${55 + 7}%)`,
  700: "hsl(14, 80%, 55%)",
  800: `hsl(14, 80%, ${55 - 4}%)`,
};
export const darkPink: ColorPalette = {
  10: "hsl(340, 28%, 97.7%)",

  20: "hsla(340, 80%, 62%, 5%)",
  500: `hsl(340, 80%, ${62 + 7}%)`,
  700: "hsl(340, 80%, 62%)",
  800: `hsl(340, 80%, ${62 - 4}%)`,
};
export const denim: ColorPalette = {
  10: "hsl(210, 28%, 97.7%)",

  20: "hsla(210, 80%, 46%, 5%)",
  500: `hsl(210, 80%, ${46 + 7}%)`,
  700: "hsl(210, 80%, 46%)",
  800: `hsl(210, 80%, ${46 - 4}%)`,
};
export const blueViolet: ColorPalette = {
  10: "hsl(277, 28%, 97.7%)",

  20: "hsla(277, 80%, 46%, 5%)",
  500: `hsl(277, 80%, ${46 + 7}%)`,
  700: "hsl(277, 80%, 46%)",
  800: `hsl(277, 80%, ${46 - 4}%)`,
};

export const contessa: ColorPalette = {
  10: "hsl(14, 28%, 97.7%)",

  20: "hsla(14, 35%, 55%, 5%)",
  500: `hsl(14, 35%, ${55 + 7}%)`,
  700: "hsl(14, 35%, 55%)",
  800: `hsl(14, 35%, ${55 - 4}%)`,
};
export const royalHeath: ColorPalette = {
  10: "hsl(340, 28%, 97.7%)",

  20: "hsla(340, 35%, 55%, 5%)",
  500: `hsl(340, 35%, ${55 + 7}%)`,
  700: "hsl(340, 35%, 55%)",
  800: `hsl(340, 35%, ${55 - 4}%)`,
};
export const danube: ColorPalette = {
  10: "hsl(210, 28%, 97.7%)",

  20: "hsla(210, 35%, 55%, 5%)",
  500: `hsl(210, 35%, ${55 + 7}%)`,
  700: "hsl(210, 35%, 55%)",
  800: `hsl(210, 35%, ${55 - 4}%)`,
};
export const deepLilac: ColorPalette = {
  10: "hsl(277, 28%, 97.7%)",

  20: "hsla(277, 35%, 55%, 5%)",
  500: `hsl(277, 35%, ${55 + 7}%)`,
  700: "hsl(277, 35%, 55%)",
  800: `hsl(277, 35%, ${55 - 4}%)`,
};

// Map all the available palettes using an object
export const allPalettes: Record<string, ColorPalette> = {
  como,
  ferra,
  eclipse,
  black,
  sorbus,
  darkPink,
  denim,
  blueViolet,
  contessa,
  royalHeath,
  danube,
  deepLilac,
  // Add more palettes here if you have others
  // paletteName: paletteObject,
};
