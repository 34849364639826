import { useEffect } from "react";
import { FormikProps } from "formik";
import { BusinessInfo } from "Model/BusinessInfo";
import { useAppSelector } from "Redux/hooks";
import { businessInfoSelector } from "Redux/selectors";
import _ from "lodash";

// ui related
import {
  Radio,
  FormControl,
  InputAdornment,
  List,
  ListItem,
  Button,
  OutlinedInput,
  IconButton,
  FormHelperText,
} from "@mui/material";
import { FormSection } from "Components/BusinessView/FormSection";
import { FormEditor } from "Components/BusinessView/FormEditor";

// icons
import DeleteIcon from "@mui/icons-material/Delete";

export const Packaging = ({
  formik,
}: {
  formik: FormikProps<BusinessInfo>;
}) => {
  const getErrors = (errors: any) => {
    return Array.isArray(errors)
      ? errors.filter(
          (email: string, i: number, arr: any) => arr.indexOf(email) === i
        )
      : errors;
  };

  if (formik.values.showPackagings) {
    return (
      <FormSection id="packaging" sectionName="Packaging">
        <FormEditor visible>
          <List disablePadding>
            {formik.values.packagings.map((packaging, index) => (
              <ListItem key={index} disableGutters>
                <FormControl variant="outlined" fullWidth size="small">
                  <OutlinedInput
                    required
                    multiline
                    placeholder="Option"
                    value={formik.values.packagings[index]}
                    onChange={(e) => {
                      let tempPackagings = _.cloneDeep(
                        formik.values.packagings
                      );
                      tempPackagings[index] = e.target.value;
                      formik.setFieldValue("packagings", tempPackagings);
                    }}
                    error={
                      formik.values.packagings[index] === "" &&
                      formik.touched.packagings &&
                      Boolean(formik.errors.packagings)
                    }
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => {
                            let tempPackagings = _.cloneDeep(
                              formik.values.packagings
                            );
                            tempPackagings.splice(index, 1);
                            formik.setFieldValue("packagings", tempPackagings);
                          }}
                          disabled={formik.values.packagings.length === 1}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                  {formik.values.packagings[index] === "" &&
                    formik.touched.packagings &&
                    Boolean(formik.errors.packagings) && (
                      <FormHelperText error>
                        {formik.values.packagings[index] === "" &&
                          formik.touched.packagings &&
                          getErrors(formik.errors.packagings as any)}
                      </FormHelperText>
                    )}
                </FormControl>
              </ListItem>
            ))}
          </List>
          <Button
            variant="text"
            onClick={() => {
              let tempPackagings = formik.values.packagings;
              formik.setFieldValue("packagings", tempPackagings.concat([""]));
            }}
            sx={{ width: "fit-content" }}
          >
            Add option
          </Button>
        </FormEditor>
      </FormSection>
    );
  } else {
    return null;
  }
};
