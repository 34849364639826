// ui related
import { TextField } from "@mui/material";
import { FormSection } from "Components/BusinessView/FormSection";

export const Questions = () => {
  return (
    <FormSection id="questions" sectionName="Questions/comments">
      <TextField fullWidth multiline id="questions" disabled />
    </FormSection>
  );
};
