import { useNavigate, useParams } from "react-router-dom";

// assets

import {
  Typography,
  Stack,
  Button,
  Box,
  Divider,
  AppBar,
  Toolbar,
  ThemeProvider,
} from "@mui/material";
import {
  ContentWrapNarrow,
  CreatedWith,
  CardStyle,
  InfoBox,
} from "Components/AllComponents";
import { createCustomTheme } from "Components/Styles/Styles";
import { PresaleItems } from "Pages/CustomerView/CustomerPresale/Components/PresaleItems";
import { useSession } from "Context/userAuthContext";
import { useAppDispatch, useAppSelector } from "Redux/hooks";
import { selectPresaleById } from "Redux/selectors";
import _ from "lodash";
import {
  defaultPresaleInfo,
  formatFulfillments,
  getPresaleName,
} from "Model/PresaleInfo";
import { PresaleForm } from "Pages/CustomerView/CustomerPresale/Components/PresaleForm";
import { useEffect, useState } from "react";
import { initializeCart } from "Redux/actionCreators";

import EditIcon from "@mui/icons-material/Edit";

const PresalePreview = () => {
  const { presaleId } = useParams<"presaleId">();
  const { sellerProfile } = useSession();
  const presale =
    useAppSelector((state) => selectPresaleById(state, presaleId || "")) ||
    defaultPresaleInfo(
      sellerProfile.defaultPickupAddress ? true : false,
      sellerProfile.payments.map((p) => p.method)
    );
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  // Need to initialize cart, using redux
  useEffect(() => {
    dispatch(initializeCart(presale.presaleItems));
  }, [presale]);

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  return (
    <ThemeProvider theme={createCustomTheme(sellerProfile.colorPalette)}>
      <Stack
        spacing={3}
        sx={{
          p: { xs: 2, sm: 3 },
          backgroundColor: "background.default",
        }}
      >
        <AppBar>
          <Toolbar variant="dense">
            <Typography
              variant="subtitle2"
              color="text.primary"
              component="div"
              flex={1}
            >
              Presale preview
            </Typography>
            <Stack direction="row" spacing={1}>
              <Button
                startIcon={<EditIcon />}
                variant="contained"
                onClick={() => {
                  navigate(`/admin/presales/${presale.presaleId}#items`);
                }}
              >
                Edit presale
              </Button>
            </Stack>
          </Toolbar>
        </AppBar>

        <Box sx={{ pt: { xs: 2, sm: 1 } }}>
          <ContentWrapNarrow>
            <Stack
              direction="row"
              justifyContent="center"
              alignItems="center"
              spacing={1}
              sx={{
                mb: 2,
              }}
            ></Stack>
            <CardStyle>
              <Stack>
                <Typography variant="h5" sx={{ textAlign: "center", mb: 1 }}>
                  {getPresaleName(presale.presaleName)}
                </Typography>
                <Typography
                  variant="body1"
                  color="text.secondary"
                  textAlign="center"
                  sx={{ mb: 2, whiteSpace: "pre-line" }}
                >
                  {presale.presaleDescription}
                </Typography>
                <InfoBox>
                  <Typography
                    variant="subtitle2"
                    color="text.secondary"
                    textAlign="center"
                  >
                    {formatFulfillments(presale.fulfillments)}
                  </Typography>
                </InfoBox>
              </Stack>
            </CardStyle>
          </ContentWrapNarrow>
        </Box>

        <PresaleItems presaleItems={presale.presaleItems} />
        <Divider />
        <Box>
          <ContentWrapNarrow>
            <PresaleForm
              presaleInfo={presale}
              editing={true}
              sellerProfile={sellerProfile}
            />
          </ContentWrapNarrow>
        </Box>

        <CreatedWith color="dark" />
      </Stack>
    </ThemeProvider>
  );
};

export default PresalePreview;
