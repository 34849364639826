import { useState } from "react";
import { useSession } from "Context/userAuthContext";
import { logOut, publishProfile } from "Util/firebaseHelper";
import { Formik, Form } from "formik";
import { profileValidationSchema } from "Model/Profile";
import { editSellerProfile } from "Redux/actionCreators";
import { useAppDispatch } from "Redux/hooks";

import {
  Button,
  TextField,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  Typography,
  InputAdornment,
  Stack,
} from "@mui/material";
import { InfoBox } from "Components/AllComponents";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

type Props = {
  isModalOpen: boolean;
  openModal: () => void;
  closeModal: () => void;
};

export const AccountSettings = ({
  isModalOpen,
  openModal,
  closeModal,
}: Props) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const { user } = useSession();

  return (
    <div>
      <Dialog
        fullWidth
        fullScreen={fullScreen}
        open={isModalOpen}
        onClose={closeModal}
      >
        <DialogTitle>Account</DialogTitle>
        <DialogContent dividers>
          <Typography variant="subtitle2">Google account</Typography>
          <Stack direction="row" alignItems="center">
            <Typography variant="body1" flex={1}>
              {user?.email}
            </Typography>
            <Button
              variant="outlined"
              onClick={() => {
                logOut();
                window.location.reload();
              }}
            >
              Sign out
            </Button>
          </Stack>
          <InfoBox sx={{ mt: 3 }}>
            <Typography>
              If you wish to delete your account, please email
              admin@flowlyapp.com with the Gmail account to be deleted.
            </Typography>
          </InfoBox>
        </DialogContent>
        <DialogActions>
          <Button color="secondary" onClick={closeModal}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
