import { CustomerRequestInfo } from "Model/CustomerRequestInfo";
import { OrderInfo } from "Model/OrderInfo";
import Resizer from "react-image-file-resizer";
import USStates from "Components/USStates.json";

export const getStateAbbreviation = (stateName: string): string => {
  const state = USStates.find(
    (state) => state.name.toLowerCase() === stateName.toLowerCase()
  );
  return state ? state.abbreviation : stateName;
};

export const getStateName = (abbreviation: string): string => {
  const state = USStates.find(
    (state) => state.abbreviation.toLowerCase() === abbreviation.toLowerCase()
  );
  return state ? state.name : abbreviation;
};

// a little function to help us with reordering the result
export const reorder = <T>(
  list: T[],
  startIndex: number,
  endIndex: number
): T[] => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

// A little helper function to reorder a list
export const reorder2 = (
  list: any[],
  startIndex: number,
  endIndex: number
): any[] => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

export const resizeFile = (file: File, maxWidth: number, maxHeight: number) =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      maxWidth,
      maxHeight,
      "PNG",
      100,
      0,
      (uri) => {
        resolve(uri);
      },
      "file"
    );
  });

export const convertRequestToOrder = (
  formId: string,
  requestInfo: CustomerRequestInfo
): OrderInfo => {
  const o: OrderInfo = {
    formId: formId,
    requestId: requestInfo.requestId,
    orderCreated: "",
    requestCreated: requestInfo.created,
    orderTotal: requestInfo.orderTotal,
    paid: 0,
    orderId: "",
    status: "",
    identifier: "",
    notes: "",
    firstName: requestInfo.firstName,
    lastName: requestInfo.lastName,
    email: requestInfo.email,
    phoneNumber: requestInfo.phoneNumber,
    preferredContact: requestInfo.preferredContact,
    customerDate: requestInfo.customerDate,
    customerFulfillment: requestInfo.customerFulfillment,
    shippingAddress1: requestInfo.shippingAddress1,
    shippingAddress2: requestInfo.shippingAddress2,
    shippingCity: requestInfo.shippingCity,
    shippingState: requestInfo.shippingState,
    shippingZip: requestInfo.shippingZip,
    event: requestInfo.event,
    theme: requestInfo.theme,
    colors: requestInfo.colors,
    personalization: requestInfo.personalization,
    count: requestInfo.count,
    questions: requestInfo.questions,
    paymentChecked: requestInfo.paymentChecked,
    bucketLists: requestInfo.bucketLists,
    flavors: requestInfo.flavors,
    packagings: requestInfo.packagings,
    designInspirations: requestInfo.designInspirations,
    unit: requestInfo.unit,
    updated: requestInfo.updated,
    created: requestInfo.created,
  };
  return o;
};
