import { Grid } from "@mui/material";
import { LabeledText } from "Components/LabeledText";

type Props = {
  name: string;
  qty: string;
  date: string;
  fulfillment: string;
};

export const OverviewGrid = ({ name, qty, date, fulfillment }: Props) => {
  return (
    <Grid container rowSpacing={2} columnSpacing={{ xs: 2, md: 3 }}>
      <Grid item xs={6}>
        <LabeledText label="Customer" text={name} bold />
      </Grid>
      <Grid item xs={6}>
        <LabeledText label="Quantity" text={qty} bold />
      </Grid>
      <Grid item xs={6}>
        <LabeledText label="Date needed" text={date} bold />
      </Grid>
      <Grid item xs={6}>
        <LabeledText label="Fulfillment" text={fulfillment} bold />
      </Grid>
    </Grid>
  );
};
