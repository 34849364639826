import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { PresaleItems } from "./Components/PresaleItems";
import { PresaleForm } from "./Components/PresaleForm";

import { ThemeProvider } from "@mui/material";
import { createCustomTheme } from "Components/Styles/Styles";

// assets
import { Typography, Stack, Button, Box, Divider } from "@mui/material";
import { SelectChangeEvent } from "@mui/material/Select";
import {
  CardStyle,
  ContentWrapNarrow,
  CreatedWith,
  InfoBox,
  Loading,
} from "Components/AllComponents";
import Avatar from "boring-avatars";
import { fetchPresale, readSellerInfo } from "Util/firebaseHelper";
import {
  getPresaleName,
  PresaleInfo,
  formatFulfillments,
} from "Model/PresaleInfo";
import { AvailabilityType } from "Model/Availability";
import { Profile, defaultProfile, defaultSquareState } from "Model/Profile";
import { getNewDateWithoutHours } from "Util/dateUtil";
import { Timestamp } from "firebase/firestore";
import {
  DEFAULT_COLOR_PALETTE,
  PERSONALIZATION_TYPE_CUSTOM_ONLY,
  PRESALE_STATUS_ENDED,
  PRESALE_STATUS_NOTPUBLISHED,
} from "Constants";
import { useAppDispatch } from "Redux/hooks";
import { initializeCart } from "Redux/actionCreators";
import StorefrontOutlinedIcon from "@mui/icons-material/StorefrontOutlined";
import { SubAppBar } from "Components/CustomerView/SubAppBar";
import { como } from "Components/Styles/colors";
import { PresaleItem } from "Model/PresaleItem";

const CustomerPresale = () => {
  const { presaleId } = useParams<"presaleId">();
  let { userId } = useParams<"userId">();
  userId = userId?.toLowerCase();
  const [sellerProfile, setSellerProfile] = useState<Profile>(defaultProfile);
  const [presaleInfo, setPresaleInfo] = useState<PresaleInfo>();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      if (userId) {
        const form = await readSellerInfo(userId);
        let data = form.data();
        if (data) {
          var type =
            (data?.availability?.type === AvailabilityType.acceptBetween ||
              data?.availability?.type === AvailabilityType.acceptUntil) &&
            (data?.availability.end instanceof Timestamp
              ? data?.availability.end.toDate().toISOString()
              : data?.availability?.end) < getNewDateWithoutHours()
              ? AvailabilityType.doNotAcceptOrder
              : data?.availability?.type || AvailabilityType.acceptNow;
          document.title = data?.businessName;
          setSellerProfile({
            businessName: data?.businessName,
            sellerName: data?.sellerName || "",
            sellerIntro: data?.sellerIntro || "",
            sellerCity: data?.sellerCity || "",
            sellerState: data?.sellerState || "",
            sellerZip: data?.sellerZip || "",
            shipOnlyToMyState: data?.shipOnlyToMyState || false,
            fulfillmentMethod: data?.fulfillmentMethod || "Pickup only",
            defaultPickupAddress: data?.defaultPickupAddress || "",
            sellerPhoto: data?.sellerPhoto || "",
            username: data?.username || "",
            links: data?.links || [],
            forms: data?.forms || [],
            availability: {
              type: type,
              start:
                (data?.availability.start instanceof Timestamp
                  ? data?.availability.start.toDate().toISOString()
                  : data?.availability?.start) || getNewDateWithoutHours(),
              end:
                (data?.availability.end instanceof Timestamp
                  ? data?.availability.end.toDate().toISOString()
                  : data?.availability?.end) || getNewDateWithoutHours(),
              lead: data?.availability?.lead || 1,
              dateBlocked: data?.availability?.dateBlocked || [],
            },
            presales: data?.presales || [],
            livePresales: data?.livePresales || [],
            payments: data?.payments || [],
            uid: data?.uid || "", // need for checkout
            sellerEmail: data?.sellerEmail || "",
            colorPalette: data?.colorPalette || DEFAULT_COLOR_PALETTE,
            squareState: data?.squareState || defaultSquareState,
            disableCustomerEmailList: data?.disableCustomerEmailList || false,
            imageGallery: data?.imageGallery || [],
            hideImageGallery: data?.hideImageGallery || false,
          });
        }
      }
      if (presaleId) {
        const presale = await fetchPresale(presaleId);
        if (presale === null) {
        } else {
          console.log("presale data", presale.data());
          let content = presale.data();
          if (content) {
            const presaleInfo = content as PresaleInfo;
            const updatedPresaleItems: PresaleItem[] =
              presaleInfo.presaleItems.map((item) => ({
                ...item,
                flavors: item.flavors || [],
                colors: item.colors || [],
                personalizationType:
                  item.personalizationType || PERSONALIZATION_TYPE_CUSTOM_ONLY,
                personalizationOptions: item.personalizationOptions || [],
              }));
            setPresaleInfo({
              ...presaleInfo,
              presaleItems: updatedPresaleItems,
            });
            dispatch(initializeCart(updatedPresaleItems));
          }
        }
      }
      setLoading(false);
    }
    fetchData();
  }, []);

  return (
    <ThemeProvider theme={createCustomTheme(sellerProfile.colorPalette)}>
      {loading ? (
        <Loading />
      ) : !presaleInfo || presaleInfo.status === PRESALE_STATUS_NOTPUBLISHED ? (
        <Stack
          spacing={2}
          alignItems="center"
          justifyContent="center"
          sx={{
            backgroundColor: "background.default",
            minHeight: "100vh",
            p: 2,
          }}
        >
          <Typography variant="h4" sx={{ fontWeight: 500 }}>
            Presale doesn't exist
          </Typography>
          <Button
            variant="outlined"
            startIcon={<StorefrontOutlinedIcon />}
            sx={{ width: "fit-content" }}
            onClick={() => {
              navigate(`/${sellerProfile.username}`);
            }}
          >
            {sellerProfile.businessName}
          </Button>
        </Stack>
      ) : presaleInfo.status === PRESALE_STATUS_ENDED ? (
        <Stack
          spacing={2}
          alignItems="center"
          justifyContent="center"
          sx={{
            backgroundColor: "background.default",
            minHeight: "100vh",
            p: 2,
          }}
        >
          <Typography variant="h4" sx={{ fontWeight: 500 }}>
            Presale has ended
          </Typography>
          <Button
            variant="outlined"
            startIcon={<StorefrontOutlinedIcon />}
            sx={{ width: "fit-content" }}
            onClick={() => {
              navigate(`/${sellerProfile.username}`);
            }}
          >
            {sellerProfile.businessName}
          </Button>
        </Stack>
      ) : (
        <Stack
          spacing={{ xs: 2, sm: 3 }}
          sx={{
            p: { xs: 2, sm: 3 },
            backgroundColor: "background.default",
          }}
        >
          <Box>
            <ContentWrapNarrow>
              <SubAppBar
                sellerName={sellerProfile.sellerName}
                sellerPhoto={sellerProfile.sellerPhoto}
                username={sellerProfile.username}
                businessName={sellerProfile.businessName}
              />

              <Box sx={{ pt: { xs: 6, sm: 7 } }}>
                <CardStyle>
                  <Stack>
                    <Typography
                      variant="h5"
                      sx={{ mb: 1, textAlign: "center" }}
                    >
                      {getPresaleName(presaleInfo.presaleName)}
                    </Typography>
                    {presaleInfo.presaleDescription ? (
                      <Typography
                        variant="body1"
                        color="text.primary"
                        textAlign="center"
                        sx={{ mb: 2, whiteSpace: "pre-line" }}
                      >
                        {presaleInfo.presaleDescription}
                      </Typography>
                    ) : null}
                    <InfoBox>
                      <Typography variant="subtitle2" textAlign="center">
                        {formatFulfillments(presaleInfo.fulfillments)}
                      </Typography>
                    </InfoBox>
                  </Stack>
                </CardStyle>
              </Box>
            </ContentWrapNarrow>
          </Box>

          <PresaleItems presaleItems={presaleInfo.presaleItems} />
          <Divider />
          <Box>
            <ContentWrapNarrow>
              <PresaleForm
                presaleInfo={presaleInfo}
                editing={false}
                sellerProfile={sellerProfile}
              />
            </ContentWrapNarrow>
          </Box>

          {/* <CreatedWith color="dark" /> */}
        </Stack>
      )}
    </ThemeProvider>
  );
};

export default CustomerPresale;
