import { useLocation, useParams } from "react-router-dom";
import { PAYMENT_TYPE_CREDIT_CARD, PAYMENT_TYPE_INSTRUCTION } from "Constants";
import { Payment } from "Model/Payment";

import { Typography, Stack, Button, Divider } from "@mui/material";
import {
  CardStyle,
  ContentWrapNarrow,
  InfoBox,
} from "Components/AllComponents";
import { useEffect } from "react";

export const PresaleConfirmation = () => {
  const state = useLocation().state as {
    businessName: string;
    email: string;
    balance: number;
    payment: Payment;
  };
  useEffect(() => {
    document.title = state?.businessName ?? "";
  }, []);

  return (
    <Stack
      alignItems="center"
      justifyContent="center"
      sx={{ backgroundColor: "background.default", minHeight: "100vh", p: 2 }}
    >
      <ContentWrapNarrow>
        <CardStyle>
          <Typography color="text.primary" variant="h5">
            Thanks for your order!
          </Typography>
          <Typography color="text.primary" variant="body1" sx={{ mt: 1 }}>
            A copy of your order has been sent to <b>{state?.email}</b>. If you
            can't find it, please check your spam folder. Please follow below
            instruction to make a payment to secure your order.
          </Typography>

          <InfoBox sx={{ mt: 2 }}>
            <Typography variant="h6">
              Remaining balance: ${state?.balance}
            </Typography>
            <Divider sx={{ mt: 2, mb: 2 }} />
            {state?.payment ? (
              <>
                <Typography variant="subtitle2" sx={{ mb: 1 }}>
                  Payment method: {state.payment.method}
                </Typography>
                {state?.payment.type === PAYMENT_TYPE_INSTRUCTION ? (
                  <Typography color="text.primary" variant="body1">
                    {state?.payment.instruction}
                  </Typography>
                ) : (
                  <Button
                    variant="contained"
                    onClick={() => {
                      window.open(state?.payment.link, "_blank");
                    }}
                  >
                    Pay now
                  </Button>
                )}
              </>
            ) : null}
          </InfoBox>
        </CardStyle>
      </ContentWrapNarrow>
    </Stack>
  );
};

export const PresaleCreditCardConfirmation = () => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const email = params.get("email");

  return (
    <Stack
      alignItems="center"
      justifyContent="center"
      sx={{ backgroundColor: "background.default", minHeight: "100vh", p: 2 }}
    >
      <ContentWrapNarrow>
        <CardStyle>
          <Typography color="text.primary" variant="h5">
            Thanks for your order!
          </Typography>
          <Typography color="text.primary" variant="body1" sx={{ mt: 1 }}>
            A copy of your order has been sent to <b>{email}</b>. If you can't
            find it, please check your spam folder.
          </Typography>
        </CardStyle>
      </ContentWrapNarrow>
    </Stack>
  );
};
