import { FormikProps } from "formik";
import { BusinessInfo } from "Model/BusinessInfo";

// ui related
import {
  FormControl,
  RadioGroup,
  Radio,
  FormControlLabel,
  FormHelperText,
  Select,
  MenuItem,
} from "@mui/material";
import { FormSection } from "Components/BusinessView/FormSection";
import { CustomerRequestInfo } from "Model/CustomerRequestInfo";

export const CustomerPackaging = ({
  businessInfo,
  customerFormik,
}: {
  businessInfo: BusinessInfo;
  customerFormik: FormikProps<CustomerRequestInfo>;
}) => {
  if (businessInfo.showPackagings) {
    return (
      <FormSection id="packaging" sectionName="Packaging">
        {
          <FormControl
            fullWidth
            required
            error={
              customerFormik.touched.packagings &&
              Boolean(customerFormik.errors.packagings)
            }
          >
            <FormControl fullWidth>
              <Select
                value={customerFormik.values.packagings}
                onChange={(e) => {
                  customerFormik.setFieldValue("packagings", [e.target.value]);
                }}
                sx={{
                  "& .MuiOutlinedInput-input.MuiSelect-select": {
                    whiteSpace: "normal",
                  },
                }}
              >
                {businessInfo.packagings.map((packaging, index) => (
                  <MenuItem
                    value={packaging}
                    key={index}
                    sx={{ whiteSpace: "normal" }}
                  >
                    {packaging}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormHelperText>
              {customerFormik.touched.packagings &&
                customerFormik.errors.packagings}
            </FormHelperText>
          </FormControl>
        }
      </FormSection>
    );
  } else {
    return null;
  }
};
