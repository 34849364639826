import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import {
  Typography,
  Stack,
  IconButton,
  TextField,
  MenuItem,
  Grid,
  FormControlLabel,
  Checkbox,
  Button,
  FormHelperText,
  Alert,
  Snackbar,
  FormControl,
  ListItemIcon,
} from "@mui/material";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { CardStyle, InfoBox } from "Components/AllComponents";
import { useAppDispatch, useAppSelector } from "Redux/hooks";
import { selectCart } from "Redux/selectors";
import { useFormik } from "formik";
import { addPresaleOrder } from "Redux/Reducers/presaleOrders";
import { convertCartToPresaleOrder } from "Model/PresaleOrder";
import { cartFormValidationSchema, defaultCartForm } from "Model/Cart";
import {
  Fulfillment,
  PresaleInfo,
  defaultFulfillment,
  fulfillmentToString,
  stringToFulfillment,
} from "Model/PresaleInfo";
import { getNewDateHumanMode } from "Util/dateUtil";
import { Profile } from "Model/Profile";
import { Payment, defaultPayment } from "Model/Payment";
import moment, { Moment } from "moment";
import {
  CHECKOUT_FUNCTION,
  PAYMENT_TYPE_CASH_APP,
  PAYMENT_TYPE_CREDIT_CARD,
  PAYMENT_TYPE_PAYPAL,
  PAYMENT_TYPE_VENMO,
} from "Constants";
import SquareIconSrc from "../../../../Assets/square.svg";
import VenmoIconSrc from "../../../../Assets/venmo.svg";
import PayPalIconSrc from "../../../../Assets/paypal.svg";
import CashAppIconSrc from "../../../../Assets/cashapp.svg";
import { joinEmailList, startSquareCheckout } from "Util/firebaseHelper";
import LoadingButton from "@mui/lab/LoadingButton";

const SquareIcon = () => (
  <img
    src={SquareIconSrc}
    alt="square"
    style={{ width: 17.5, height: 17.5, borderRadius: 4 }}
  />
);
const VenmoIcon = () => (
  <img
    src={VenmoIconSrc}
    alt="venmo"
    style={{ width: 17.5, height: 17.5, borderRadius: 4 }}
  />
);
const PayPalIcon = () => (
  <img
    src={PayPalIconSrc}
    alt="paypal"
    style={{ width: 17.5, height: 17.5, borderRadius: 4 }}
  />
);
const CashAppIcon = () => (
  <img
    src={CashAppIconSrc}
    alt="cashapp"
    style={{ width: 17.5, height: 17.5, borderRadius: 4 }}
  />
);

export const PresaleForm = ({
  sellerProfile,
  presaleInfo,
  editing,
}: {
  sellerProfile: Profile;
  presaleInfo: PresaleInfo;
  editing: boolean;
}) => {
  const navigate = useNavigate();

  const [showAlert, setShowAlert] = useState(false);
  const [checkingOut, setCheckingOut] = useState(false);

  const cart = useAppSelector(selectCart);
  const totalItemCountInCart = Object.values(cart.items).reduce(
    (accumulator, currentItem) => accumulator + currentItem.count,
    0
  );

  // const presalePaymentMethods = sellerProfile.payments.filter((payment) =>
  //   presaleInfo.paymentIds.includes(payment.method)
  // );
  console.log("seelrProfile", sellerProfile);
  const dispatch = useAppDispatch();
  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    initialValues: {
      ...defaultCartForm,
      presaleId: presaleInfo.presaleId,
      fulfillment:
        presaleInfo.fulfillments?.length > 0
          ? stringToFulfillment(
              fulfillmentToString(presaleInfo.fulfillments[0], sellerProfile)
            )
          : defaultFulfillment,
      payment:
        sellerProfile.payments?.length > 0
          ? sellerProfile.payments[0]
          : defaultPayment,
      joinEmailList: !sellerProfile.disableCustomerEmailList,
    },
    validationSchema: cartFormValidationSchema,
    onSubmit: async (values, { resetForm }) => {
      setCheckingOut(true);
      if (totalItemCountInCart <= 0) {
        // alert("Please add at least one item.");
        setShowAlert(true);
        setCheckingOut(false);
        return;
      }
      const order = convertCartToPresaleOrder(cart, {
        ...values,
        submittedDate: getNewDateHumanMode(),
      });
      try {
        await dispatch(
          addPresaleOrder({
            sellerProfile: sellerProfile,
            order: order,
            presale: presaleInfo,
            sendMail: values.payment.type !== PAYMENT_TYPE_CREDIT_CARD,
          })
        );
        if (formik.values.joinEmailList) {
          await joinEmailList(order.email, sellerProfile.uid);
        }
        if (values.payment.type === PAYMENT_TYPE_CREDIT_CARD) {
          //window.open(
          window.location.href =
            CHECKOUT_FUNCTION +
            "?sellerId=" +
            sellerProfile.uid +
            "&orderId=" +
            order.orderId +
            "&presaleId=" +
            presaleInfo.presaleId +
            "&presaleName=" +
            presaleInfo.presaleName;
        } else {
          // Handle success, if needed
          navigate("/presale-confirmation", {
            state: {
              businessName: sellerProfile.businessName,
              email: values.email,
              balance: cart.totalPrice.toFixed(2),
              payment: sellerProfile.payments.find(
                (payment) => payment.method === values.payment.method
              ),
            },
          });
        }
      } catch (error: any) {
        // Handle error, if needed
        console.error("Failed to add presale order:", error);
        setCheckingOut(false);
      }
    },
  });

  return (
    <CardStyle>
      <InfoBox sx={{ mb: 2 }}>
        <Stack direction="row" alignItems="center" spacing={2}>
          <Typography flex={1} variant="h6">
            Total
            {totalItemCountInCart > 0 ? (
              <Typography color="text.primary" component="span">
                {" "}
                ({totalItemCountInCart}{" "}
                {totalItemCountInCart > 1 ? "items" : "item"})
              </Typography>
            ) : null}
          </Typography>
          <Typography variant="h6" sx={{ fontSize: "1.3rem" }}>
            ${cart.totalPrice.toFixed(2)}
          </Typography>
        </Stack>
      </InfoBox>

      <Stack spacing={1} sx={{ mb: 2 }}>
        <Typography variant="h6">Pickup date</Typography>
        <Select
          value={fulfillmentToString(formik.values.fulfillment, sellerProfile)}
          onChange={(e) => {
            formik.setFieldValue(
              "fulfillment",
              stringToFulfillment(e.target.value)
            );
          }}
        >
          {presaleInfo.fulfillments.map((fulfillment: Fulfillment, index) => (
            <MenuItem
              key={fulfillmentToString(fulfillment, sellerProfile) + index}
              value={fulfillmentToString(fulfillment, sellerProfile)}
            >
              {fulfillment.date + " " + fulfillment.range}
            </MenuItem>
          ))}
        </Select>
        <FormHelperText error>
          {formik.touched.fulfillment && formik.errors.fulfillment}
        </FormHelperText>

        {presaleInfo.fulfillments.length ? (
          <Stack>
            <Typography variant="subtitle2">Pickup address</Typography>
            <Typography variant="body2" color="text.secondary">
              {formik.values.fulfillment.uDA
                ? sellerProfile.sellerCity +
                  ", " +
                  sellerProfile.sellerState +
                  " " +
                  sellerProfile.sellerZip +
                  " (you will be contacted before the pickup date with details about the pickup address and instructions)."
                : formik.values.fulfillment.address}
            </Typography>
          </Stack>
        ) : null}
      </Stack>

      <Typography variant="h6">Contact info</Typography>
      <Grid container columnSpacing={2}>
        <Grid item xs={6}>
          <TextField
            fullWidth
            margin="normal"
            required
            id="firstName"
            label="First name"
            value={formik.values.firstName}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.firstName && Boolean(formik.errors.firstName)}
            helperText={formik.touched.firstName && formik.errors.firstName}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            margin="normal"
            required
            id="lastName"
            label="Last name"
            value={formik.values.lastName}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.lastName && Boolean(formik.errors.lastName)}
            helperText={formik.touched.lastName && formik.errors.lastName}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            margin="normal"
            required
            id="phoneNumber"
            label="Phone number"
            value={formik.values.phoneNumber}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.phoneNumber && Boolean(formik.errors.phoneNumber)
            }
            helperText={formik.touched.phoneNumber && formik.errors.phoneNumber}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            margin="normal"
            required
            id="email"
            type="email"
            label="Email"
            value={formik.values.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
          />
        </Grid>
      </Grid>

      {sellerProfile.payments.length > 0 ? (
        <Stack spacing={1} sx={{ mt: 2 }}>
          <Typography variant="h6">Payment method</Typography>
          <Select
            value={formik.values.payment.type + formik.values.payment.method}
            onChange={(e) => {
              formik.setFieldValue(
                "payment",
                sellerProfile.payments.find(
                  (payment) => payment.type + payment.method === e.target.value
                )
              );
            }}
            sx={{
              "& .MuiListItemIcon-root": { minWidth: "30px" },
              "& .MuiInputBase-input": {
                display: "flex",
                alignItems: "center",
              },
            }}
          >
            {sellerProfile.payments.map((p: Payment) =>
              p.type === PAYMENT_TYPE_CREDIT_CARD ? (
                <MenuItem key={p.type + p.method} value={p.type + p.method}>
                  <ListItemIcon>
                    <Stack sx={{ pl: "2px" }}>
                      <SquareIcon />
                    </Stack>
                  </ListItemIcon>
                  Credit card (via Square)
                </MenuItem>
              ) : p.type === PAYMENT_TYPE_VENMO ? (
                <MenuItem key={p.type + p.method} value={p.type + p.method}>
                  <ListItemIcon>
                    <Stack sx={{ pl: "2px" }}>
                      <VenmoIcon />
                    </Stack>
                  </ListItemIcon>
                  Venmo
                </MenuItem>
              ) : p.type === PAYMENT_TYPE_PAYPAL ? (
                <MenuItem key={p.type + p.method} value={p.type + p.method}>
                  <ListItemIcon>
                    <Stack sx={{ pl: "2px" }}>
                      <PayPalIcon />
                    </Stack>
                  </ListItemIcon>
                  PayPal
                </MenuItem>
              ) : p.type === PAYMENT_TYPE_CASH_APP ? (
                <MenuItem key={p.type + p.method} value={p.type + p.method}>
                  <ListItemIcon>
                    <Stack sx={{ pl: "2px" }}>
                      <CashAppIcon />
                    </Stack>
                  </ListItemIcon>
                  Cash App
                </MenuItem>
              ) : (
                <MenuItem key={p.type + p.method} value={p.type + p.method}>
                  {p.method}
                </MenuItem>
              )
            )}
          </Select>
          {formik.values.payment.type === PAYMENT_TYPE_CREDIT_CARD ? null : (
            <Typography variant="body2" color="text.secondary">
              Payment is required to secure your order. After submitting this
              form, instructions will be provided to complete your payment.
            </Typography>
          )}
        </Stack>
      ) : null}

      <Stack spacing={1} sx={{ mt: 3 }}>
        <Typography variant="h6">Questions/comments</Typography>
        <TextField
          fullWidth
          multiline
          margin="normal"
          id="questions"
          value={formik.values.questions}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.questions && Boolean(formik.errors.questions)}
          helperText={formik.touched.questions && formik.errors.questions}
        />
      </Stack>

      {!sellerProfile.disableCustomerEmailList ? (
        <Stack spacing={1} sx={{ mt: 3 }}>
          <Typography variant="h6">Join email list</Typography>
          <FormControl fullWidth>
            <FormControlLabel
              control={<Checkbox defaultChecked />}
              name="joinEmailList"
              label="I would like to receive emails about future presales and events."
              onChange={formik.handleChange}
            />
          </FormControl>
        </Stack>
      ) : null}

      <Stack alignItems="center" sx={{ mt: 3 }}>
        {formik.values.payment.type === "Credit card" ? (
          <LoadingButton
            variant="contained"
            size="large"
            loading={checkingOut}
            onClick={() => {
              formik.submitForm();
            }}
          >
            Check out
          </LoadingButton>
        ) : (
          <Button
            variant="contained"
            size="large"
            onClick={() => {
              formik.submitForm();
            }}
            disabled={editing || checkingOut}
          >
            Submit order
          </Button>
        )}
      </Stack>

      <Snackbar
        open={showAlert}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        autoHideDuration={6000}
        onClose={() => {
          setShowAlert(false);
        }}
      >
        <Alert
          onClose={() => {
            setShowAlert(false);
          }}
          severity="error"
        >
          Please add at least one item.
        </Alert>
      </Snackbar>
    </CardStyle>
  );
};
