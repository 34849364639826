import { useState } from "react";
import { useSession } from "Context/userAuthContext";
import { publishProfile, startSquareOAuth } from "Util/firebaseHelper";
import _ from "lodash";

import {
  Button,
  Box,
  TextField,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  Chip,
  MenuItem,
  Select,
  InputLabel,
  Typography,
  InputAdornment,
  Link,
  ListItemIcon,
  Stack,
  Popover,
} from "@mui/material";
import { useAppDispatch } from "Redux/hooks";
import { editSellerProfile } from "Redux/actionCreators";
import { PaymentMethod } from "Components/PaymentMethod";

// icons
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import InsertLinkOutlinedIcon from "@mui/icons-material/InsertLinkOutlined";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import { Payment, paymentValidationSchema } from "Model/Payment";
import { useFormik } from "formik";
import {
  OAUTH_FUNCTION,
  PAYMENT_METHOD_CASH_APP,
  PAYMENT_METHOD_CREDIT_CARD,
  PAYMENT_METHOD_PAYPAL,
  PAYMENT_METHOD_VENMO,
  PAYMENT_TYPE_CASH_APP,
  PAYMENT_TYPE_CREDIT_CARD,
  PAYMENT_TYPE_INSTRUCTION,
  PAYMENT_TYPE_LINK,
  PAYMENT_TYPE_OTHER,
  PAYMENT_TYPE_PAYPAL,
  PAYMENT_TYPE_VENMO,
  REVOKE_FUNCTION,
} from "Constants";
import SquareIconSrc from "../../../../../Assets/square.svg";
import VenmoIconSrc from "../../../../../Assets/venmo.svg";
import PayPalIconSrc from "../../../../../Assets/paypal.svg";
import CashAppIconSrc from "../../../../../Assets/cashapp.svg";
import LoadingButton from "@mui/lab/LoadingButton";
import { CardStyle } from "Components/AllComponents";

const SquareIcon = () => (
  <img
    src={SquareIconSrc}
    alt="square"
    style={{ width: 17.5, height: 17.5, borderRadius: 4 }}
  />
);
const VenmoIcon = () => (
  <img
    src={VenmoIconSrc}
    alt="venmo"
    style={{ width: 17.5, height: 17.5, borderRadius: 4 }}
  />
);
const PayPalIcon = () => (
  <img
    src={PayPalIconSrc}
    alt="paypal"
    style={{ width: 17.5, height: 17.5, borderRadius: 4 }}
  />
);
const CashAppIcon = () => (
  <img
    src={CashAppIconSrc}
    alt="cashapp"
    style={{ width: 17.5, height: 17.5, borderRadius: 4 }}
  />
);

type Props = {
  empty?: boolean;
};

export const EditPaymentLinks = ({ empty }: Props) => {
  const { user, sellerProfile } = useSession();

  const [addingNewPayment, setAddingNewPayment] = useState(true);
  const [openEditPayment, setOpenEditPayment] = useState(false);
  const [linkingSquare, setLinkingSquare] = useState(false);

  const firstAvailablePaymentType =
    [
      PAYMENT_TYPE_CREDIT_CARD,
      PAYMENT_TYPE_VENMO,
      PAYMENT_TYPE_PAYPAL,
      PAYMENT_TYPE_CASH_APP,
      PAYMENT_TYPE_OTHER,
    ].find(
      (type) => !sellerProfile.payments.some((payment) => payment.type === type)
    ) || PAYMENT_TYPE_OTHER;

  const [selectedPaymentType, setSelectedPaymentType] = useState(
    firstAvailablePaymentType
  );

  const firstAvailableDefaultPayment = {
    type:
      firstAvailablePaymentType === PAYMENT_TYPE_OTHER
        ? PAYMENT_TYPE_INSTRUCTION
        : firstAvailablePaymentType,
    method:
      firstAvailablePaymentType === PAYMENT_TYPE_OTHER
        ? ""
        : firstAvailablePaymentType,
    link: "",
    instruction: "",
  } as Payment;

  const [payment, setPayment] = useState(firstAvailableDefaultPayment);

  const getPreDefinedPaymentUrl = (paymentType: string) => {
    switch (paymentType) {
      case PAYMENT_TYPE_VENMO:
        return "venmo.com/u/";
      case PAYMENT_TYPE_PAYPAL:
        return "paypal.me/";
      case PAYMENT_TYPE_CASH_APP:
        return "cash.app/$";
      default:
        return "";
    }
  };

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const handleClickPopover = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClosePopover = () => {
    setAnchorEl(null);
  };
  const openDeleteRequest = Boolean(anchorEl);

  const [preDefinedPaymentUrl, setPreDefinitPaymentUrl] = useState(
    getPreDefinedPaymentUrl(firstAvailablePaymentType)
  );

  const dispatch = useAppDispatch();
  const handleOpenEditPayment = (p: Payment) => {
    setPayment(p);
    setOpenEditPayment(true);
  };
  const handleCloseEditPayment = () => {
    setPayment(firstAvailableDefaultPayment);
    setOpenEditPayment(false);
    formik.resetForm();
  };

  const onDeleteCreditCard = async (payment: Payment) => {
    const session = await startSquareOAuth(sellerProfile.username);
    if (session === "") {
      alert("Error deleting Square account, please retry");
    } else {
      try {
        console.log("triggering delete payment");
        const r = await fetch(
          REVOKE_FUNCTION + "?sessionId=" + session.sessionId
        );
        const response = await r.text();
        console.log("response: " + response);
        if (response === "success") {
          console.log("payment removed successfully");
        } else {
          console.log("failed to remove payment");
        }
      } catch (e) {
        console.log("error removing payment: " + e);
      } finally {
        console.log("finishe");
      }
    }
  };

  const onDelete = async (paymentToDelete: Payment) => {
    let tempPayments = _.cloneDeep(sellerProfile.payments);
    const index = tempPayments.findIndex(
      (p) =>
        p.method === paymentToDelete.method &&
        p.type === paymentToDelete.type &&
        p.instruction === paymentToDelete.instruction &&
        p.link === paymentToDelete.link
    );
    if (index !== -1) {
      tempPayments.splice(index, 1);
    }
    publishProfile(
      { ...sellerProfile, payments: tempPayments },
      user?.uid || ""
    );
    dispatch(
      editSellerProfile({
        ...sellerProfile,
        payments: tempPayments,
      })
    );
  };

  const onSave = async (values: Payment) => {
    const link =
      "https://" + getPreDefinedPaymentUrl(values.type) + values.instruction;

    let tempPayments = _.cloneDeep(sellerProfile.payments);
    const index = tempPayments.findIndex((p) => p.method === values.method);
    const newPayment = {
      ...values,
      instruction:
        values.type === PAYMENT_TYPE_INSTRUCTION ||
        values.type === PAYMENT_TYPE_CASH_APP ||
        values.type === PAYMENT_TYPE_PAYPAL ||
        values.type === PAYMENT_TYPE_VENMO
          ? values.instruction
          : "", // link or credit card
      link:
        values.type === PAYMENT_TYPE_LINK
          ? values.link
          : values.type === PAYMENT_TYPE_CASH_APP ||
            values.type === PAYMENT_TYPE_PAYPAL ||
            values.type === PAYMENT_TYPE_VENMO
          ? link
          : "", // credit card
    };
    if (index !== -1) {
      // find the payment method, update it
      tempPayments[index] = newPayment;
    } else if (addingNewPayment) {
      // didn't find the payment method and it is new one
      tempPayments.push(newPayment);
    } else {
      // didn't find the payment method and it was an old one changed method name
      const oldIndex = tempPayments.findIndex(
        (p) => p.method === payment.method
      );
      if (oldIndex !== -1) {
        tempPayments[oldIndex] = newPayment;
      } else {
        // should not happen
      }
    }
    publishProfile(
      {
        ...sellerProfile,
        payments: tempPayments.sort((a, b) => {
          const paymentTypeOrder: { [key: string]: number } = {
            [PAYMENT_TYPE_CREDIT_CARD]: 0,
            [PAYMENT_TYPE_VENMO]: 1,
            [PAYMENT_TYPE_PAYPAL]: 2,
            [PAYMENT_TYPE_CASH_APP]: 3,
          };

          const typeA = a.type;
          const typeB = b.type;
          if (typeA === typeB) {
            return 0;
          }
          if (typeA in paymentTypeOrder && typeB in paymentTypeOrder) {
            return paymentTypeOrder[typeA] - paymentTypeOrder[typeB];
          }
          if (typeA in paymentTypeOrder) {
            return -1;
          }
          if (typeB in paymentTypeOrder) {
            return 1;
          }
          return 0;
        }),
      },
      user?.uid || ""
    );
    dispatch(editSellerProfile({ ...sellerProfile, payments: tempPayments }));
  };

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    initialValues: payment,
    validationSchema: paymentValidationSchema(
      // only allow same method to be shown once
      sellerProfile.payments
        .map((p) => p.method)
        .filter((p) => p !== payment.method)
    ),
    onSubmit: async (value: Payment) => {
      onSave(value);
      setOpenEditPayment(false);
      setPayment(firstAvailableDefaultPayment);
      setSelectedPaymentType(firstAvailablePaymentType);
      formik.resetForm();
    },
  });
  console.log(
    "firstAvailablePaymentType,firstAvailableDefaultPayment, payment,formik",
    firstAvailablePaymentType,
    firstAvailableDefaultPayment,
    payment,
    formik.values
  );
  return (
    <div>
      <Box>
        {sellerProfile.payments.map((p) =>
          p.type === PAYMENT_TYPE_LINK ||
          p.type === PAYMENT_TYPE_INSTRUCTION ? (
            <Chip
              key={p.type + p.method}
              label={p.method}
              variant="outlined"
              icon={
                p.type === PAYMENT_TYPE_LINK ? (
                  <InsertLinkOutlinedIcon />
                ) : (
                  <></>
                )
              }
              sx={{
                mr: 1,
                mb: 1,
                backgroundColor: "white",
                border: "none",
              }}
              onClick={() => {
                setAddingNewPayment(false);
                handleOpenEditPayment(p);
              }}
            />
          ) : (
            <PaymentMethod
              editing
              payment={p}
              key={p.type + p.method}
              onSave={onSave}
              onDelete={
                p.type === PAYMENT_TYPE_CREDIT_CARD
                  ? onDeleteCreditCard
                  : onDelete
              }
            />
          )
        )}
        {!empty ? (
          <Button
            size="small"
            variant="text"
            startIcon={<AddRoundedIcon />}
            onClick={() => {
              setSelectedPaymentType(firstAvailablePaymentType);
              setPreDefinitPaymentUrl(
                getPreDefinedPaymentUrl(firstAvailablePaymentType)
              );
              setAddingNewPayment(true);
              handleOpenEditPayment(firstAvailableDefaultPayment);
            }}
            sx={{ mb: 0.75 }}
          >
            Add payment method
          </Button>
        ) : (
          <Button
            variant="outlined"
            color="secondary"
            onClick={() => {
              setSelectedPaymentType(firstAvailablePaymentType);
              setPreDefinitPaymentUrl(
                getPreDefinedPaymentUrl(firstAvailablePaymentType)
              );
              setAddingNewPayment(true);
              handleOpenEditPayment(firstAvailableDefaultPayment);
            }}
          >
            Add payment method
          </Button>
        )}
      </Box>

      <Dialog
        open={openEditPayment}
        fullWidth
        onClose={(event, reason) => {
          if (!linkingSquare) {
            handleCloseEditPayment();
          }
        }}
        disableEscapeKeyDown={linkingSquare}
      >
        <DialogTitle>
          {addingNewPayment ? "Add " : "Edit "}payment method
        </DialogTitle>
        <DialogContent>
          {addingNewPayment ? (
            <FormControl required fullWidth margin="dense">
              <InputLabel>Payment method</InputLabel>
              <Select
                label="Payment method"
                id="selectMethod"
                name="selectMethod"
                value={selectedPaymentType}
                onChange={(v) => {
                  setSelectedPaymentType(v.target.value);
                  formik.setFieldValue("type", v.target.value);
                  formik.setFieldError("instruction", "");
                  if (v.target.value === PAYMENT_TYPE_PAYPAL) {
                    setPreDefinitPaymentUrl("paypal.me/");
                    formik.setFieldValue("method", PAYMENT_METHOD_PAYPAL);
                    formik.setFieldValue("type", PAYMENT_TYPE_PAYPAL);
                    formik.setFieldValue("link", "");
                    formik.setFieldValue("instruction", "");
                  } else if (v.target.value === PAYMENT_TYPE_CASH_APP) {
                    setPreDefinitPaymentUrl("cash.app/$");
                    formik.setFieldValue("method", PAYMENT_METHOD_CASH_APP);
                    formik.setFieldValue("type", PAYMENT_TYPE_CASH_APP);
                    formik.setFieldValue("link", "");
                    formik.setFieldValue("instruction", "");
                  } else if (v.target.value === PAYMENT_TYPE_VENMO) {
                    setPreDefinitPaymentUrl("venmo.com/u/");
                    formik.setFieldValue("method", PAYMENT_METHOD_VENMO);
                    formik.setFieldValue("type", PAYMENT_TYPE_VENMO);
                    formik.setFieldValue("link", "");
                    formik.setFieldValue("instruction", "");
                  } else {
                    setPreDefinitPaymentUrl("");
                    formik.setFieldValue("method", "");
                    formik.setFieldValue("type", PAYMENT_TYPE_INSTRUCTION);
                    formik.setFieldValue("link", "");
                    formik.setFieldValue("instruction", "");
                  }
                }}
                sx={{
                  "& .MuiListItemIcon-root": { minWidth: "30px" },
                  "& .MuiInputBase-input": {
                    display: "flex",
                    alignItems: "center",
                  },
                }}
              >
                {sellerProfile.payments.findIndex(
                  (p) => p.type === PAYMENT_TYPE_CREDIT_CARD
                ) === -1 ? (
                  <MenuItem value={PAYMENT_TYPE_CREDIT_CARD}>
                    <ListItemIcon>
                      <Stack sx={{ pl: "2px" }}>
                        <SquareIcon />
                      </Stack>
                    </ListItemIcon>
                    Credit card (via Square)
                  </MenuItem>
                ) : null}
                {sellerProfile.payments.findIndex(
                  (p) => p.type === PAYMENT_TYPE_VENMO
                ) === -1 ? (
                  <MenuItem value={PAYMENT_TYPE_VENMO}>
                    <ListItemIcon>
                      <Stack sx={{ pl: "2px" }}>
                        <VenmoIcon />
                      </Stack>
                    </ListItemIcon>
                    Venmo
                  </MenuItem>
                ) : null}
                {sellerProfile.payments.findIndex(
                  (p) => p.type === PAYMENT_TYPE_PAYPAL
                ) === -1 ? (
                  <MenuItem value={PAYMENT_TYPE_PAYPAL}>
                    <ListItemIcon>
                      <Stack sx={{ pl: "2px" }}>
                        <PayPalIcon />
                      </Stack>
                    </ListItemIcon>
                    PayPal
                  </MenuItem>
                ) : null}
                {sellerProfile.payments.findIndex(
                  (p) => p.type === PAYMENT_TYPE_CASH_APP
                ) === -1 ? (
                  <MenuItem value={PAYMENT_TYPE_CASH_APP}>
                    <ListItemIcon>
                      <Stack sx={{ pl: "2px" }}>
                        <CashAppIcon />
                      </Stack>
                    </ListItemIcon>
                    Cash App
                  </MenuItem>
                ) : null}
                <MenuItem value={PAYMENT_TYPE_OTHER}>Other</MenuItem>
              </Select>
            </FormControl>
          ) : null}

          {addingNewPayment &&
          selectedPaymentType === PAYMENT_METHOD_CREDIT_CARD ? (
            <LoadingButton
              variant="contained"
              disabled={linkingSquare}
              sx={{ mt: 2 }}
              loading={linkingSquare}
              onClick={async () => {
                console.log("link square account started");
                setLinkingSquare(true);
                const r = await startSquareOAuth(sellerProfile.username);
                if (r === "") {
                  alert("Error linking Square account, please retry");
                } else {
                  window.location.href = OAUTH_FUNCTION + r.sessionId;
                }
              }}
            >
              Link Square account
            </LoadingButton>
          ) : null}

          {addingNewPayment &&
          (selectedPaymentType === PAYMENT_TYPE_VENMO ||
            selectedPaymentType === PAYMENT_TYPE_PAYPAL ||
            selectedPaymentType === PAYMENT_TYPE_CASH_APP) ? (
            <>
              <TextField
                fullWidth
                margin="dense"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Typography color="text.primary">
                        {preDefinedPaymentUrl}
                      </Typography>
                    </InputAdornment>
                  ) as React.ReactNode,
                }}
                placeholder="your ID"
                required
                id="instruction"
                value={formik.values.instruction}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.instruction &&
                  Boolean(formik.errors.instruction)
                }
                helperText={
                  formik.touched.instruction && formik.errors.instruction
                }
              />
              <Typography sx={{ mt: 1 }}>Payment link:</Typography>
              <Link
                onClick={() =>
                  window.open(
                    "https://" +
                      preDefinedPaymentUrl +
                      formik.values.instruction,
                    "_blank"
                  )
                }
              >
                {"https://" + preDefinedPaymentUrl + formik.values.instruction}
              </Link>
            </>
          ) : null}

          {(addingNewPayment && selectedPaymentType === PAYMENT_TYPE_OTHER) ||
          (!addingNewPayment &&
            (formik.values.type === PAYMENT_TYPE_LINK ||
              formik.values.type === PAYMENT_TYPE_INSTRUCTION)) ? (
            <>
              <TextField
                fullWidth
                multiline
                required
                margin="dense"
                id="method"
                label="Payment method"
                value={formik.values.method}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.method && Boolean(formik.errors.method)}
                helperText={formik.touched.method && formik.errors.method}
              />

              <FormControl required fullWidth margin="dense">
                <RadioGroup
                  name="type"
                  id="type"
                  value={formik.values.type}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                >
                  <FormControlLabel
                    value={PAYMENT_TYPE_INSTRUCTION}
                    control={<Radio />}
                    label="Payment instruction"
                  />
                  <FormControlLabel
                    value={PAYMENT_TYPE_LINK}
                    control={<Radio />}
                    label="Payment link"
                  />
                </RadioGroup>
              </FormControl>
              {formik.values.type === PAYMENT_TYPE_LINK ? (
                <TextField
                  fullWidth
                  required
                  margin="dense"
                  id="link"
                  label="Payment link"
                  type="url"
                  value={formik.values.link}
                  onChange={formik.handleChange}
                  onBlur={() => {
                    if (
                      formik.values.link.indexOf("http://") !== 0 &&
                      formik.values.link.indexOf("https://") !== 0
                    ) {
                      formik.setFieldValue(
                        "link",
                        "https://" + formik.values.link
                      );
                    }
                  }}
                  error={formik.touched.link && Boolean(formik.errors.link)}
                  helperText={formik.touched.link && formik.errors.link}
                />
              ) : null}
              {formik.values.type === PAYMENT_TYPE_INSTRUCTION ? (
                <TextField
                  fullWidth
                  required
                  multiline
                  margin="dense"
                  id="instruction"
                  label="Payment instruction"
                  placeholder="E.g. Please send payment to @sellerId"
                  value={formik.values.instruction}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.instruction &&
                    Boolean(formik.errors.instruction)
                  }
                  helperText={
                    formik.touched.instruction && formik.errors.instruction
                  }
                />
              ) : null}
            </>
          ) : null}

          {addingNewPayment ? null : (
            <Button
              startIcon={<DeleteRoundedIcon />}
              variant="text"
              onClick={handleClickPopover}
              sx={{ width: "fit-content", color: "text.secondary", mt: 2 }}
            >
              Delete payment method
            </Button>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseEditPayment}
            disabled={!user || linkingSquare}
            color="secondary"
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              console.log("form submitted");

              formik.submitForm();
            }}
            disabled={!user || linkingSquare}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>

      <Popover
        id="deletePayment"
        open={openDeleteRequest}
        anchorEl={anchorEl}
        onClose={handleClosePopover}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
      >
        <Box sx={{ p: 2 }}>
          <Typography sx={{ mb: 1, maxWidth: "400px" }}>
            This action will delete the payment method. Would you like to
            proceed?
          </Typography>
          <Stack
            direction="row"
            spacing={1}
            justifyContent="flex-end"
            alignItems="center"
          >
            <Button
              variant="text"
              color="secondary"
              onClick={handleClosePopover}
            >
              Cancel
            </Button>
            <Button
              variant="outlined"
              color="error"
              onClick={() => {
                onDelete({
                  method: formik.values.method,
                  type: formik.values.type,
                  link: formik.values.link,
                  instruction: formik.values.instruction,
                });

                setOpenEditPayment(false);
                handleClosePopover();
              }}
            >
              Yes, delete
            </Button>
          </Stack>
        </Box>
      </Popover>
    </div>
  );
};
