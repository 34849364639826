import { createContext, useContext, useEffect, useState } from "react";
import { Loading } from "Components/AllComponents";

import { useAuthState } from "react-firebase-hooks/auth";
import { auth, db } from "Util/firebaseHelper";
import { User } from "firebase/auth";
import { defaultProfile, Profile } from "Model/Profile";
import { CustomerRequestInfo } from "Model/CustomerRequestInfo";
import { fetchProfileAsync } from "Redux/Reducers/sellerProfile";
import { useAppDispatch, useAppSelector } from "Redux/hooks";
import {
  businessInfoSelector,
  presalesLoadingSelector,
  presalesSelector,
  selectAllRequests,
  selectPresaleOrdersLoading,
  sellerProfileSelector,
} from "Redux/selectors";
import { fetchBusinessInfoAsync } from "Redux/Reducers/businessInfo";
import { fetchOrdersStatusAsync } from "Redux/Reducers/ordersStatus";
import { fetchOrdersAsync } from "Redux/Reducers/orders";
import { fetchPresalesAsync } from "Redux/Reducers/presales";
import { fetchAllRequests } from "Redux/Reducers/requests";
import { fetchSubscribersAsync } from "Redux/Reducers/subscribers";

const defaultState = {
  username: "",
  displayName: "",
  photoURL: "",
  userProfileLoading: true,
  loading: true,
  user: null,

  sellerProfile: defaultProfile,
  allRequests: [] as CustomerRequestInfo[],
};
export const UserAuthContext = createContext<UserAuth>(defaultState);

type UserAuth = {
  username: string;
  displayName: string;
  photoURL: string;
  userProfileLoading: boolean;
  loading: boolean;
  user: User | undefined | null;
  sellerProfile: Profile;
  allRequests: CustomerRequestInfo[];
};

export type Props = {
  children: React.ReactNode;
};

export function UserAuthProvider({ children }: Props) {
  const [user, loading, error] = useAuthState(auth);
  const [userAuth, setUserAuth] = useState<UserAuth>(defaultState);
  const sellerProfile = useAppSelector(sellerProfileSelector);
  const businessInfo = useAppSelector(businessInfoSelector);
  const presalesLoading = useAppSelector(presalesLoadingSelector);
  const presaleOrdersLoading = useAppSelector(selectPresaleOrdersLoading);
  const allRequests = useAppSelector(selectAllRequests);
  const dispatch = useAppDispatch();

  useEffect(() => {
    console.log("useeffect to load user profile", user);
    if (user != null) {
      setUserAuth({ ...userAuth, userProfileLoading: true });
      dispatch(fetchBusinessInfoAsync(user));
      dispatch(fetchProfileAsync(user.uid));
      dispatch(fetchOrdersStatusAsync(user.uid));
      dispatch(fetchOrdersAsync(user.uid));
      dispatch(fetchSubscribersAsync(user.uid));
      setUserAuth({ ...userAuth, userProfileLoading: false });
    } else if (loading) {
      console.log("loading user profile");
    } else {
      console.log("set user", user);
      setUserAuth({
        ...userAuth,
        userProfileLoading: false,
      });
    }
  }, [user, loading]);

  if (
    loading ||
    sellerProfile.loading ||
    (user && !sellerProfile.initialized) ||
    businessInfo.loading ||
    // presalesLoading ||
    // presaleOrdersLoading ||
    userAuth.userProfileLoading
  )
    return <Loading />;
  return (
    <UserAuthContext.Provider
      // Need to set sellerProfile separately since useState is async and there is race condition
      value={{
        ...userAuth,
        user: user,
        sellerProfile: sellerProfile.sellerProfile,
        allRequests: allRequests,
      }}
    >
      {children}
    </UserAuthContext.Provider>
  );
}

export const useSession = () => {
  return useContext(UserAuthContext);
};
