import { useEffect, useState } from "react";
import { useSession } from "Context/userAuthContext";
import { logOut, publishProfile, uploadImage } from "Util/firebaseHelper";
import { Formik, Form } from "formik";
import { profileValidationSchema } from "Model/Profile";
import { editSellerProfile } from "Redux/actionCreators";
import { useAppDispatch } from "Redux/hooks";
import USStates from "Components/USStates.json";

import {
  Button,
  Box,
  TextField,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Grid,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  Typography,
  Stack,
  InputLabel,
  Select,
  MenuItem,
  InputAdornment,
  Autocomplete,
  Checkbox,
} from "@mui/material";
import { InfoBox } from "Components/AllComponents";
import LoadingButton from "@mui/lab/LoadingButton";
import Avatar from "boring-avatars";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { getStateAbbreviation, getStateName, resizeFile } from "Util/helpers";

type Props = {
  autoPop: boolean;
  isModalOpen: boolean;
  openModal: () => void;
  closeModal: () => void;
};
export const ProfileSettings = ({
  autoPop,
  isModalOpen,
  openModal,
  closeModal,
}: Props) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const { user, sellerProfile } = useSession();
  const dispatch = useAppDispatch();

  const [uploadingImage, setUploadingImage] = useState(false);
  useEffect(() => {
    if (autoPop) {
      openModal();
    }
  }, [autoPop]);

  const handleCloseProfile = async () => {
    //props.resetForm();
    if (autoPop) {
      await logOut();
      window.location.reload();
    }
    closeModal();
  };

  return (
    <div>
      <Dialog
        fullWidth
        fullScreen={fullScreen}
        open={isModalOpen}
        onClose={() => {
          handleCloseProfile();
        }}
      >
        <DialogTitle>
          Business profile
          {user ? null : (
            <InfoBox sx={{ mt: 1 }}>
              <Typography variant="body1">
                Please sign in to save changes.
              </Typography>
            </InfoBox>
          )}
        </DialogTitle>
        <Formik
          validateOnChange={false}
          initialValues={sellerProfile}
          enableReinitialize={true}
          validationSchema={profileValidationSchema(
            sellerProfile.username.toLowerCase()
          )}
          onSubmit={(values, actions) => {
            //alert(JSON.stringify(values, null, 2));
            publishProfile(
              {
                ...values,
                username: values.username.toLowerCase(),
                links: sellerProfile.links,
                sellerEmail: user?.email || "",
              },
              user?.uid || ""
            );
            actions.resetForm({
              values: values,
            });
            dispatch(editSellerProfile(values));
            closeModal();
          }}
        >
          {(props) => (
            <Form>
              <DialogContent dividers>
                <Stack spacing={1}>
                  <Typography variant="subtitle2">Username</Typography>
                  <TextField
                    fullWidth
                    margin="normal"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Typography color="text.primary">
                            flowlylink.com/
                          </Typography>
                        </InputAdornment>
                      ),
                    }}
                    required
                    id="username"
                    name="username"
                    value={props.values.username.toLowerCase()}
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    error={
                      props.touched.username && Boolean(props.errors.username)
                    }
                    helperText={props.touched.username && props.errors.username}
                  />
                </Stack>

                <Typography variant="subtitle2" sx={{ mt: 3 }}>
                  Seller info
                </Typography>
                <TextField
                  fullWidth
                  margin="normal"
                  id="sellerName"
                  name="sellerName"
                  label="Your name"
                  value={props.values.sellerName}
                  onChange={props.handleChange}
                  onBlur={props.handleBlur}
                />
                <TextField
                  fullWidth
                  multiline
                  margin="normal"
                  id="sellerIntro"
                  name="sellerIntro"
                  label="About you"
                  value={props.values.sellerIntro}
                  onChange={props.handleChange}
                  onBlur={props.handleBlur}
                />

                <Typography variant="subtitle2" sx={{ mt: 2, mb: 1 }}>
                  Business info
                </Typography>
                <Box
                  sx={{
                    aspectRatio: "1/1",
                    borderRadius: "50%",
                    backgroundColor: "background.default",
                    width: "72px",
                    overflow: "hidden",
                  }}
                >
                  {props.values.sellerPhoto === "" ? (
                    <LoadingButton
                      color="secondary"
                      component="label"
                      loading={uploadingImage}
                      sx={{
                        position: "relative",
                        width: "100%",
                        aspectRatio: "1/1",
                        backgroundColor: "background.default",
                        overflow: "hidden",
                        "&:hover": { opacity: 0.8 },
                        textAlign: "center",
                      }}
                    >
                      Upload logo
                      <input
                        hidden
                        accept="image/*"
                        type="file"
                        onChange={async (e) => {
                          // e.preventDefault();
                          setUploadingImage(true);
                          if (
                            user &&
                            e.target.files &&
                            e.target.files.length > 0
                          ) {
                            const image = e.target.files[0];
                            const resizedImage = await resizeFile(
                              image,
                              300,
                              300
                            );
                            var url = await uploadImage(
                              resizedImage as File,
                              user.uid
                            );
                            props.setFieldValue("sellerPhoto", url);
                            console.log("uploaded photo", url);
                          }
                          setUploadingImage(false);
                        }}
                      />
                    </LoadingButton>
                  ) : (
                    <LoadingButton
                      color="secondary"
                      component="label"
                      loading={uploadingImage}
                      sx={{
                        position: "relative",
                        width: "100%",
                        p: 0,
                      }}
                    >
                      <img
                        src={props.values.sellerPhoto}
                        width={72}
                        height={72}
                        style={{ objectFit: "cover", borderRadius: "50%" }}
                      />
                      <input
                        hidden
                        accept="image/*"
                        type="file"
                        onChange={async (e) => {
                          // e.preventDefault();
                          setUploadingImage(true);
                          if (
                            user &&
                            e.target.files &&
                            e.target.files.length > 0
                          ) {
                            const image = e.target.files[0];
                            const resizedImage = await resizeFile(
                              image,
                              300,
                              300
                            );
                            var url = await uploadImage(
                              resizedImage as File,
                              user.uid
                            );
                            props.setFieldValue("sellerPhoto", url);
                            console.log("uploaded photo", url);
                          }
                          setUploadingImage(false);
                        }}
                      />
                    </LoadingButton>
                  )}
                </Box>

                <TextField
                  fullWidth
                  margin="normal"
                  required
                  id="businessName"
                  name="businessName"
                  label="Business name"
                  value={props.values.businessName}
                  onChange={props.handleChange}
                  onBlur={props.handleBlur}
                  error={
                    props.touched.businessName &&
                    Boolean(props.errors.businessName)
                  }
                  helperText={
                    props.touched.businessName && props.errors.businessName
                  }
                  sx={{ mt: 3 }}
                />

                <FormControl required fullWidth margin="normal">
                  <FormLabel>Fulfillment</FormLabel>
                  <RadioGroup
                    name="fulfillmentMethod"
                    id="fulfillmentMethod"
                    value={props.values.fulfillmentMethod}
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                  >
                    <Stack direction="row" spacing={1}>
                      <FormControlLabel
                        value="Pickup only"
                        control={<Radio />}
                        label="Pickup only"
                      />
                      <FormControlLabel
                        value="Pickup or shipping"
                        control={<Radio />}
                        label="Pickup or shipping"
                      />
                    </Stack>
                  </RadioGroup>
                </FormControl>

                <Grid container columnSpacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      margin="dense"
                      required
                      id="defaultPickupAddress"
                      name="defaultPickupAddress"
                      label="Default pickup address"
                      value={props.values.defaultPickupAddress}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      error={
                        props.touched.defaultPickupAddress &&
                        Boolean(props.errors.defaultPickupAddress)
                      }
                      helperText={
                        props.touched.defaultPickupAddress &&
                        props.errors.defaultPickupAddress
                          ? props.errors.defaultPickupAddress
                          : "This is likely your home address. It’s saved to set up presale pickup locations and will never be shared without your permission."
                      }
                      sx={{ mb: 1 }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <TextField
                      fullWidth
                      margin="normal"
                      required
                      id="sellerCity"
                      name="sellerCity"
                      label="City"
                      value={props.values.sellerCity}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      error={
                        props.touched.sellerCity &&
                        Boolean(props.errors.sellerCity)
                      }
                      helperText={
                        props.touched.sellerCity && props.errors.sellerCity
                      }
                    />
                  </Grid>
                  <Grid item xs={6} sm={4}>
                    <FormControl required fullWidth sx={{ mt: 2, mb: 1 }}>
                      <Autocomplete
                        freeSolo
                        options={USStates.map((state) => state.name)}
                        onChange={(event: any, newValue: string | null) => {
                          if (newValue) {
                            props.setFieldValue(
                              "sellerState",
                              getStateAbbreviation(newValue)
                            );
                          }
                        }}
                        inputValue={getStateName(props.values.sellerState)}
                        onInputChange={(event, newInputValue) => {
                          props.setFieldValue(
                            "sellerState",
                            getStateAbbreviation(newInputValue)
                          );
                        }}
                        renderInput={(params) => (
                          <TextField required label="State" {...params} />
                        )}
                      />
                    </FormControl>
                    {/* <FormControl required fullWidth sx={{ mt: 2, mb: 1 }}>
                      <InputLabel>State</InputLabel>
                      <Select
                        label="State"
                        value={props.values.sellerState}
                        onChange={(e) => {
                          props.setFieldValue("sellerState", e.target.value);
                        }}
                      >
                        {USStates.map((state) => (
                          <MenuItem value={state.abbreviation} key={state.name}>
                            {state.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl> */}
                  </Grid>
                  <Grid item xs={6} sm={4}>
                    <TextField
                      fullWidth
                      margin="normal"
                      required
                      id="sellerZip"
                      name="sellerZip"
                      label="Zip code"
                      value={props.values.sellerZip}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      error={
                        props.touched.sellerZip &&
                        Boolean(props.errors.sellerZip)
                      }
                      helperText={
                        props.touched.sellerZip && props.errors.sellerZip
                      }
                    />
                  </Grid>
                </Grid>
                <FormControlLabel
                  control={<Checkbox />}
                  name="shipOnlyToMyState"
                  label="Only ship to my state"
                  onChange={props.handleChange}
                  checked={props.values.shipOnlyToMyState}
                />
              </DialogContent>
              <DialogActions>
                {autoPop ? null : (
                  <Button
                    onClick={handleCloseProfile}
                    disabled={!user}
                    color="secondary"
                  >
                    Cancel
                  </Button>
                )}
                <Button type="submit" variant="contained" disabled={!user}>
                  Save
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    </div>
  );
};
