import { FormikProps } from "formik";
import { BusinessInfo } from "Model/BusinessInfo";

// ui related
import {
  TextField,
  Grid,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Stack,
  FormLabel,
} from "@mui/material";
import { FormSection } from "Components/BusinessView/FormSection";
import { CustomerRequestInfo } from "Model/CustomerRequestInfo";

export const CustomerContactInfo = ({
  customerFormik,
}: {
  customerFormik: FormikProps<CustomerRequestInfo>;
}) => {
  return (
    <FormSection id="contact" sectionName="Contact info">
      <Grid container columnSpacing={2} sx={{ mt: -1 }}>
        <Grid item xs={6}>
          <TextField
            fullWidth
            margin="normal"
            required
            id="firstName"
            label="First name"
            value={customerFormik.values.firstName}
            onChange={customerFormik.handleChange}
            onBlur={customerFormik.handleBlur}
            error={
              customerFormik.touched.firstName &&
              Boolean(customerFormik.errors.firstName)
            }
            helperText={
              customerFormik.touched.firstName &&
              customerFormik.errors.firstName
            }
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            margin="normal"
            required
            id="lastName"
            label="Last name"
            value={customerFormik.values.lastName}
            onChange={customerFormik.handleChange}
            onBlur={customerFormik.handleBlur}
            error={
              customerFormik.touched.lastName &&
              Boolean(customerFormik.errors.lastName)
            }
            helperText={
              customerFormik.touched.lastName && customerFormik.errors.lastName
            }
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            margin="normal"
            required
            id="phoneNumber"
            label="Phone number"
            onChange={customerFormik.handleChange}
            onBlur={customerFormik.handleBlur}
            error={
              customerFormik.touched.phoneNumber &&
              Boolean(customerFormik.errors.phoneNumber)
            }
            helperText={
              customerFormik.touched.phoneNumber &&
              customerFormik.errors.phoneNumber
            }
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            margin="normal"
            required
            id="email"
            type="email"
            label="Email"
            onChange={customerFormik.handleChange}
            onBlur={customerFormik.handleBlur}
            error={
              customerFormik.touched.email &&
              Boolean(customerFormik.errors.email)
            }
            helperText={
              customerFormik.touched.email && customerFormik.errors.email
            }
          />
        </Grid>
      </Grid>

      <FormControl required fullWidth sx={{ mt: 1 }}>
        <FormLabel>Preferred contact method</FormLabel>
        <RadioGroup
          name="preferredContact"
          value={customerFormik.values.preferredContact}
          onChange={customerFormik.handleChange}
        >
          <Stack direction="row" alignItems="center">
            <FormControlLabel
              value="Email"
              control={<Radio />}
              label="Email"
              checked={customerFormik.values.preferredContact === "Email"}
            />
            <FormControlLabel
              value="Text"
              control={<Radio />}
              label="Text"
              checked={customerFormik.values.preferredContact === "Text"}
            />
          </Stack>
        </RadioGroup>
      </FormControl>
    </FormSection>
  );
};
