import {
  ORDER_STATUS_CANCELED,
  ORDER_STATUS_COMPLETED,
  // ORDER_STATUS_TOBAKE,
  // ORDER_STATUS_TODECORATE,
  ORDER_STATUS_INPROGRESS,
  ORDER_STATUS_NOTSTARTED,
  ORDER_STATUS_READY,
} from "Constants";

// This is for display in Order page
export const columnOrder = [
  { name: ORDER_STATUS_NOTSTARTED, showCount: false },
  { name: ORDER_STATUS_INPROGRESS, showCount: true },
  // { name: ORDER_STATUS_TOBAKE, showCount: true },
  // { name: ORDER_STATUS_TODECORATE, showCount: false },
  { name: ORDER_STATUS_READY, showCount: false },
];

export interface OrdersStatus {
  totalOrders: number;
  ordersMap: { [key: string]: string[] };
}

export const defaultOrdersStatus: OrdersStatus = {
  totalOrders: 0,
  ordersMap: {
    [ORDER_STATUS_NOTSTARTED]: [],
    [ORDER_STATUS_INPROGRESS]: [],
    // [ORDER_STATUS_TOBAKE]: [],
    // [ORDER_STATUS_TODECORATE]: [],
    [ORDER_STATUS_READY]: [],
    [ORDER_STATUS_COMPLETED]: [],
    [ORDER_STATUS_CANCELED]: [],
  },
};
