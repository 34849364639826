import moment from "moment";

import { Grid } from "@mui/material";
import { CalCell } from "./CalCell";
import { ViewOrder } from "../../Components/ViewOrder";
import { useAppSelector } from "Redux/hooks";
import { ordersByCustomerDateSelector } from "Redux/selectors";
import { getDateMMDDYYYY } from "Util/dateUtil";

type CalGridProps = {
  month: string;
};

export const CalGrid = ({ month }: CalGridProps) => {
  const currentMonth = moment(month);
  const previousMonth = moment(month).subtract(1, "month");
  const nextMonth = moment(month).add(1, "month");
  const daysInPreviousMonth = previousMonth.daysInMonth();
  const ordersByCustomerDate = useAppSelector(ordersByCustomerDateSelector);
  const daysInMonth = moment(month).daysInMonth();
  const firstDayOfMonth = moment(month).startOf("month").weekday();
  const prevMonthDaysToShow = firstDayOfMonth;
  const nextMonthDaysToShow = 7 - ((prevMonthDaysToShow + daysInMonth) % 7);

  return (
    <Grid
      container
      flex={1}
      gap={0}
      sx={{ border: ".5px solid rgba(0, 0, 0, 0.12)" }}
    >
      {/* ------------- days from prev month ------------- */}
      {Array.from(Array(prevMonthDaysToShow), (e, index) => {
        const date = getDateMMDDYYYY(
          new Date(
            previousMonth.year(),
            previousMonth.month(),
            daysInPreviousMonth - prevMonthDaysToShow + index + 1
          )
        );
        const orders = ordersByCustomerDate.get(date) || [];

        return (
          <CalCell
            key={date}
            date={daysInPreviousMonth - prevMonthDaysToShow + index + 1}
            dateFullString={date}
            today={
              moment([moment().year(), moment().month()]).diff(
                month,
                "months"
              ) === -1 &&
              moment().format("D") ===
                (
                  daysInPreviousMonth -
                  prevMonthDaysToShow +
                  index +
                  1
                ).toString()
            }
          >
            {orders.map((order) => (
              <ViewOrder
                view="calendar"
                orderInfo={order}
                key={order.orderId}
              />
            ))}
          </CalCell>
        );
      })}

      {/* ------------- days from current month ------------- */}
      {Array.from(Array(daysInMonth), (e, index) => {
        const date = getDateMMDDYYYY(
          new Date(currentMonth.year(), currentMonth.month(), index + 1)
        );
        const orders = ordersByCustomerDate.get(date) || [];
        return (
          <CalCell
            key={date}
            dateFullString={date}
            date={index + 1}
            currentMonth
            today={
              moment().isSame(month, "month") &&
              moment().format("D") === (index + 1).toString()
            }
          >
            {orders.map((order) => (
              <ViewOrder
                view="calendar"
                orderInfo={order}
                key={order.orderId}
              />
            ))}
          </CalCell>
        );
      })}

      {/* ------------- days from next month ------------- */}
      {Array.from(Array(nextMonthDaysToShow), (e, index) => {
        const date = getDateMMDDYYYY(
          new Date(nextMonth.year(), nextMonth.month(), index + 1)
        );
        const orders = ordersByCustomerDate.get(date) || [];
        return (
          <CalCell
            key={date}
            dateFullString={date}
            date={index + 1}
            today={
              moment([moment().year(), moment().month()]).diff(
                month,
                "months"
              ) === 1 && moment().format("D") === (index + 1).toString()
            }
          >
            {orders.map((order) => (
              <ViewOrder
                view="calendar"
                orderInfo={order}
                key={order.orderId}
              />
            ))}
          </CalCell>
        );
      })}
    </Grid>
  );
};
