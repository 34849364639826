import React, { useState } from "react";
import { useLocation, Path } from "react-router-dom";
import { login } from "Util/firebaseHelper";

import { Button, Box, Typography, Stack } from "@mui/material";
import {
  GoogleSignInButton,
  CardStyle,
  ContentWrapNarrow,
} from "Components/AllComponents";

const SignIn = () => {
  return (
    <div className="page-background-1">
      <ContentWrapNarrow>
        <CardStyle>
          <Typography variant="h5">Sign in</Typography>
          <GoogleSignInButton
            onClick={() => {
              login();
            }}
          />
        </CardStyle>
      </ContentWrapNarrow>
    </div>
  );
};

export default SignIn;
