import { useSession } from "Context/userAuthContext";
import { useAppDispatch, useAppSelector } from "Redux/hooks";
import { businessInfoSelector } from "Redux/selectors";
import { editBusinessInfo } from "Redux/actionCreators";

// ui related
import {
  Typography,
  Switch,
  List,
  ListItem,
  ListItemText,
  ListItemButton,
  Box,
} from "@mui/material";
import { FormikProps } from "formik";
import { BusinessInfo } from "Model/BusinessInfo";

export const QuoteRequestFormSettings = ({
  formik,
}: {
  formik: FormikProps<BusinessInfo>;
}) => {
  const { user, sellerProfile } = useSession();

  return (
    <Box>
      <Typography variant="subtitle2" sx={{ mb: 1 }}>
        Form sections
      </Typography>
      <List component="nav" sx={{ p: 0 }}>
        <ListItem disableGutters>
          <ListItemText primary="Date needed" />
        </ListItem>
        <ListItem disableGutters>
          <ListItemText primary="Quantity" />
        </ListItem>
        <ListItem disableGutters>
          <ListItemText primary="Contact info" />
        </ListItem>
        {sellerProfile.fulfillmentMethod === "Pickup or shipping" ? (
          <ListItem disableGutters>
            <ListItemText primary="Fulfillment" />
          </ListItem>
        ) : null}
        <ListItem disableGutters>
          <ListItemText primary="My bucket list" />
          <Switch
            edge="end"
            onChange={(e: any) => {
              formik.setFieldValue("showBucketLists", e.target.checked);
            }}
            checked={formik.values.showBucketLists}
          />
        </ListItem>
        <ListItem disableGutters>
          <ListItemText primary="Request details" />
        </ListItem>
        <ListItem disableGutters>
          <ListItemText primary="Design inspirations" />
        </ListItem>
        <ListItem disableGutters>
          <ListItemText primary="Flavor" />
          <Switch
            edge="end"
            onChange={(e: any) => {
              formik.setFieldValue("showFlavors", e.target.checked);
            }}
            checked={formik.values.showFlavors}
          />
        </ListItem>
        <ListItem disableGutters>
          <ListItemText primary="Packaging" />
          <Switch
            edge="end"
            onChange={(e: any) => {
              formik.setFieldValue("showPackagings", e.target.checked);
            }}
            checked={formik.values.showPackagings}
          />
        </ListItem>
        <ListItem disableGutters>
          <ListItemText primary="Agreements" />
        </ListItem>
        <ListItem disableGutters>
          <ListItemText primary="Questions/comments" />
        </ListItem>
      </List>
    </Box>
  );
};
