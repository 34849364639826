import { combineReducers } from "@reduxjs/toolkit";
import { businessInfoSlice } from "./Reducers/businessInfo";
import { ordersSlice } from "./Reducers/orders";
import { ordersStatusSlice } from "./Reducers/ordersStatus";
import { presalesSlice } from "./Reducers/presales";
import { selectedOrderSlice } from "./Reducers/selectedOrder";
import { selectedPresaleSlice } from "./Reducers/selectedPresale";
import { sellerProfileSlice } from "./Reducers/sellerProfile";
import { cartSlice } from "./Reducers/cart";
import { presaleOrdersSlice } from "./Reducers/presaleOrders";
import { requestsSlice } from "./Reducers/requests";
import { selectedRequestSlice } from "./Reducers/selectedRequest";
import { subscribersSlice } from "./Reducers/subscribers";

export const rootReducer = combineReducers({
  orders: ordersSlice.reducer,
  selectedOrder: selectedOrderSlice.reducer,
  businessInfo: businessInfoSlice.reducer,
  sellerProfile: sellerProfileSlice.reducer,
  ordersStatus: ordersStatusSlice.reducer,
  presales: presalesSlice.reducer,
  selectedPresale: selectedPresaleSlice.reducer,
  cart: cartSlice.reducer,
  presaleOrders: presaleOrdersSlice.reducer,
  requests: requestsSlice.reducer,
  selectedRequest: selectedRequestSlice.reducer,
  subscribers: subscribersSlice.reducer,
});
