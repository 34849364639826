import * as yup from "yup";

const phoneRegExp =
  /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;

export const customerRequestInfoValidationSchema = (minOrder: number) =>
  yup.object({
    firstName: yup.string().required("This field is required"),
    lastName: yup.string().required("This field is required"),
    email: yup.string().email().required("This field is required"),
    phoneNumber: yup
      .string()
      .matches(phoneRegExp, "Phone number is not valid")
      .required("This field is required"),
    event: yup.string().required("This field is required"),
    count: yup
      .number()
      .when("unit", {
        is: Unit.Dozen,
        then: yup
          .number()
          .min(minOrder, "Minimum: " + minOrder + " dozen")
          .required("This field is required"),
      })
      .when("unit", {
        is: Unit.Individual,
        then: yup
          .number()
          .min(minOrder, "Minimum: " + minOrder * 12 + " cookies")
          .required("This field is required"),
      }),
    shippingAddress1: yup.string().when("customerFulfillment", {
      is: "Shipping",
      then: yup.string().required("This field is required"),
    }),
    shippingCity: yup.string().when("customerFulfillment", {
      is: "Shipping",
      then: yup.string().required("This field is required"),
    }),
    shippingState: yup.string().when("customerFulfillment", {
      is: "Shipping",
      then: yup.string().required("This field is required"),
    }),
    shippingZip: yup.string().when("customerFulfillment", {
      is: "Shipping",
      then: yup.string().required("This field is required"),
    }),
    paymentChecked: yup.boolean().oneOf([true], "This field is required"),
  });

export enum Unit {
  Dozen,
  Individual,
}

export interface CustomerRequestInfo {
  requestId: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  preferredContact: string;
  customerDate: string;
  customerFulfillment: string;
  shippingAddress1: string;
  shippingAddress2: string;
  shippingCity: string;
  shippingState: string;
  shippingZip: string;
  event: string;
  theme: string;
  colors: string;
  personalization: string;
  count: number;
  questions: string;
  paymentChecked: boolean;
  bucketLists: string;
  flavors: string[];
  packagings: string[];
  paymentTypes: string[];
  designInspirations: string[];
  created: string;
  unit: Unit;
  orderId: string;
  joinEmailList: boolean;
  status: string;
  quoteId: string;
  orderTotal: number;
  notes: string; // this is for notes to customer, different from the one in order which is used by seller
  updated: string;
}

export const DefaultCustomerRequestInfo = {
  requestId: "",
  firstName: "",
  lastName: "",
  email: "",
  phoneNumber: "",
  preferredContact: "Email",
  customerDate: new Date().toISOString(),
  customerFulfillment: "Pickup",
  shippingAddress1: "",
  shippingAddress2: "",
  shippingCity: "",
  shippingState: "",
  shippingZip: "",
  event: "",
  theme: "",
  colors: "",
  personalization: "",
  count: 1,
  questions: "",
  paymentChecked: false,
  bucketLists: "",
  flavors: [] as string[],
  packagings: [] as string[],
  paymentTypes: [] as string[],
  designInspirations: [] as string[],
  created: new Date().toISOString(),
  unit: Unit.Dozen,
  orderId: "",
  joinEmailList: true,
  status: "",
  quoteId: "",
  orderTotal: 0,
  notes: "",
  updated: new Date().toISOString(),
};
