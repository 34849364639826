import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { readBusinessInfo, readSellerInfo } from "Util/firebaseHelper";
import { BusinessInfo } from "Model/BusinessInfo";
import { ThemeProvider } from "@mui/material";
import { createCustomTheme } from "Components/Styles/Styles";

// ui related
import { Box } from "@mui/material";
import {
  CustomerQuoteRequestForm,
  ContentWrapNarrow,
  CreatedWith,
  Loading,
} from "Components/AllComponents";
import {
  Availability,
  AvailabilityType,
  defaultAvailability,
} from "Model/Availability";
import { getNewDateWithoutHours } from "Util/dateUtil";
import { Timestamp } from "@firebase/firestore";
import { Profile, defaultProfile, defaultSquareState } from "Model/Profile";
import { DEFAULT_COLOR_PALETTE } from "Constants";
import { NotFound } from "Components/NotFound";

const DisplayForm = () => {
  const paid = false;
  let { formId } = useParams<"formId">();
  let { userId } = useParams<"userId">();
  userId = userId?.toLowerCase();
  const [sellerProfile, setSellerProfile] = useState<Profile>(defaultProfile);
  formId = formId?.toLowerCase();
  const [businessInfo, setBusinessInfo] = useState<BusinessInfo>();
  const [availability, setAvailability] =
    useState<Availability>(defaultAvailability);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      if (userId) {
        const form = await readSellerInfo(userId);
        let data = form.data();
        if (data) {
          var type =
            (data?.availability?.type === AvailabilityType.acceptBetween ||
              data?.availability?.type === AvailabilityType.acceptUntil) &&
            (data?.availability.end instanceof Timestamp
              ? data?.availability.end.toDate().toISOString()
              : data?.availability?.end) < getNewDateWithoutHours()
              ? AvailabilityType.doNotAcceptOrder
              : data?.availability?.type || AvailabilityType.acceptNow;
          setSellerProfile({
            businessName: data?.businessName,
            sellerName: data?.sellerName || "",
            sellerIntro: data?.sellerIntro || "",
            sellerCity: data?.sellerCity || "",
            sellerState: data?.sellerState || "",
            sellerZip: data?.sellerZip || "",
            shipOnlyToMyState: data?.shipOnlyToMyState || false,
            fulfillmentMethod: data?.fulfillmentMethod || "Pickup only",
            defaultPickupAddress: data?.defaultPickupAddress || "",
            sellerPhoto: data?.sellerPhoto || "",
            username: data?.username || "",
            links: data?.links || [],
            forms: data?.forms || [],
            availability: {
              type: type,
              start:
                (data?.availability.start instanceof Timestamp
                  ? data?.availability.start.toDate().toISOString()
                  : data?.availability?.start) || getNewDateWithoutHours(),
              end:
                (data?.availability.end instanceof Timestamp
                  ? data?.availability.end.toDate().toISOString()
                  : data?.availability?.end) || getNewDateWithoutHours(),
              lead: data?.availability?.lead || 1,
              dateBlocked: data?.availability?.dateBlocked || [],
            },
            presales: data?.presales || [],
            livePresales: data?.livePresales || [],
            payments: data?.payments || [],
            uid: data?.uid || "",
            sellerEmail: data?.sellerEmail || "",
            colorPalette: data?.colorPalette || DEFAULT_COLOR_PALETTE,
            squareState: data?.squareState || defaultSquareState,
            disableCustomerEmailList: data?.disableCustomerEmailList || false,
            imageGallery: data?.imageGallery || [],
            hideImageGallery: data?.hideImageGallery || false,
          });
        }
      }
      if (formId) {
        const form = await readBusinessInfo(formId);
        if (form === null) {
        } else {
          console.log(form.data());
          let content = form.data();
          if (content) {
            setBusinessInfo({
              id: content.id,
              businessName: content.businessName,
              formIntro: content.formIntro,
              sellerName: content.sellerName,
              sellerIntro: content.sellerIntro,
              sellerCity: content.sellerCity,
              sellerState: content.sellerState,
              shipOnlyToMyState: content.shipOnlyToMyState || false,
              fulfillmentMethod: content.fulfillmentMethod,
              minOrder: content.minOrder,
              priceChoice: content.priceChoice,
              price: content.price,
              sellerBookedUp: content.sellerBookedUp,
              sellerAvailability: content.sellerAvailability,
              orderDetails: content.orderDetails,
              sellerEmail: content.sellerEmail,
              bucketLists: content.bucketLists || [""],
              flavors: content.flavors || [""],
              packagings: content.packagings || [""],
              paymentTypes: content.paymentTypes || [""],
              agreements: content.agreements || "",
              showBucketLists: content.showBucketLists || false,
              showFlavors: content.showFlavors || false,
              showPackagings: content.showPackagings || false,
              showPaymentTypes: content.showPaymentTypes || false,
              sellerPhoto: content.sellerPhoto || "",
              aic: content.aic || false,
              username: content.username || "",
            });
            setAvailability({
              type: content?.availability?.type || AvailabilityType.acceptNow,
              start:
                (content?.availability.start instanceof Timestamp
                  ? content?.availability.start.toDate().toISOString()
                  : content?.availability?.start) || getNewDateWithoutHours(),
              end:
                (content?.availability.end instanceof Timestamp
                  ? content?.availability.end.toDate().toISOString()
                  : content?.availability?.end) || getNewDateWithoutHours(),
              lead: content?.availability?.lead || 1,
              dateBlocked: content?.availability?.dateBlocked || [],
            });
          }
        }
      }
      setLoading(false);
    }
    fetchData();
  }, []);

  console.log(formId);

  return (
    <ThemeProvider theme={createCustomTheme(sellerProfile.colorPalette)}>
      {loading ? (
        <Loading />
      ) : availability.type === AvailabilityType.doNotAcceptOrder ? (
        <NotFound></NotFound>
      ) : (
        <Box
          sx={{ p: { xs: 2, sm: 3 }, backgroundColor: "background.default" }}
        >
          <ContentWrapNarrow>
            {businessInfo ? (
              <CustomerQuoteRequestForm
                uid={sellerProfile.uid}
                sample={false}
                paid={paid}
                form={businessInfo}
                showCta={true}
                availability={availability}
                disableCustomerEmailList={
                  sellerProfile.disableCustomerEmailList
                }
              />
            ) : null}
          </ContentWrapNarrow>

          {/* <CreatedWith color="dark" /> */}
        </Box>
      )}
    </ThemeProvider>
  );
};

export default DisplayForm;
