import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { PRESALES_TABLE_NAME, PRESALEORDERS_COLLECTION_NAME } from "Constants";
import { BusinessInfo } from "Model/BusinessInfo";
import { PresaleInfo } from "Model/PresaleInfo";
import { PresaleOrder } from "Model/PresaleOrder";
import { Profile } from "Model/Profile";
import store from "Redux/store";
import {
  createPresaleOrder,
  updatePresaleOrder,
  db,
  deletePresaleCustomerOrder,
  readPresaleOrders,
} from "Util/firebaseHelper";
import { collection, onSnapshot } from "firebase/firestore";

interface PresaleOrderState {
  orders: Record<string, PresaleOrder[]>;
  loading: boolean;
  error: string | null;
}

const initialState: PresaleOrderState = {
  orders: {},
  loading: false,
  error: null,
};

// Async thunk to fetch presale orders from Firebase
export const fetchPresaleOrders = createAsyncThunk(
  "presaleOrder/fetchPresaleOrders",
  async (presaleIds: string[], thunkAPI) => {
    const unsubscribeFunctions: (() => void)[] = [];

    // Loop through the presaleIds and attach listeners for each presale
    presaleIds.forEach((presaleId) => {
      const ordersCollection = collection(
        db,
        PRESALES_TABLE_NAME,
        presaleId,
        PRESALEORDERS_COLLECTION_NAME
      );

      const unsubscribe = onSnapshot(ordersCollection, (querySnapshot) => {
        const presaleOrders: PresaleOrder[] = [];
        querySnapshot.forEach((doc) => {
          const presaleOrder: PresaleOrder = doc.data() as PresaleOrder;
          if (!presaleOrder.items) {
            presaleOrder.items = {};
          }
          Object.values(presaleOrder.items).forEach((item) => {
            if (!item.customization) {
              item.customization = [];
              if (item.personalization.length === 0) {
                for (let i = 0; i < item.count; i++) {
                  item.customization.push({
                    price: item.price,
                    flavor: "",
                    color: "",
                    personalization: "",
                    count: 1,
                  });
                }
              } else {
                item.personalization.forEach((personalization) => {
                  item.customization.push({
                    price: item.price,
                    flavor: "",
                    color: "",
                    personalization: personalization,
                    count: 1,
                  });
                });
              }
            }
          });
          presaleOrders.push(presaleOrder);
        });
        // Handle the result (e.g., update state, trigger callback)
        thunkAPI.dispatch(
          presaleOrdersSlice.actions.setPresaleOrders({
            presaleId: presaleId,
            orders: presaleOrders,
          })
        );
      });

      unsubscribeFunctions.push(unsubscribe);
    });

    // Return a function to unsubscribe from all listeners
    return () => {
      unsubscribeFunctions.forEach((unsubscribe) => unsubscribe());
    };
  }
);

// Async thunk to add a presale order to Firebase
export const addPresaleOrder = createAsyncThunk(
  "presaleOrder/addPresaleOrder",
  async ({
    order,
    sellerProfile,
    presale,
    sendMail,
  }: {
    order: PresaleOrder;
    sellerProfile: Profile;
    presale: PresaleInfo;
    sendMail: boolean;
  }) => {
    await createPresaleOrder(order, sellerProfile, presale, sendMail);
    return order;
  }
);

// Async thunk to add a presale order to Firebase
export const modifyPresaleOrder = createAsyncThunk(
  "presaleOrder/modifyPresaleOrder",
  async (order: PresaleOrder) => {
    await updatePresaleOrder(order);
    return order;
  }
);

// Async thunk to add a presale order to Firebase
export const deletePresaleOrder = createAsyncThunk(
  "presaleOrder/deletePresaleOrder",
  async ({ presaleId, orderId }: { presaleId: string; orderId: string }) => {
    await deletePresaleCustomerOrder(presaleId, orderId);
    return { presaleId, orderId };
  }
);

export const presaleOrdersSlice = createSlice({
  name: "presaleOrder",
  initialState,
  reducers: {
    setPresaleOrders: (
      state,
      { payload }: PayloadAction<{ presaleId: string; orders: PresaleOrder[] }>
    ) => {
      const { presaleId, orders } = payload;
      state.orders[presaleId] = orders;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPresaleOrders.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchPresaleOrders.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(fetchPresaleOrders.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message ?? "Failed to fetch presale orders";
      })
      .addCase(addPresaleOrder.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        addPresaleOrder.fulfilled,
        (state, action: PayloadAction<PresaleOrder>) => {
          state.loading = false;
          state.error = null;
          const { presaleId } = action.payload;
          if (presaleId in state.orders) {
            state.orders[presaleId].push(action.payload);
          } else {
            state.orders[presaleId] = [action.payload];
          }
        }
      )
      .addCase(addPresaleOrder.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message ?? "Failed to add presale order";
      })
      .addCase(
        modifyPresaleOrder.fulfilled,
        (state, action: PayloadAction<PresaleOrder>) => {
          state.loading = false;
          state.error = null;
          const { presaleId } = action.payload;
          if (presaleId in state.orders) {
            const index = state.orders[presaleId].findIndex(
              (order) => order.orderId === action.payload.orderId
            );
            if (index >= 0) {
              state.orders[presaleId][index] = action.payload;
            }
          }
        }
      )
      .addCase(modifyPresaleOrder.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message ?? "Failed to modify presale order";
      })
      .addCase(
        deletePresaleOrder.fulfilled,
        (
          state,
          action: PayloadAction<{ presaleId: string; orderId: string }>
        ) => {
          state.loading = false;
          state.error = null;
          const { presaleId, orderId } = action.payload;
          state.orders[presaleId] = state.orders[presaleId].filter(
            (o) => o.orderId !== orderId
          );
        }
      )
      .addCase(deletePresaleOrder.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message ?? "Failed to delete presale order";
      });
  },
});
