import { FormikProps } from "formik";
import { BusinessInfo } from "Model/BusinessInfo";

// ui related
import {
  TextField,
  RadioGroup,
  Radio,
  FormControlLabel,
  InputAdornment,
  Stack,
  Checkbox,
} from "@mui/material";
import { FormEditor } from "Components/BusinessView/FormEditor";
import { FormSection } from "Components/BusinessView/FormSection";

export const Quantity = ({ formik }: { formik: FormikProps<BusinessInfo> }) => {
  return (
    <FormSection id="quantity" sectionName="Quantity">
      <Stack spacing={2}>
        <FormEditor visible>
          <FormControlLabel
            label={
              "Accept individual cookies (minimum quantity: " +
              Math.round(formik.values.minOrder * 12) +
              " cookies)"
            }
            name="aic"
            checked={formik.values.aic === true}
            control={<Checkbox />}
            onChange={formik.handleChange}
          />
        </FormEditor>

        <Stack>
          {formik.values.aic ? (
            <RadioGroup sx={{ mb: 1 }}>
              <Stack direction="row" alignItems="center">
                <FormControlLabel disabled control={<Radio />} label="Dozen" />
                <FormControlLabel
                  disabled
                  control={<Radio checked={false} />}
                  label="Individual"
                />
              </Stack>
            </RadioGroup>
          ) : null}

          <TextField
            fullWidth
            disabled
            id="count"
            name="count"
            type="number"
            onWheel={(e: any) => e.target.blur()}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">dozen</InputAdornment>
              ),
              inputProps: { min: formik.values.minOrder },
            }}
          />
        </Stack>
      </Stack>
    </FormSection>
  );
};
