import { Grid, Typography } from "@mui/material";
import { LabeledText } from "Components/LabeledText";
import PhoneAndroidOutlinedIcon from "@mui/icons-material/PhoneAndroidOutlined";
import AlternateEmailOutlinedIcon from "@mui/icons-material/AlternateEmailOutlined";
import { toast } from "react-toastify";

type Props = {
  phone: string;
  email: string;
  preferredContact?: string;
};

export const ContactInfo = ({ phone, email, preferredContact }: Props) => {
  return (
    <div>
      <Typography variant="h6" color="text.primary" sx={{ mb: 1 }}>
        Contact
      </Typography>

      <Grid container rowSpacing={2} columnSpacing={{ xs: 2, md: 3 }}>
        <Grid
          item
          sm={6}
          xs={12}
          onClick={() => {
            navigator.clipboard.writeText(phone);
            toast(`Phone number copied.`, {
              position: "top-center",
              autoClose: 800,
            });
          }}
          sx={{ "&:hover": { cursor: "pointer" } }}
        >
          <LabeledText
            icon={<PhoneAndroidOutlinedIcon />}
            label="Phone number"
            text={phone}
            bold
          />
        </Grid>
        <Grid
          item
          sm={6}
          xs={12}
          onClick={() => {
            navigator.clipboard.writeText(email);
            toast(`Email copied.`, {
              position: "top-center",
              autoClose: 800,
            });
          }}
          sx={{ "&:hover": { cursor: "pointer" } }}
        >
          <LabeledText
            icon={<AlternateEmailOutlinedIcon />}
            label="Email"
            text={email}
            bold
          />
        </Grid>
      </Grid>

      {preferredContact ? (
        <Typography color="text.secondary" sx={{ mt: 1.5 }}>
          Preferred contact method:{" "}
          <Typography variant="subtitle2" color="text.primary" component="span">
            {preferredContact.toLowerCase()}
          </Typography>
        </Typography>
      ) : null}
    </div>
  );
};
