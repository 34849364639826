import { FormikProps } from "formik";
import { BusinessInfo } from "Model/BusinessInfo";

// ui related
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from "@mui/material";
import { FormSection } from "Components/BusinessView/FormSection";
import { CustomerRequestInfo } from "Model/CustomerRequestInfo";
import { getStateName } from "Util/helpers";

export const CustomerFulfillment = ({
  businessInfo,
  customerFormik,
}: {
  businessInfo: BusinessInfo;
  customerFormik: FormikProps<CustomerRequestInfo>;
}) => {
  if (businessInfo.fulfillmentMethod === "Pickup or shipping") {
    return (
      <FormSection id="fulfillment" sectionName="Fulfillment">
        <FormControl required fullWidth>
          <RadioGroup
            name="customerFulfillment"
            value={customerFormik.values.customerFulfillment}
            onChange={customerFormik.handleChange}
          >
            <Stack direction="row" alignItems="center">
              <FormControlLabel
                value="Pickup"
                control={<Radio />}
                label="Pickup"
                checked={customerFormik.values.customerFulfillment === "Pickup"}
              />
              <FormControlLabel
                value="Shipping"
                control={<Radio />}
                label="Shipping"
                checked={
                  customerFormik.values.customerFulfillment === "Shipping"
                }
              />
            </Stack>
          </RadioGroup>
        </FormControl>
        {businessInfo.shipOnlyToMyState &&
        customerFormik.values.customerFulfillment === "Shipping" ? (
          <Typography variant="body2" color="text.secondary">
            Seller can only ship to {getStateName(businessInfo.sellerState)}.
          </Typography>
        ) : null}
      </FormSection>
    );
  } else {
    return null;
  }
};
