import React, { useState } from "react";
import { useTheme } from "@mui/material/styles";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  Link,
  Radio,
  RadioGroup,
  Stack,
  TextField,
} from "@mui/material";
import SettingsIcon from "@mui/icons-material/Settings";
import useMediaQuery from "@mui/material/useMediaQuery";
import {
  PERSONALIZATION_TYPE_BOTH,
  PERSONALIZATION_TYPE_CUSTOM_ONLY,
  PERSONALIZATION_TYPE_PRESET_ONLY,
} from "Constants";

export const PersonalizationSettings = ({
  onChanged,
  initialValue,
}: {
  onChanged: Function;
  initialValue: string;
}) => {
  const theme = useTheme();
  const [openSettings, setOpenSettings] = useState(false);
  const [value, setValue] = useState(initialValue);

  return (
    <>
      <Link
        variant="caption"
        sx={{ textDecoration: "none", fontWeight: 600 }}
        onClick={() => {
          setOpenSettings(true);
        }}
      >
        Change type
      </Link>
      <Dialog
        open={openSettings}
        onClose={() => {
          setValue(initialValue);
          setOpenSettings(false);
        }}
        // fullScreen={fullScreen}
      >
        <DialogTitle>Personalization types</DialogTitle>
        <DialogContent dividers>
          <FormControl>
            <RadioGroup
              name="row-radio-buttons-group"
              value={value}
              onChange={(e) => {
                setValue(e.target.value);
              }}
            >
              <FormControlLabel
                value={PERSONALIZATION_TYPE_CUSTOM_ONLY}
                control={<Radio />}
                label="Free text"
              />
              <FormControlLabel
                value={PERSONALIZATION_TYPE_BOTH}
                control={<Radio />}
                label="Single select or free text"
              />
              <FormControlLabel
                value={PERSONALIZATION_TYPE_PRESET_ONLY}
                control={<Radio />}
                label="Single select"
              />
            </RadioGroup>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button
            color="secondary"
            onClick={() => {
              setValue(initialValue);
              setOpenSettings(false);
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              onChanged(value);
              setOpenSettings(false);
            }}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
