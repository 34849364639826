import * as yup from "yup";

export const businessInfoValidationSchema = yup.object({
  // formIntro: yup.string().required("This field is required"),
  minOrder: yup
    .number()
    .min(0.5, "Minimum order must be more than or equal to 0.5 dozen")
    .required("This field is required"),
  price: yup
    .number()
    .min(1, "Price must be larger than or equal to 1")
    .required("This field is required"),
  // orderDetails: yup.string().required("This field is required"),
  flavors: yup.array().when("showFlavors", {
    is: true,
    then: yup.array().of(yup.string().required("This field is required")),
  }),
  packagings: yup.array().when("showPackagings", {
    is: true,
    then: yup.array().of(yup.string().required("This field is required")),
  }),
  paymentTypes: yup.array().when("showPaymentTypes", {
    is: true,
    then: yup.array().of(yup.string().required("This field is required")),
  }),
  agreements: yup.string().required("This field is required"),
});

export const defaultBusinessInfo: BusinessInfo = {
  id: "",
  businessName: "",
  formIntro:
    "Once I receive your request, I’ll send you a quote and confirm my availability within 24 hours.",
  sellerName: "",
  sellerIntro: "",
  sellerCity: "",
  sellerState: "",
  shipOnlyToMyState: false,
  fulfillmentMethod: "Pickup only",
  minOrder: 1, // minOrder is always in Dozen unit
  priceChoice: "Starting price",
  price: 1,
  sellerBookedUp: false,
  sellerAvailability: 0,
  orderDetails:
    "Hand writing and designs with more details and colors will have higher price.",
  sellerEmail: "",
  bucketLists: [""],
  flavors: [""],
  packagings: [""],
  paymentTypes: [""],
  agreements:
    "After confirming my availability and order details, a deposit of 50% of the total order amount is required to secure your order. If you wish to make changes or cancel and get a full refund, I require a minimum one-week notice.",
  showBucketLists: false,
  showFlavors: false,
  showPackagings: false,
  showPaymentTypes: false,
  sellerPhoto: "",
  aic: false,
  username: "",
};

export interface BusinessInfo {
  id: string;
  businessName: string;
  formIntro: string;
  sellerName: string;
  sellerIntro: string;
  sellerCity: string;
  sellerState: string;
  shipOnlyToMyState: boolean;
  fulfillmentMethod: string;
  minOrder: number;
  priceChoice: string;
  price: number;
  sellerBookedUp: boolean;
  sellerAvailability: number;
  orderDetails: string;
  sellerEmail: string;
  bucketLists: string[];
  flavors: string[];
  packagings: string[];
  paymentTypes: string[];
  agreements: string;
  showBucketLists: boolean;
  showFlavors: boolean;
  showPackagings: boolean;
  showPaymentTypes: boolean;
  sellerPhoto: string;
  aic: boolean; // acceptIndividualCookies,
  username: string; // the id for user
}
