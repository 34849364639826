import { useState } from "react";
import {
  Box,
  Button,
  FormControlLabel,
  FormHelperText,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Stack,
  Switch,
  TextField,
  ToggleButton,
  Typography,
} from "@mui/material";
import { InfoBox } from "Components/AllComponents";
import { updatePresaleItemAsync } from "Redux/Reducers/presales";
import { FormikErrors, FormikProps } from "formik";
import { PresaleInfo } from "Model/PresaleInfo";
import { useAppDispatch } from "Redux/hooks";
import { PresaleItem } from "Model/PresaleItem";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";

type FormSectionProps = {
  index: number;
  presaleId: string;
  presaleItem: PresaleItem;
  formik: FormikProps<PresaleInfo>;
};

export const Designs = ({
  index,
  presaleId,
  presaleItem,
  formik,
}: FormSectionProps) => {
  const dispatch = useAppDispatch();

  const [addingColor, setAddingColor] = useState(false);
  const [editingColor, setEditingColor] = useState(false);
  const [localColors, setLocalColors] = useState<string[]>(presaleItem.colors);
  const [editingColorIndex, setEditingColorIndex] = useState(0);
  const [colorName, setColorName] = useState("");
  const [colorChecked, setColorChecked] = useState(
    presaleItem.colors?.length > 0
  );

  return (
    <Stack spacing={1}>
      <Stack
        flexDirection="row"
        sx={{
          paddingLeft: 1.35,
          height: "27.91px",
        }}
        alignItems="center"
      >
        <FormControlLabel
          sx={{ flex: 1 }}
          control={
            <Switch
              size="small"
              checked={colorChecked}
              onChange={async (event: React.ChangeEvent<HTMLInputElement>) => {
                setColorChecked(event.target.checked);
                if (event.target.checked) {
                  setColorName("");
                  if (localColors.length > 0) {
                    formik.setFieldValue(
                      `presaleItems[${index}].colors`,
                      localColors
                    );
                    dispatch(
                      updatePresaleItemAsync({
                        presaleId,
                        presaleItem: {
                          ...formik.values.presaleItems[index],
                          colors: localColors,
                        },
                      })
                    );
                  }
                  if (localColors.length === 0) {
                    setAddingColor(true);
                  }
                } else {
                  setAddingColor(false);
                  setEditingColor(false);
                  setColorName("");
                  formik.setFieldValue(`presaleItems[${index}].colors`, []);
                  dispatch(
                    updatePresaleItemAsync({
                      presaleId,
                      presaleItem: {
                        ...formik.values.presaleItems[index],
                        colors: [],
                      },
                    })
                  );
                }
              }}
            />
          }
          label="Designs"
          labelPlacement="end"
        />
      </Stack>

      {formik.values.presaleItems[index]?.colors.length > 0 &&
        Boolean(
          (formik.errors.presaleItems?.[index] as FormikErrors<PresaleItem>)
            ?.colors
        ) && (
          <FormHelperText error>
            {formik.values.presaleItems[index].colors.length > 0 &&
              (formik.errors.presaleItems?.[index] as FormikErrors<PresaleItem>)
                ?.colors}
          </FormHelperText>
        )}

      {colorChecked ? (
        <InfoBox sx={{ p: 1.5 }}>
          <Typography
            flex={1}
            variant="caption"
            color="text.secondary"
            sx={{ fontWeight: 500 }}
          >
            Options
          </Typography>
          <List
            dense
            disablePadding
            sx={{
              width: "100%",
            }}
            aria-label="options"
          >
            {formik.values.presaleItems[index]?.colors.map(
              (color: string, colorIndex: number) => (
                <ListItem key={colorIndex} disablePadding>
                  {
                    <ListItemButton
                      disableGutters
                      disabled={
                        addingColor ||
                        (editingColor && colorIndex !== editingColorIndex)
                      }
                      onClick={() => {
                        setEditingColor(true);
                        setEditingColorIndex(colorIndex);
                        setColorName(color);
                      }}
                      sx={{ minHeight: "34.81px" }}
                    >
                      <ListItemText
                        primary={color === "" ? "Missing value" : color}
                        primaryTypographyProps={{
                          color: color === "" ? "warning.main" : "text.primary",
                        }}
                      />
                    </ListItemButton>
                  }
                </ListItem>
              )
            )}

            {addingColor || editingColor ? null : (
              <ListItemButton
                disableGutters
                disabled={editingColor}
                onClick={() => {
                  if (!addingColor) {
                    setAddingColor(true);
                  }
                }}
              >
                <ListItemText
                  primaryTypographyProps={{
                    fontSize: "0.85rem",
                    fontWeight: 600,
                    color: "primary.main",
                  }}
                >
                  Add option
                </ListItemText>
              </ListItemButton>
            )}
          </List>

          {addingColor || editingColor ? (
            <Stack spacing={0.5}>
              <TextField
                autoFocus
                fullWidth
                required
                size="small"
                margin="dense"
                label="Design"
                value={colorName}
                onChange={(e) => {
                  setColorName(e.target.value);
                }}
              />
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="flex-end"
                spacing={1}
              >
                {editingColor ? (
                  <Box flex={1}>
                    {formik.values.presaleItems[index]?.colors.length ===
                    1 ? null : (
                      <Button
                        sx={{
                          color: "text.secondary",
                        }}
                        onClick={() => {
                          const updatedColors = [
                            ...formik.values.presaleItems[index]?.colors,
                          ];
                          updatedColors.splice(editingColorIndex, 1);
                          formik.setFieldValue(
                            `presaleItems[${index}].colors`,
                            updatedColors
                          );
                          setLocalColors(updatedColors);
                          dispatch(
                            updatePresaleItemAsync({
                              presaleId,
                              presaleItem: {
                                ...formik.values.presaleItems[index],
                                colors: updatedColors,
                              },
                            })
                          );
                          if (updatedColors.length > 0) {
                            setColorChecked(true);
                          } else {
                            setColorChecked(false);
                          }
                          setAddingColor(false);
                          setEditingColor(false);
                          setColorName("");
                        }}
                      >
                        Remove
                      </Button>
                    )}
                  </Box>
                ) : null}
                <ToggleButton
                  size="small"
                  value="check"
                  onClick={() => {
                    if (addingColor && presaleItem.colors.length === 0) {
                      setColorChecked(false);
                    }
                    setAddingColor(false);
                    setEditingColor(false);
                    setColorName("");
                  }}
                >
                  <CloseRoundedIcon />
                </ToggleButton>
                <ToggleButton
                  size="small"
                  value="check"
                  type="submit"
                  onClick={() => {
                    const updatedColors = addingColor
                      ? [
                          ...formik.values.presaleItems[index]?.colors,
                          colorName,
                        ]
                      : formik.values.presaleItems[index]?.colors.map(
                          (color, i) =>
                            i === editingColorIndex ? colorName : color
                        );
                    setAddingColor(false);
                    setEditingColor(false);
                    formik.setFieldValue(
                      `presaleItems[${index}].colors`,
                      updatedColors
                    );
                    setLocalColors(updatedColors);
                    dispatch(
                      updatePresaleItemAsync({
                        presaleId,
                        presaleItem: {
                          ...formik.values.presaleItems[index],
                          colors: updatedColors,
                        },
                      })
                    );
                    if (updatedColors.length > 0) {
                      setColorChecked(true);
                    } else {
                      setColorChecked(false);
                    }
                    setColorName("");
                  }}
                >
                  <CheckRoundedIcon />
                </ToggleButton>
              </Stack>
            </Stack>
          ) : null}
        </InfoBox>
      ) : null}
    </Stack>
  );
};
