import _ from "lodash";
import * as yup from "yup";

export const presaleItemValidationSchema = yup.object({
  title: yup.string().required("This field is required"),
  colors: yup.array().test({
    name: "colors",
    message: "At least two designs are required.",
    test: function (value) {
      if ((value ?? []).length != 0 && (value ?? []).length < 2) {
        return false;
      }
      return true;
    },
  }),
  flavors: yup.array().test({
    name: "flavors",
    message: "At least two flavors are required.",
    test: function (value) {
      if ((value ?? []).length != 0 && (value ?? []).length < 2) {
        return false;
      }
      return true;
    },
  }),
  packagings: yup.array().test({
    name: "packagings",
    message: "At least two packagings are required.",
    test: function (value) {
      if ((value ?? []).length != 0 && (value ?? []).length < 2) {
        return false;
      }
      return true;
    },
  }),
});

export const defaultPresaleItem: PresaleItem = {
  id: "",
  img: "",
  title: "",
  description: "",
  price: 0,
  personalizable: false,
  personalizationType: "c",
  personalizationOptions: [],
  soldOut: false,
  limitQty: false,
  remainingQty: 0,
  selected: false,
  flavors: [],
  colors: [],
  packagings: [],
};

export const defaultFlavor: Flavor = { flavorName: "", flavorPrice: 0 };
export interface Flavor {
  flavorPrice: number;
  flavorName: string;
}

export interface PresaleItem {
  id: string;
  img: string;
  title: string;
  description: string;
  price: number;
  personalizable: boolean;
  personalizationType: string;
  personalizationOptions: string[];
  soldOut: boolean;
  limitQty: boolean;
  remainingQty: number;
  selected: boolean;
  flavors: Flavor[];
  colors: string[];
  packagings: string[];
}
