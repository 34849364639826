export enum AvailabilityType {
  acceptNow,
  acceptAfter,
  acceptUntil,
  acceptBetween,
  doNotAcceptOrder,
}

export const defaultAvailability: Availability = {
  type: AvailabilityType.acceptNow,
  start: new Date().toISOString(),
  end: new Date().toISOString(),
  lead: 1,
  dateBlocked: [],
};

export interface Availability {
  type: AvailabilityType;
  start: string;
  end: string;
  lead: number;
  dateBlocked: string[];
}
