import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ORDERS_COLLECTION_NAME, ORDERS_TABLE_NAME } from "Constants";
import {
  query,
  collection,
  orderBy,
  onSnapshot,
  Timestamp,
} from "firebase/firestore";
import { OrderInfo } from "Model/OrderInfo";
import { db, deleteOrder, fetchSubscribersList } from "Util/firebaseHelper";

const initialState: string[] = [];

export const subscribersSlice = createSlice({
  name: "subscribers",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    updateSubscribers: (state, action: PayloadAction<string[]>) => {
      return action.payload;
    },
    deleteSubscriber: (state, action: PayloadAction<string>) => {
      return state.filter((subscriber) => subscriber !== action.payload);
    },
  },
});

export const fetchSubscribersAsync = createAsyncThunk(
  "orders/fetchSubscribersAsync",
  async (userId: string, thunkAPI) => {
    const subscribers = await fetchSubscribersList(userId);
    thunkAPI.dispatch(subscribersSlice.actions.updateSubscribers(subscribers));
  }
);
