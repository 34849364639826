import { FormikProps } from "formik";
import { BusinessInfo } from "Model/BusinessInfo";

// ui related
import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  Stack,
  Typography,
  Checkbox,
} from "@mui/material";
import { InfoBox } from "Components/Containers/InfoBox";
import { FormSection } from "Components/BusinessView/FormSection";
import { CustomerRequestInfo } from "Model/CustomerRequestInfo";

export const CustomerJoinEmail = ({
  businessInfo,
  customerFormik,
}: {
  businessInfo: BusinessInfo;
  customerFormik: FormikProps<CustomerRequestInfo>;
}) => {
  return (
    <FormSection id="joinEmail" sectionName="Join email list">
      <FormControl fullWidth>
        <FormControlLabel
          control={<Checkbox defaultChecked />}
          name="joinEmailList"
          label="I would like to receive emails about future presales and events."
          onChange={customerFormik.handleChange}
        />
      </FormControl>
    </FormSection>
  );
};
