import { Stack, Typography } from "@mui/material";

type Props = {
  shippingAddress1: string;
  shippingAddress2?: string;
  shippingCity: string;
  shippingState: string;
  shippingZip: string;
};

export const ShipTo = ({
  shippingAddress1,
  shippingAddress2,
  shippingCity,
  shippingState,
  shippingZip,
}: Props) => {
  return (
    <div>
      <Typography variant="h6" color="text.primary" sx={{ mb: 0.5 }}>
        Ship to
      </Typography>
      <Stack>
        <Typography variant="body1">{shippingAddress1}</Typography>
        {shippingAddress2 ? (
          <Typography variant="body1">{shippingAddress2}</Typography>
        ) : (
          ""
        )}
        <Typography variant="body1">
          {shippingCity + ", " + shippingState + " " + shippingZip}
        </Typography>
      </Stack>
    </div>
  );
};
