import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSession } from "Context/userAuthContext";
import {
  PRESALE_ANNOUCEMENT_NONE,
  PRESALE_ANNOUCEMENT_SENT,
  PRESALE_ANNOUCEMENT_SKIPPED,
  PRESALE_STATUS_ENDED,
  PRESALE_STATUS_PUBLISHED,
} from "Constants";
import {
  customersAndSubscribersSelector,
  selectedPresaleSelector,
  subscribersSelector,
} from "Redux/selectors";
import { useAppDispatch, useAppSelector } from "Redux/hooks";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

import {
  Box,
  Stack,
  Typography,
  Button,
  Popover,
  Link,
  Alert,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  ImageList,
  ImageListItem,
} from "@mui/material";
import { Header } from "Components/Header";
import { CardStyle, InfoBox } from "Components/AllComponents";

import OpenInNewRoundedIcon from "@mui/icons-material/OpenInNewRounded";
import { FormikProps } from "formik";
import { PresaleInfo, getPresaleName } from "Model/PresaleInfo";
import {
  updatePresaleAsync,
  updatePresaleStatusAsync,
} from "Redux/Reducers/presales";
import { getNewDateMMDDYYYY } from "Util/dateUtil";
import CampaignRoundedIcon from "@mui/icons-material/CampaignRounded";
import { toast } from "react-toastify";
import {
  sendEmailToSubscribers,
  sendNotificationEmail,
  sendTestEmailToSubscribers,
} from "Util/firebaseHelper";
import { render } from "@react-email/render";
import { PresaleAnnouncement } from "EmailTemplates/Presale/PresaleAnnouncement";

type Props = {
  editing: boolean;
  formik: FormikProps<PresaleInfo>;
};

export const PresaleHeader = ({ editing, formik }: Props) => {
  const { user, sellerProfile } = useSession();
  const selectedPresale = useAppSelector(selectedPresaleSelector);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const subscribers = useAppSelector(subscribersSelector);

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const handleClickPopover = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClosePopover = () => {
    setAnchorEl(null);
  };
  const openStartPresale = Boolean(anchorEl);
  const openEndPresale = Boolean(anchorEl);

  const convertErrorToTab = () => {
    const errorKeys = Object.keys(formik.errors);
    console.log("errrr", formik.errors);
    const formattedErrorKeys = errorKeys.map((key) => {
      if (key === "presaleName") {
        return "Settings";
      } else if (key === "presaleItems") {
        return "Items";
      } else if (key === "paymentIds") {
        return "Settings";
      } else if (key === "fulfillments") {
        return "Settings";
      }
    });

    const deduplicatedKeys = Array.from(new Set(formattedErrorKeys));
    return deduplicatedKeys.join(", ");
  };

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [openAnnouncementDraft, setOpenAnnouncementDraft] = useState(false);
  const [sendingEmail, setSendingEmail] = useState(false);
  const [emailSubject, setEmailSubject] = useState("");
  const [emailHeading, setEmailHeading] = useState("");
  const [emailBody, setEmailBody] = useState("");

  const handleOpenAnnouncementDraft = () => {
    setOpenAnnouncementDraft(true);
    setEmailSubject(getPresaleName(formik.values.presaleName) + " Is Here");
    setEmailHeading(
      getPresaleName(formik.values.presaleName) + " has just started"
    );
    setEmailBody(
      "Get ready to be among the first to taste these newest mouthwatering creations!"
    );
  };
  const handleCloseAnnouncementDraft = () => {
    setOpenAnnouncementDraft(false);
  };
  const [countdown, setCountdown] = useState(0);

  return editing ? (
    <CardStyle sx={{ mb: 1 }}>
      <Stack direction="row" alignItems="center">
        <Typography variant="h5" flex={1}>
          Editing presale
        </Typography>
        <Stack direction="row" spacing={1}>
          <Button variant="text" sx={{ height: "fit-content" }}>
            Cancel
          </Button>
          <Button variant="contained" sx={{ height: "fit-content" }}>
            Save
          </Button>
        </Stack>
      </Stack>
    </CardStyle>
  ) : (
    <CardStyle sx={{ mb: 1 }}>
      <Header
        heading={getPresaleName(formik.values.presaleName)}
        meta={
          formik.values.status === PRESALE_STATUS_PUBLISHED
            ? `${formik.values.startTime} - present`
            : formik.values.status === PRESALE_STATUS_ENDED
            ? `${formik.values.startTime} - ${formik.values.endTime}`
            : "Not started"
        }
      >
        {formik.values.status === PRESALE_STATUS_PUBLISHED ? (
          <Stack direction="row" spacing={1}>
            <Button
              variant="text"
              startIcon={<OpenInNewRoundedIcon />}
              onClick={() =>
                window.open(
                  `/${sellerProfile.username}/presale/${selectedPresale.presaleId}`,
                  "_blank"
                )
              }
              sx={{ height: "fit-content" }}
            >
              View form
            </Button>
            <Button
              variant="outlined"
              color="secondary"
              sx={{ height: "fit-content" }}
              onClick={handleClickPopover}
            >
              End presale
            </Button>
            <Popover
              id="endPresale"
              open={openEndPresale}
              anchorEl={anchorEl}
              onClose={handleClosePopover}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
            >
              <Box sx={{ p: 2 }}>
                <Typography sx={{ mb: 1, maxWidth: "400px" }}>
                  Once the presale ends, it cannot be restarted.
                </Typography>
                <Stack
                  direction="row"
                  spacing={1}
                  justifyContent="flex-end"
                  alignItems="center"
                >
                  <Button onClick={handleClosePopover}>Later</Button>
                  <Button
                    variant="contained"
                    onClick={() => {
                      handleClosePopover();
                      if (user) {
                        dispatch(
                          updatePresaleStatusAsync({
                            presaleInfo: {
                              ...formik.values,
                              status: PRESALE_STATUS_ENDED,
                              endTime: getNewDateMMDDYYYY(),
                            },
                            uid: user.uid,
                          })
                        );
                      }
                    }}
                  >
                    End now
                  </Button>
                </Stack>
              </Box>
            </Popover>
          </Stack>
        ) : formik.values.status === PRESALE_STATUS_ENDED ? null : (
          <Stack direction="row" spacing={1}>
            <Button
              variant="text"
              sx={{ height: "fit-content" }}
              onClick={() => {
                navigate(`/admin/presalepreview/${selectedPresale.presaleId}`);
              }}
            >
              Preview
            </Button>
            <Button
              variant="contained"
              disabled={selectedPresale.presaleItems.length === 0}
              sx={{ height: "fit-content" }}
              onClick={handleClickPopover}
            >
              Start presale
            </Button>
            <Popover
              id="startPresale"
              open={openStartPresale}
              anchorEl={anchorEl}
              onClose={handleClosePopover}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
            >
              <Box sx={{ p: 2 }}>
                <Typography sx={{ mb: 1, maxWidth: "400px" }}>
                  Your presale will be accessible from your Flowlylink home
                  page. You can make changes anytime before the presale ends.
                </Typography>
                <Stack
                  direction="row"
                  spacing={2}
                  justifyContent="flex-end"
                  alignItems="center"
                >
                  <Button onClick={handleClosePopover}>Later</Button>
                  <Button
                    variant="contained"
                    onClick={() => {
                      handleClosePopover();
                      if (user) {
                        formik.validateForm().then((errors) => {
                          if (Object.keys(errors).length === 0) {
                            dispatch(
                              updatePresaleStatusAsync({
                                presaleInfo: {
                                  ...formik.values,
                                  status: PRESALE_STATUS_PUBLISHED,
                                  startTime: getNewDateMMDDYYYY(),
                                },
                                uid: user.uid,
                              })
                            );
                          } else {
                            formik.setErrors(errors);
                          }
                        });
                      }
                    }}
                  >
                    Start now
                  </Button>
                </Stack>
              </Box>
            </Popover>
          </Stack>
        )}
      </Header>

      {Object.keys(formik.errors).length != 0 ? (
        <Alert severity="error" sx={{ mt: 1 }}>
          Please fill in all required fields in {convertErrorToTab()}.
        </Alert>
      ) : null}

      {selectedPresale.status === PRESALE_STATUS_PUBLISHED &&
      selectedPresale.presaleAnnouncement !== PRESALE_ANNOUCEMENT_SKIPPED &&
      selectedPresale.presaleAnnouncement !== PRESALE_ANNOUCEMENT_SENT &&
      subscribers.length > 0 ? (
        <InfoBox sx={{ mt: 1 }}>
          <Stack flexDirection="row" alignItems="center" columnGap={1}>
            <CampaignRoundedIcon />
            <Typography variant="subtitle2" flex={1}>
              Announce your presale
            </Typography>
          </Stack>
          <Typography>
            Announcement this presale to your{" "}
            <Link
              onClick={() => {
                navigate("/admin/customers");
              }}
            >
              email subscribers
            </Link>
            .
          </Typography>
          <Stack
            flexDirection="row"
            alignItems="center"
            justifyContent="flex-end"
            columnGap={1}
            sx={{ mt: 2 }}
          >
            <Box>
              <Button
                size="small"
                onClick={() => {
                  dispatch(
                    updatePresaleAsync({
                      ...selectedPresale,
                      presaleAnnouncement: PRESALE_ANNOUCEMENT_SKIPPED,
                    })
                  );
                }}
              >
                Skip
              </Button>
            </Box>
            <Button
              variant="contained"
              size="small"
              onClick={() => {
                handleOpenAnnouncementDraft();
              }}
            >
              View draft
            </Button>
          </Stack>
        </InfoBox>
      ) : null}

      <Dialog
        open={openAnnouncementDraft}
        onClose={(event, reason) => {
          handleCloseAnnouncementDraft();
        }}
        fullWidth
        fullScreen={fullScreen}
      >
        <DialogTitle>Announce presale</DialogTitle>
        <DialogContent dividers>
          <Typography mt={2} color="text.secondary" flex={1}>
            Edit content
          </Typography>
          <TextField
            variant="filled"
            label="Email subject"
            id="emailSubject"
            margin="dense"
            fullWidth
            sx={{
              whiteSpace: "pre-wrap",
              "& .MuiInputBase-input": {
                fontWeight: 500,
              },
            }}
            value={emailSubject}
            onChange={(e) => {
              setEmailSubject(e.target.value);
            }}
          />
          <TextField
            variant="filled"
            label="Heading"
            id="emailHeading"
            margin="dense"
            fullWidth
            multiline
            sx={{
              "& .MuiInputBase-input": {
                fontWeight: 600,
                fontSize: "20px",
              },
            }}
            value={emailHeading}
            onChange={(e) => {
              setEmailHeading(e.target.value);
            }}
          />
          <TextField
            variant="filled"
            label="Body"
            margin="dense"
            fullWidth
            multiline
            required
            id="emailBody"
            // error={!emailBody.trim()}
            // helperText={
            //   !emailBody.trim()
            //     ? "Email body cannot be empty"
            //     : emailTopic === "paymentReminder"
            //     ? "The email will contain remaining balance and instructions for the selected payment method."
            //     : ""
            // }
            sx={{ whiteSpace: "pre-wrap" }}
            value={emailBody}
            onChange={(e) => {
              setEmailBody(e.target.value);
            }}
          />
          <Stack
            flexDirection="row"
            alignItems="center"
            columnGap={1}
            flex={1}
            mt={2}
            mb={1}
          >
            <Typography color="text.secondary" flex={1}>
              Preview
            </Typography>
            <Button
              variant="outlined"
              size="small"
              disabled={countdown > 0} // Disable the button during the countdown
              onClick={async () => {
                setSendingEmail(true);
                const html = render(
                  <PresaleAnnouncement
                    sellerName={sellerProfile.username}
                    sellerPhoto={sellerProfile.sellerPhoto}
                    businessName={sellerProfile.businessName}
                    emailHeading={emailHeading}
                    emailBody={emailBody}
                    presale={selectedPresale}
                    displayMode={true}
                  />,
                  {
                    pretty: true,
                  }
                );

                await sendTestEmailToSubscribers(
                  sellerProfile.sellerEmail,
                  emailSubject,
                  html
                );
                setSendingEmail(false);
                toast("Email sent.", {
                  position: "top-center",
                  autoClose: 800,
                });
                // Start the countdown
                setCountdown(20);
                const countdownInterval = setInterval(() => {
                  setCountdown((prevCountdown) => prevCountdown - 1);
                }, 1000);

                // Stop the countdown after 20 seconds
                setTimeout(() => {
                  clearInterval(countdownInterval);
                  setCountdown(0);
                }, 20000);
              }}
            >
              {countdown > 0 ? `Resend (${countdown}s)` : "Send test email"}
            </Button>
          </Stack>
          <PresaleAnnouncement
            sellerName={sellerProfile.username}
            sellerPhoto={sellerProfile.sellerPhoto}
            businessName={sellerProfile.businessName}
            emailHeading={emailHeading}
            emailBody={emailBody}
            presale={selectedPresale}
            displayMode={true}
            smallScreen={fullScreen}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              handleCloseAnnouncementDraft();
            }}
          >
            Close
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              setSendingEmail(true);
              const html = render(
                <PresaleAnnouncement
                  sellerName={sellerProfile.username}
                  sellerPhoto={sellerProfile.sellerPhoto}
                  businessName={sellerProfile.businessName}
                  emailHeading={emailHeading}
                  emailBody={emailBody}
                  presale={selectedPresale}
                  displayMode={false}
                />,
                {
                  pretty: true,
                }
              );
              sendEmailToSubscribers(sellerProfile.uid, emailSubject, html);
              setSendingEmail(false);
              toast("Email sent.", {
                position: "top-center",
                autoClose: 800,
              });
              dispatch(
                updatePresaleAsync({
                  ...selectedPresale,
                  presaleAnnouncement: PRESALE_ANNOUCEMENT_SENT,
                })
              );
              // Start the countdown
              handleCloseAnnouncementDraft();
            }}
          >
            Send announcement
          </Button>
        </DialogActions>
      </Dialog>
    </CardStyle>
  );
};
