import { useState } from "react";
import { FormikErrors, FormikProps } from "formik";
import {
  Box,
  Button,
  FormControlLabel,
  FormHelperText,
  InputAdornment,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Stack,
  Switch,
  TextField,
  ToggleButton,
  Typography,
} from "@mui/material";
import { InfoBox } from "Components/AllComponents";
import { updatePresaleItemAsync } from "Redux/Reducers/presales";
import { PresaleInfo } from "Model/PresaleInfo";
import { useAppDispatch } from "Redux/hooks";
import { Flavor, PresaleItem } from "Model/PresaleItem";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";

type FormSectionProps = {
  index: number;
  presaleId: string;
  presaleItem: PresaleItem;
  formik: FormikProps<PresaleInfo>;
};

export const Flavors = ({
  index,
  presaleId,
  presaleItem,
  formik,
}: FormSectionProps) => {
  const dispatch = useAppDispatch();

  const [addingFlavor, setAddingFlavor] = useState(false);
  const [editingFlavor, setEditingFlavor] = useState(false);
  const [localFlavors, setLocalFlavor] = useState<Flavor[]>(
    presaleItem.flavors
  );
  const [editingFlavorIndex, setEditingFlavorIndex] = useState(0);
  const [flavorName, setFlavorName] = useState("");
  const [flavorPrice, setFlavorPrice] = useState(0);
  const [flavorChecked, setFlavorChecked] = useState(
    presaleItem.flavors?.length > 0
  );

  return (
    <Stack spacing={1}>
      <Stack
        flexDirection="row"
        sx={{ paddingLeft: 1.35, height: "27.91px" }}
        alignItems="center"
      >
        <FormControlLabel
          sx={{ flex: 1 }}
          control={
            <Switch
              size="small"
              checked={flavorChecked}
              onChange={async (event: React.ChangeEvent<HTMLInputElement>) => {
                setFlavorChecked(event.target.checked);
                if (event.target.checked) {
                  setFlavorName("");
                  setFlavorPrice(0);
                  if (localFlavors.length > 0) {
                    formik.setFieldValue(
                      `presaleItems[${index}].flavors`,
                      localFlavors
                    );
                    dispatch(
                      updatePresaleItemAsync({
                        presaleId,
                        presaleItem: {
                          ...formik.values.presaleItems[index],
                          flavors: localFlavors,
                        },
                      })
                    );
                  }
                  if (localFlavors.length === 0) {
                    setAddingFlavor(true);
                  }
                } else {
                  setAddingFlavor(false);
                  setEditingFlavor(false);
                  setFlavorName("");
                  setFlavorPrice(0);
                  formik.setFieldValue(`presaleItems[${index}].flavors`, []);
                  dispatch(
                    updatePresaleItemAsync({
                      presaleId,
                      presaleItem: {
                        ...formik.values.presaleItems[index],
                        flavors: [],
                      },
                    })
                  );
                }
              }}
            />
          }
          label="Flavors"
          labelPlacement="end"
        />
      </Stack>

      {formik.values.presaleItems[index]?.flavors.length > 0 &&
        Boolean(
          (formik.errors.presaleItems?.[index] as FormikErrors<PresaleItem>)
            ?.flavors
        ) && (
          <FormHelperText error>
            {formik.values.presaleItems[index].flavors.length > 0 &&
              (formik.errors.presaleItems?.[index] as FormikErrors<PresaleItem>)
                ?.flavors}
          </FormHelperText>
        )}

      {flavorChecked ? (
        <InfoBox sx={{ p: 1.5 }}>
          <Typography
            flex={1}
            variant="caption"
            color="text.secondary"
            sx={{ fontWeight: 500 }}
          >
            Options
          </Typography>
          <List
            dense
            disablePadding
            sx={{
              width: "100%",
            }}
            aria-label="options"
          >
            {formik.values.presaleItems[index]?.flavors.map(
              (flavor: Flavor, flavorIndex: number) => (
                <ListItem key={flavorIndex} disablePadding>
                  {
                    <ListItemButton
                      disableGutters
                      disabled={
                        addingFlavor ||
                        (editingFlavor && flavorIndex !== editingFlavorIndex)
                      }
                      onClick={() => {
                        setEditingFlavor(true);
                        setEditingFlavorIndex(flavorIndex);
                        setFlavorName(flavor.flavorName);
                        setFlavorPrice(flavor.flavorPrice);
                      }}
                    >
                      <ListItemText
                        primary={
                          flavor.flavorName === ""
                            ? "Missing value"
                            : flavor.flavorPrice !== 0
                            ? `${flavor.flavorName} (+$${flavor.flavorPrice})`
                            : flavor.flavorName
                        }
                        primaryTypographyProps={{
                          color:
                            flavor.flavorName === ""
                              ? "warning.main"
                              : "text.primary",
                        }}
                      />
                    </ListItemButton>
                  }
                </ListItem>
              )
            )}

            {addingFlavor || editingFlavor ? null : (
              <ListItemButton
                disableGutters
                disabled={editingFlavor}
                onClick={() => {
                  if (!addingFlavor) {
                    setAddingFlavor(true);
                  }
                }}
              >
                <ListItemText
                  primaryTypographyProps={{
                    fontSize: "0.85rem",
                    fontWeight: 600,
                    color: "primary.main",
                  }}
                >
                  Add option
                </ListItemText>
              </ListItemButton>
            )}
          </List>

          {addingFlavor || editingFlavor ? (
            <Stack spacing={1}>
              <TextField
                autoFocus
                fullWidth
                required
                size="small"
                margin="dense"
                label="Flavor"
                value={flavorName}
                onChange={(e) => {
                  setFlavorName(e.target.value);
                }}
              />
              <TextField
                fullWidth
                required
                size="small"
                margin="dense"
                type="number"
                label="Extra charge"
                onWheel={(e: any) => e.target.blur()}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                  inputProps: { min: 1 },
                }}
                value={flavorPrice}
                onChange={(e) => {
                  setFlavorPrice(parseFloat(e.target.value));
                }}
              />
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="flex-end"
                spacing={1}
              >
                {editingFlavor ? (
                  <Box flex={1}>
                    {formik.values.presaleItems[index]?.flavors.length ===
                    1 ? null : (
                      <Button
                        sx={{
                          color: "text.secondary",
                        }}
                        onClick={() => {
                          const updatedFlavors = [
                            ...formik.values.presaleItems[index]?.flavors,
                          ];
                          updatedFlavors.splice(editingFlavorIndex, 1);
                          formik.setFieldValue(
                            `presaleItems[${index}].flavors`,
                            updatedFlavors
                          );
                          setLocalFlavor(updatedFlavors);
                          dispatch(
                            updatePresaleItemAsync({
                              presaleId,
                              presaleItem: {
                                ...formik.values.presaleItems[index],
                                flavors: updatedFlavors,
                              },
                            })
                          );
                          if (updatedFlavors.length > 0) {
                            setFlavorChecked(true);
                          } else {
                            setFlavorChecked(false);
                          }
                          setAddingFlavor(false);
                          setEditingFlavor(false);
                          setFlavorName("");
                          setFlavorPrice(0);
                        }}
                      >
                        Remove
                      </Button>
                    )}
                  </Box>
                ) : null}
                <ToggleButton
                  size="small"
                  value="check"
                  onClick={() => {
                    if (addingFlavor && presaleItem.flavors.length === 0) {
                      setFlavorChecked(false);
                    }
                    setAddingFlavor(false);
                    setEditingFlavor(false);
                    setFlavorName("");
                    setFlavorPrice(0);
                  }}
                >
                  <CloseRoundedIcon />
                </ToggleButton>
                <ToggleButton
                  size="small"
                  value="check"
                  type="submit"
                  onClick={() => {
                    const updatedFlavors = addingFlavor
                      ? [
                          ...formik.values.presaleItems[index]?.flavors,
                          {
                            flavorName: flavorName,
                            flavorPrice: flavorPrice,
                          },
                        ]
                      : formik.values.presaleItems[index]?.flavors.map(
                          (flavor, i) =>
                            i === editingFlavorIndex
                              ? {
                                  flavorName: flavorName,
                                  flavorPrice: flavorPrice,
                                }
                              : flavor
                        );

                    setAddingFlavor(false);
                    setEditingFlavor(false);
                    formik.setFieldValue(
                      `presaleItems[${index}].flavors`,
                      updatedFlavors
                    );
                    setLocalFlavor(updatedFlavors);
                    dispatch(
                      updatePresaleItemAsync({
                        presaleId,
                        presaleItem: {
                          ...formik.values.presaleItems[index],
                          flavors: updatedFlavors,
                        },
                      })
                    );
                    if (updatedFlavors.length > 0) {
                      setFlavorChecked(true);
                    } else {
                      setFlavorChecked(false);
                    }
                    setFlavorName("");
                    setFlavorPrice(0);
                  }}
                >
                  <CheckRoundedIcon />
                </ToggleButton>
              </Stack>
            </Stack>
          ) : null}
        </InfoBox>
      ) : null}
    </Stack>
  );
};
