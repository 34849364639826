import * as yup from "yup";
import { Fulfillment, defaultFulfillment } from "./PresaleInfo";
import { Payment, defaultPayment } from "./Payment";
import { PresaleOrderItemCustomization } from "./PresaleOrder";

export interface CartItem {
  presaleItemId: string;
  presaleItemName: string;
  price: number;
  count: number;
  personalization: string[];
  itemTotalPrice: number;
  customization: PresaleOrderItemCustomization[];
  img: string;
}
export interface Cart {
  items: Record<string, CartItem>;
  totalPrice: number;
}

export interface CartForm {
  fulfillment: Fulfillment;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  questions: string;
  presaleId: string;
  submittedDate: string;
  payment: Payment;
  joinEmailList: boolean;
}

export const defaultCart: Cart = {
  items: {},
  totalPrice: 0,
};

export const defaultCartForm: CartForm = {
  fulfillment: defaultFulfillment,
  firstName: "", // set firstName as needed
  lastName: "", // set lastName as needed
  email: "", // set email as needed
  phoneNumber: "", // set phoneNumber as needed
  questions: "", // set questions as needed
  presaleId: "",
  submittedDate: new Date().toISOString(),
  payment: defaultPayment,
  joinEmailList: true,
};

const phoneRegExp =
  /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;

export const cartFormValidationSchema = () =>
  yup.object({
    firstName: yup.string().required("This field is required"),
    lastName: yup.string().required("This field is required"),
    email: yup.string().email().required("This field is required"),
    phoneNumber: yup
      .string()
      .matches(phoneRegExp, "Phone number is not valid")
      .required("This field is required"),
    fulfillments: yup
      .object()
      .shape({
        date: yup.string(),
        range: yup.string(),
        address: yup.string(),
      })
      .required("This field is required"),
  });
