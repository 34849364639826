import { useSession } from "Context/userAuthContext";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { PresaleInfo } from "Model/PresaleInfo";
import { selectedPresaleSelector } from "Redux/selectors";
import { useAppDispatch, useAppSelector } from "Redux/hooks";

import {
  Box,
  Button,
  Typography,
  Divider,
  Stack,
  Popover,
} from "@mui/material";
import { CardStyle } from "Components/AllComponents";
import { PRESALE_STATUS_PUBLISHED, PRESALE_STATUS_ENDED } from "Constants";
import { defaultPresaleItem } from "Model/PresaleItem";
import {
  addPresaleItemAsync,
  deletePresaleAsync,
} from "Redux/Reducers/presales";
import { nanoid } from "nanoid";
import { PresaleItemView } from "../Items/PresaleItemView";
import { PresaleSettings } from "./PresaleSettings";
import { FormikProps } from "formik";

import DeleteOutlineRoundedIcon from "@mui/icons-material/DeleteOutlineRounded";

export const EditPresale = ({
  formik,
}: {
  formik: FormikProps<PresaleInfo>;
}) => {
  const { user } = useSession();
  const navigate = useNavigate();
  const selectedPresale = useAppSelector(selectedPresaleSelector);
  const dispatch = useAppDispatch();

  const [addingNewPresaleItem, setAddingNewPresaleItem] = useState(false);

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const handleClickPopover = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClosePopover = () => {
    setAnchorEl(null);
  };
  const openDeletePresale = Boolean(anchorEl);

  return (
    <Stack spacing={2}>
      <PresaleSettings
        ended={formik.values.status === PRESALE_STATUS_ENDED}
        live={formik.values.status === PRESALE_STATUS_PUBLISHED}
        formik={formik}
      />

      <Box sx={{ pt: 1 }}>
        <Button
          variant="text"
          onClick={handleClickPopover}
          sx={{
            width: "fit-content",
            color: "text.secondary",
          }}
        >
          Delete presale
        </Button>
        <Popover
          id="deletePresale"
          open={openDeletePresale}
          anchorEl={anchorEl}
          onClose={handleClosePopover}
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
        >
          <Box sx={{ p: 2 }}>
            <Typography sx={{ mb: 1, maxWidth: "400px" }}>
              This action will permanently delete all the settings and order
              information associated with this presale.
            </Typography>
            <Stack
              direction="row"
              spacing={1}
              justifyContent="flex-end"
              alignItems="center"
            >
              <Button variant="outlined" onClick={handleClosePopover}>
                Close
              </Button>
              <Button
                variant="text"
                color="error"
                onClick={() => {
                  if (user) {
                    handleClosePopover();
                    dispatch(
                      deletePresaleAsync({
                        uid: user.uid,
                        presaleId: selectedPresale.presaleId,
                      })
                    );
                    navigate("/admin/presales");
                  }
                }}
              >
                Delete presale
              </Button>
            </Stack>
          </Box>
        </Popover>
      </Box>
    </Stack>
  );
};
