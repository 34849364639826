import { useSession } from "Context/userAuthContext";
import { useNavigate } from "react-router-dom";
import { FormikProps } from "formik";
import { PresaleInfo } from "Model/PresaleInfo";

import {
  Typography,
  Stack,
  TextField,
  FormHelperText,
  Link,
  Box,
  Button,
  FormControlLabel,
  Switch,
  Divider,
  Alert,
} from "@mui/material";
import { PickupDate } from "./PickupDate";
import { useAppDispatch, useAppSelector } from "Redux/hooks";
import { updatePresaleAsync } from "Redux/Reducers/presales";
import { selectedPresaleSelector } from "Redux/selectors";
import { CardStyle, InfoBox } from "Components/AllComponents";
import { getFullDefaultAddress } from "Model/Profile";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { InstructionSchedule } from "./InstructionSchedule";

type Props = {
  ended: boolean;
  live: boolean;
  formik: FormikProps<PresaleInfo>;
};

export const PresaleSettings = ({ formik, live, ended }: Props) => {
  const navigate = useNavigate();

  const { sellerProfile } = useSession();
  const selectedPresale = useAppSelector(selectedPresaleSelector);
  const dispatch = useAppDispatch();
  const updatePresale = () => {
    dispatch(updatePresaleAsync(formik.values));
  };

  if (ended) {
    return (
      <Stack spacing={2}>
        <CardStyle>
          <Typography variant="h6" sx={{ mb: 1 }}>
            About presale
          </Typography>
          <Typography variant="subtitle2">
            {selectedPresale.presaleName}
          </Typography>
          {selectedPresale.presaleDescription ? (
            <Typography
              variant="body2"
              color="text.secondary"
              sx={{ whiteSpace: "pre-line" }}
            >
              {selectedPresale.presaleDescription}
            </Typography>
          ) : null}
        </CardStyle>

        <CardStyle>
          <Stack spacing={1}>
            <Typography variant="h6">Pickup dates</Typography>
            {selectedPresale.fulfillments.map((d, index) => (
              <Stack key={d.date + d.range + index}>
                <Typography variant="subtitle2">
                  {d.date + " " + d.range}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {d.uDA ? getFullDefaultAddress(sellerProfile) : d.address}
                </Typography>
              </Stack>
            ))}
          </Stack>
          {/* <InstructionSchedule /> */}
        </CardStyle>
      </Stack>
    );
  } else {
    return (
      <Stack spacing={2}>
        <CardStyle>
          <Typography variant="h6">About presale</Typography>
          <TextField
            fullWidth
            multiline
            required
            margin="normal"
            id="presaleName"
            label="Presale name"
            value={formik.values.presaleName}
            onChange={formik.handleChange}
            onBlur={updatePresale}
            error={Boolean(formik.errors.presaleName)}
            helperText={formik.errors.presaleName}
          />
          <TextField
            fullWidth
            multiline
            margin="dense"
            id="presaleDescription"
            label="Description"
            value={formik.values.presaleDescription}
            onChange={formik.handleChange}
            onBlur={updatePresale}
          />
        </CardStyle>

        <PickupDate formik={formik} />

        <CardStyle>
          <Stack spacing={1}>
            <Typography variant="h6">Payment methods</Typography>
            <Typography>
              Manage payment methods on{" "}
              <Link
                onClick={() => {
                  navigate("/admin/home");
                }}
              >
                Home
              </Link>
              . At least one payment method is required.
            </Typography>
            <FormHelperText error>{formik.errors.paymentIds}</FormHelperText>
          </Stack>
        </CardStyle>
      </Stack>
    );
  }
};
