import React, { useEffect, useState } from "react";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useNavigate } from "react-router-dom";

import {
  Typography,
  Stack,
  Button,
  Box,
  Divider,
  Link,
  IconButton,
  ImageList,
  ImageListItem,
} from "@mui/material";
import {
  PresaleInfo,
  defaultPresaleInfo,
  getPresaleName,
  formatFulfillments,
} from "Model/PresaleInfo";
import { initializeCart } from "Redux/actionCreators";
import { useAppDispatch, useAppSelector } from "Redux/hooks";
import { getNewDateWithoutHours } from "Util/dateUtil";
import { readSellerInfo, fetchPresale } from "Util/firebaseHelper";
import { Timestamp } from "firebase/firestore";
import { selectPresaleById } from "Redux/selectors";

import EditRoundedIcon from "@mui/icons-material/EditRounded";
import { InfoBox } from "Components/AllComponents";

type Props = {
  editing: boolean;
  presaleId: string;
  username: string;
};

export const PresaleEntry = ({ editing, presaleId, username }: Props) => {
  const navigate = useNavigate();
  const presale =
    useAppSelector((state) => selectPresaleById(state, presaleId)) ||
    defaultPresaleInfo(false, []);
  const [presaleInfo, setPresaleInfo] = useState<PresaleInfo>(
    defaultPresaleInfo(false, [])
  );
  const [loading, setLoading] = useState(false);
  const dispatch = useAppDispatch();
  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      if (presaleId) {
        const presale = await fetchPresale(presaleId);
        if (presale === null) {
        } else {
          console.log(presale.data());
          let content = presale.data();
          if (content) {
            const presaleInfo = content as PresaleInfo;
            setPresaleInfo(presaleInfo);
            dispatch(initializeCart(presaleInfo.presaleItems));
          }
        }
      }
      setLoading(false);
    }
    if (!editing) {
      fetchData();
    }
  }, []);

  const theme = useTheme();
  const isMdScreen = useMediaQuery(theme.breakpoints.up("md"));
  const previewImgCol = isMdScreen ? 4 : 3;

  return (
    <Box
      onClick={() => {
        editing
          ? console.log("Editing mode is active.")
          : navigate(`/${username}/presale/${presaleId}`);
      }}
      sx={{
        boxShadow: "rgba(0, 0, 0, 0.04) 0px 4px 16px 0px",
        backgroundColor: "white",
        borderRadius: 4,
        p: { xs: 2, sm: 3 },
        "&:hover": {
          cursor: editing ? "default" : "pointer",
        },
      }}
    >
      {editing ? (
        <Stack>
          <Typography
            variant="h6"
            flex={1}
            sx={{
              mb: 0.5,
              fontFamily: "Frank Ruhl Libre",
              fontSize: "1.4rem",
            }}
          >
            {getPresaleName(presale.presaleName)}
          </Typography>
          <Typography variant="subtitle2">
            {formatFulfillments(presale.fulfillments)}
          </Typography>
          <Typography variant="body1" color="text.secondary">
            Limited time
          </Typography>
          {/* <ImageList
            variant="quilted"
            cols={Math.min(presale.presaleItems.length, previewImgCol)}
            gap={4}
            sx={{ overflow: "hidden", m: 0, mt: 1 }}
          >
            {presale.presaleItems.map((i, index) =>
              index < previewImgCol && i.img ? (
                <ImageListItem
                  key={i.id}
                  sx={{
                    maxHeight: presale.presaleItems.length < 4 ? 181 : 135,
                    overflow: "hidden",
                    borderRadius: 1,
                    position: "relative",
                  }}
                >
                  <img
                    src={i.img}
                    alt="item"
                    loading="lazy"
                    style={{
                      borderRadius: 4,
                      filter:
                        presale.presaleItems.length > previewImgCol &&
                        index === previewImgCol - 1
                          ? "brightness(70%)"
                          : "none",
                    }}
                  />
                  {presale.presaleItems.length > previewImgCol &&
                  index === previewImgCol - 1 ? (
                    <Typography
                      variant="h6"
                      sx={{
                        color: "white",
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                      }}
                    >
                      + {presale.presaleItems.length - previewImgCol}
                    </Typography>
                  ) : null}
                </ImageListItem>
              ) : null
            )}
          </ImageList> */}
          <InfoBox sx={{ mt: 2, p: 1 }}>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-evenly"
              spacing={1}
            >
              <Button
                variant="text"
                onClick={() => navigate(`/admin/presales/${presaleId}#orders`)}
                sx={{
                  width: "100%",
                  backgroundColor: "white",
                  "&:hover": { backgroundColor: "rgba(255,255,255,0.6)" },
                }}
              >
                View orders
              </Button>
              <Button
                variant="text"
                startIcon={<EditRoundedIcon />}
                onClick={() => navigate(`/admin/presales/${presaleId}#items`)}
                sx={{
                  width: "100%",
                  backgroundColor: "white",
                  "&:hover": { backgroundColor: "rgba(255,255,255,0.6)" },
                }}
              >
                Edit presale
              </Button>
            </Stack>
          </InfoBox>
        </Stack>
      ) : (
        <Stack>
          <Typography
            variant="h6"
            flex={1}
            sx={{
              mb: 0.5,
              fontFamily: "Frank Ruhl Libre",
              fontSize: "1.4rem",
            }}
          >
            {getPresaleName(presaleInfo.presaleName)}
          </Typography>
          <Stack spacing={{ sm: 1, xs: 2 }} direction="row" alignItems="center">
            <Stack flex={1}>
              <Typography variant="subtitle2">
                {formatFulfillments(presaleInfo.fulfillments)}
              </Typography>
              <Typography variant="body1" color="text.secondary">
                Limited time
              </Typography>
            </Stack>
          </Stack>
          <ImageList
            variant="quilted"
            cols={Math.min(presaleInfo.presaleItems.length, previewImgCol)}
            gap={4}
            sx={{ overflow: "hidden", m: 0, mt: 1 }}
          >
            {presaleInfo.presaleItems.map((i, index) =>
              index < previewImgCol && i.img ? (
                <ImageListItem
                  key={i.id}
                  sx={{
                    maxHeight: presaleInfo.presaleItems.length < 4 ? 181 : 135,
                    overflow: "hidden",
                    borderRadius: 1,
                    position: "relative",
                  }}
                >
                  <img
                    src={i.img}
                    alt="image"
                    loading="lazy"
                    style={{
                      borderRadius: 4,
                      filter:
                        presaleInfo.presaleItems.length > previewImgCol &&
                        index === previewImgCol - 1
                          ? "brightness(70%)"
                          : "none",
                    }}
                  />
                  {presaleInfo.presaleItems.length > previewImgCol &&
                  index === previewImgCol - 1 ? (
                    <Typography
                      variant="h6"
                      sx={{
                        color: "white",
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                      }}
                    >
                      + {presaleInfo.presaleItems.length - previewImgCol}
                    </Typography>
                  ) : null}
                </ImageListItem>
              ) : null
            )}
          </ImageList>
        </Stack>
      )}
    </Box>
  );
};
