import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "Redux/hooks";
import { businessInfoSelector } from "Redux/selectors";

// ui related
import {
  Box,
  Button,
  IconButton,
  Typography,
  Toolbar,
  AppBar,
  Menu,
  MenuItem,
  Tabs,
  Tab,
  Link,
  Chip,
} from "@mui/material";
import { HelpChip } from "Components/BusinessView/HelpChip";

// icons
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import AlignHorizontalRightRoundedIcon from "@mui/icons-material/AlignHorizontalRightRounded";

type Props = {
  drawerName?: string;
  returnTo: string;
  handleDrawerToggle: () => void;
  drawerState: boolean;
  backBtn: string;
  onConfirm?: () => void;
};

export const SubNav = ({
  drawerName,
  returnTo,
  handleDrawerToggle,
  drawerState,
  backBtn,
  onConfirm,
}: Props) => {
  const navigate = useNavigate();
  const myNavigate = () => {
    if (returnTo === "-1") {
      navigate(-1);
    } else {
      navigate(returnTo);
    }
  };
  const { dirty } = useAppSelector(businessInfoSelector);
  return (
    <AppBar
      position="fixed"
      color="inherit"
      sx={{
        zIndex: (theme) => theme.zIndex.drawer + 1,
      }}
    >
      <Toolbar variant="dense">
        {/* mobile */}
        <Box
          alignItems="center"
          sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}
        >
          <IconButton
            size="large"
            color="inherit"
            onClick={() => {
              if (dirty) {
                if (
                  window.confirm(
                    "Your changes will be discarded if you don't publish the form. Press OK if you want to leave WITHOUT saving the changes."
                  ) === true
                ) {
                  if (typeof onConfirm === "function") {
                    onConfirm();
                  }

                  myNavigate();
                }
              } else {
                myNavigate();
              }
            }}
          >
            <ArrowBackIosNewIcon />
          </IconButton>
          {!drawerName ? null : (
            <Box sx={{ alignItems: "center" }}>
              <Button
                // startIcon={<AlignHorizontalRightRoundedIcon />}
                color="secondary"
                variant={drawerState ? "contained" : "outlined"}
                onClick={handleDrawerToggle}
                sx={{
                  display: { xs: "flex", md: "none" },
                }}
              >
                {drawerName}
              </Button>
            </Box>
          )}
        </Box>

        {/* desktop */}
        <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
          <Button
            variant="text"
            color="secondary"
            startIcon={<ArrowBackIosNewIcon />}
            onClick={() => {
              if (dirty) {
                if (
                  window.confirm(
                    "Your changes will be discarded if you don't publish the form. Press OK if you want to leave WITHOUT saving the changes."
                  ) === true
                ) {
                  if (typeof onConfirm === "function") {
                    onConfirm();
                  }
                  myNavigate();
                }
              } else {
                myNavigate();
              }
            }}
          >
            {backBtn}
          </Button>
        </Box>
      </Toolbar>
    </AppBar>
  );
};
