import { useSession } from "Context/userAuthContext";
import { useState, useEffect, useCallback } from "react";
import _ from "lodash";
import { useAppDispatch, useAppSelector } from "Redux/hooks";
import { selectedOrderSelector } from "Redux/selectors";

// ui related
import {
  Box,
  Button,
  Typography,
  Stack,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Toolbar,
  TableSortLabel,
  Paper,
  Alert,
} from "@mui/material";
import { visuallyHidden } from "@mui/utils";
import { CardStyle, InfoBox } from "Components/AllComponents";
import { toast } from "react-toastify";
import { PresaleOrder } from "Model/PresaleOrder";
import { modifyPresaleOrder } from "Redux/Reducers/presaleOrders";
import AccessTimeRoundedIcon from "@mui/icons-material/AccessTimeRounded";

interface Data {
  orderId: string;
  customerName: string;
  orderTotal: number;
  payment: string;
}

function createData(
  orderId: string,
  customerName: string,
  orderTotal: number,
  payment: string
): Data {
  return {
    orderId,
    customerName,
    orderTotal,
    payment,
  };
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = "asc" | "desc";

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string }
) => number {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(
  array: readonly T[],
  comparator: (a: T, b: T) => number
) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

interface HeadCell {
  disablePadding: boolean;
  id: keyof Data;
  label: string;
  numeric: boolean;
}

const headCells: readonly HeadCell[] = [
  {
    id: "payment",
    numeric: false,
    disablePadding: true,
    label: "Method",
  },
  {
    id: "customerName",
    numeric: false,
    disablePadding: false,
    label: "Customer",
  },
  {
    id: "orderTotal",
    numeric: true,
    disablePadding: false,
    label: "Amount",
  },
];

const DEFAULT_ORDER = "asc";
const DEFAULT_ORDER_BY = "payment";
const DEFAULT_ROWS_PER_PAGE = 999;

interface EnhancedTableProps {
  numSelected: number;
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    newOrderBy: keyof Data
  ) => void;
  // onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const {
    // onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler =
    (newOrderBy: keyof Data) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, newOrderBy);
    };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="none">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            // onChange={onSelectAllClick}
            inputProps={{
              "aria-label": "select all",
            }}
            disabled
            sx={{ opacity: 0 }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding="none"
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

interface EnhancedTableToolbarProps {
  selected: PresaleOrder[];
  onMarkPaid: Function;
}

function EnhancedTableToolbar(props: EnhancedTableToolbarProps) {
  const { selected, onMarkPaid } = props;
  const dispatch = useAppDispatch();

  return (
    <Stack direction="row" alignItems="center" sx={{ height: "24px" }}>
      {selected.length > 0 ? (
        <Typography flex={1} color="inherit" variant="subtitle2">
          {selected.length} selected
        </Typography>
      ) : (
        <Stack flexDirection="row" alignItems="center" columnGap={1}>
          <AccessTimeRoundedIcon />
          <Typography flex={1} variant="subtitle2" id="tableTitle">
            Pending payments
          </Typography>
        </Stack>
      )}
      {selected.length > 0 ? (
        <Button
          variant="contained"
          size="small"
          onClick={() => {
            selected.forEach((order) => {
              dispatch(modifyPresaleOrder({ ...order, paid: true }));
            });
            if (onMarkPaid) {
              onMarkPaid();
            }
          }}
        >
          Mark as paid
        </Button>
      ) : null}
    </Stack>
  );
}

export const UnpaidOrders = ({
  unpaidOrders,
}: {
  unpaidOrders: PresaleOrder[];
}) => {
  const [order, setOrder] = useState<Order>(DEFAULT_ORDER);
  const [orderBy, setOrderBy] = useState<keyof Data>(DEFAULT_ORDER_BY);
  const [selected, setSelected] = useState<string[]>([]);
  const [page, setPage] = useState(0);
  const [dense, setDense] = useState(false);
  const [visibleRows, setVisibleRows] = useState<Data[] | null>(null);
  const [rowsPerPage, setRowsPerPage] = useState(DEFAULT_ROWS_PER_PAGE);
  const [paddingHeight, setPaddingHeight] = useState(0);
  const rows = unpaidOrders.map((order) =>
    createData(
      order.orderId,
      order.firstName + " " + order.lastName,
      order.totalPrice,
      order.payment.method
    )
  );
  useEffect(() => {
    let rowsOnMount = stableSort(
      rows,
      getComparator(DEFAULT_ORDER, DEFAULT_ORDER_BY)
    );
    rowsOnMount = rowsOnMount.slice(
      0 * DEFAULT_ROWS_PER_PAGE,
      0 * DEFAULT_ROWS_PER_PAGE + DEFAULT_ROWS_PER_PAGE
    );

    setVisibleRows(rowsOnMount);
  }, [unpaidOrders]);

  const handleRequestSort = useCallback(
    (event: React.MouseEvent<unknown>, newOrderBy: keyof Data) => {
      const isAsc = orderBy === newOrderBy && order === "asc";
      const toggledOrder = isAsc ? "desc" : "asc";
      setOrder(toggledOrder);
      setOrderBy(newOrderBy);

      const sortedRows = stableSort(
        rows,
        getComparator(toggledOrder, newOrderBy)
      );
      const updatedRows = sortedRows.slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      );
      setVisibleRows(updatedRows);
    },
    [order, orderBy, page, rowsPerPage]
  );

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.orderId);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event: React.MouseEvent<unknown>, name: string) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected: string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const isSelected = (name: string) => selected.indexOf(name) !== -1;
  const selectedOrders = unpaidOrders.filter((order) => {
    return selected.includes(order.orderId);
  });

  return (
    <Stack spacing={1}>
      <EnhancedTableToolbar
        selected={selectedOrders}
        onMarkPaid={() => {
          setSelected([]);
        }}
      />

      <TableContainer>
        <Table aria-labelledby="tableTitle" size="small">
          <EnhancedTableHead
            numSelected={selected.length}
            order={order}
            orderBy={orderBy}
            // onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            rowCount={rows.length}
          />
          <TableBody>
            {visibleRows
              ? visibleRows.map((row, index) => {
                  const isItemSelected = isSelected(row.orderId);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover={row.payment !== "Credit card"}
                      onClick={(event) => {
                        if (row.payment !== "Credit card") {
                          handleClick(event, row.orderId);
                        }
                      }}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.orderId}
                      selected={isItemSelected}
                      sx={{
                        cursor:
                          row.payment === "Credit card" ? "normal" : "pointer",
                        "&:last-child td, &:last-child th": {
                          border: 0,
                        },
                      }}
                    >
                      <TableCell padding="none">
                        <Checkbox
                          color="primary"
                          checked={isItemSelected}
                          inputProps={{
                            "aria-labelledby": labelId,
                          }}
                          disabled={row.payment === "Credit card"}
                          sx={{
                            opacity: row.payment === "Credit card" ? 0 : 1,
                          }}
                        />
                      </TableCell>
                      <TableCell
                        padding="none"
                        component="th"
                        id={labelId}
                        scope="row"
                      >
                        {row.payment}
                      </TableCell>
                      <TableCell padding="none" align="left">
                        {row.customerName}
                      </TableCell>
                      <TableCell padding="none" align="right">
                        ${row.orderTotal}
                      </TableCell>
                    </TableRow>
                  );
                })
              : null}
            {paddingHeight > 0 && (
              <TableRow
                style={{
                  height: paddingHeight,
                }}
              >
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Stack>
  );
};
