import { useNavigate } from "react-router-dom";

// ui related
import {
  Button,
  IconButton,
  Typography,
  Toolbar,
  AppBar,
  Stack,
} from "@mui/material";
import Avatar from "boring-avatars";
import HomeRoundedIcon from "@mui/icons-material/HomeRounded";

type Props = {
  sellerPhoto: string;
  sellerName: string;
  businessName: string;
  username: string;
};

export const SubAppBar = ({
  sellerPhoto,
  sellerName,
  businessName,
  username,
}: Props) => {
  const navigate = useNavigate();

  return (
    <AppBar>
      <Toolbar variant="dense">
        {sellerPhoto === "" ? (
          // <Avatar
          //   size={32}
          //   name={sellerName ? sellerName : ""}
          //   variant="beam"
          //   colors={["#F5673A", "#EA8676", "#FFB257", "#FFE2BF", "#FBED46"]}
          // />
          <svg
            viewBox="0 0 36 36"
            fill="none"
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            onClick={() => {
              navigate(`/${username}`);
            }}
          >
            <mask
              id=":r126:"
              maskUnits="userSpaceOnUse"
              x="0"
              y="0"
              width="36"
              height="36"
            >
              <rect width="36" height="36" rx="72" fill="#FFFFFF"></rect>
            </mask>
            <g mask="url(#:r126:)">
              <rect width="36" height="36" fill="#c8c8b9"></rect>
              <rect
                x="0"
                y="0"
                width="36"
                height="36"
                transform="translate(-2 6) rotate(306 18 18) scale(1)"
                fill="#eeda8d"
                rx="36"
              ></rect>
              <g transform="translate(-2 6) rotate(6 18 18)">
                <path d="M13,19 a1,0.75 0 0,0 10,0" fill="#000000"></path>
                <rect
                  x="13"
                  y="14"
                  width="1.5"
                  height="2"
                  rx="1"
                  stroke="none"
                  fill="#000000"
                ></rect>
                <rect
                  x="21"
                  y="14"
                  width="1.5"
                  height="2"
                  rx="1"
                  stroke="none"
                  fill="#000000"
                ></rect>
              </g>
            </g>
          </svg>
        ) : (
          <img
            src={sellerPhoto}
            width={32}
            height={32}
            style={{
              objectFit: "cover",
              borderRadius: "50%",
            }}
            onClick={() => {
              navigate(`/${username}`);
            }}
          />
        )}
        <Typography
          variant="subtitle2"
          color="text.primary"
          component="div"
          sx={{ ml: 1, "&:hover": { cursor: "pointer" } }}
          onClick={() => {
            navigate(`/${username}`);
          }}
        >
          {businessName}
        </Typography>
        {/* <IconButton
          color="primary"
          onClick={() => {
            navigate(`/${username}`);
          }}
        >
          <HomeRoundedIcon />
        </IconButton> */}
      </Toolbar>
    </AppBar>
  );
};
