import { useEffect, useState } from "react";
import { useSession } from "Context/userAuthContext";
import { useNavigate, useParams } from "react-router-dom";

// ui related
import {
  Button,
  Typography,
  List,
  ListItemText,
  ListItemButton,
  Stack,
  Divider,
} from "@mui/material";

//icons
import { useAppDispatch, useAppSelector } from "Redux/hooks";
import { createNewPresaleAsync } from "Redux/Reducers/presales";
import { getPresaleName, defaultPresaleInfo } from "Model/PresaleInfo";
import { selectPresale } from "Redux/actionCreators";
import { presalesSelector, selectedPresaleSelector } from "Redux/selectors";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import _ from "lodash";
import { PRESALE_STATUS_ENDED, PRESALE_STATUS_PUBLISHED } from "Constants";

export const PresalesDrawer = () => {
  const { user, sellerProfile } = useSession();
  let { presaleId } = useParams<"presaleId">();
  const dispatch = useAppDispatch();
  const allPresales = useAppSelector(presalesSelector);
  const selectedPresale = useAppSelector(selectedPresaleSelector);
  const getPresaleFromId = (id: string) => {
    return allPresales.find((i) => i?.presaleId === id);
  };
  const navigate = useNavigate();
  const [creatingNewPresale, setCreatingNewPresale] = useState(false);
  const allNotEndedPresales = allPresales?.filter(
    (p) => p?.status !== PRESALE_STATUS_ENDED
  );

  useEffect(() => {
    if (presaleId !== undefined) {
      const presale = getPresaleFromId(presaleId);
      if (presale) {
        dispatch(selectPresale(presale));
      }
    } else if (allNotEndedPresales?.length > 0 && allNotEndedPresales[0]) {
      dispatch(selectPresale(allNotEndedPresales[0]));
    } else if (allPresales?.length > 0 && allPresales[0]) {
      dispatch(selectPresale(allPresales[0]));
    }
  }, [presaleId, allPresales]);

  return (
    <List component="nav" sx={{ p: 0 }}>
      <Button
        variant="outlined"
        startIcon={<AutoAwesomeIcon />}
        sx={{ mb: 1, width: "100%" }}
        onClick={() => {
          navigate("/discover");
        }}
      >
        Halloween ideas
      </Button>
      <Button
        variant="outlined"
        sx={{ mb: 2, width: "100%" }}
        disabled={creatingNewPresale}
        onClick={async () => {
          if (user) {
            setCreatingNewPresale(true);
            await dispatch(
              createNewPresaleAsync({
                uid: user.uid,
                presaleInfo: {
                  ...defaultPresaleInfo(
                    sellerProfile.defaultPickupAddress ? true : false,
                    sellerProfile.payments.map((p) => p.method)
                  ),
                  uid: user.uid,
                },
              })
            );
            setCreatingNewPresale(false);
            navigate("/admin/presales");
          }
        }}
      >
        New presale
      </Button>

      {allPresales.map((presale, index) =>
        presale && presale.status !== PRESALE_STATUS_ENDED ? (
          <div key={index}>
            <ListItemButton
              selected={presale.presaleId === selectedPresale.presaleId}
              onClick={() => {
                dispatch(selectPresale(presale));
                navigate(`/admin/presales/${presale.presaleId}`);
              }}
            >
              <ListItemText
                primary={
                  <Stack direction="row" alignItems="center">
                    <Typography
                      flex={1}
                      variant="body2"
                      sx={{ fontWeight: 600 }}
                    >
                      {getPresaleName(presale.presaleName)}
                    </Typography>
                    {presale.status === PRESALE_STATUS_PUBLISHED ? (
                      <Typography
                        variant="caption"
                        color="primary.main"
                        sx={{ fontWeight: 700 }}
                      >
                        LIVE
                      </Typography>
                    ) : null}
                  </Stack>
                }
                secondary={
                  presale.status === PRESALE_STATUS_PUBLISHED
                    ? `${presale.startTime} - present`
                    : "Not started"
                }
              />
            </ListItemButton>
          </div>
        ) : null
      )}

      {allPresales.length > 0 ? (
        <Divider sx={{ mt: 1, mb: 1 }}>
          <Typography
            variant="caption"
            color="text.secondary"
            sx={{ fontWeight: 600 }}
          >
            ENDED
          </Typography>
        </Divider>
      ) : null}

      {allPresales.map((presale, index) =>
        presale && presale.status === PRESALE_STATUS_ENDED ? (
          <div key={index}>
            <ListItemButton
              selected={presale.presaleId === selectedPresale.presaleId}
              onClick={() => {
                dispatch(selectPresale(presale));
                navigate(`/admin/presales/${presale.presaleId}`);
              }}
            >
              <ListItemText
                primary={
                  <Stack direction="row" alignItems="center">
                    <Typography
                      flex={1}
                      variant="body2"
                      sx={{ fontWeight: 600 }}
                    >
                      {getPresaleName(presale.presaleName)}
                    </Typography>
                  </Stack>
                }
                secondary={`${presale.startTime} - ${presale.endTime}`}
              />
            </ListItemButton>
          </div>
        ) : null
      )}
    </List>
  );
};
