import { nanoid } from "nanoid";
import { Cart, CartForm, CartItem } from "./Cart";
import { Payment } from "./Payment";
import { Fulfillment } from "./PresaleInfo";

export interface PresaleOrderItemCustomization {
  price: number;
  flavor: string;
  color: string;
  personalization: string;
  count: number;
}

export interface AggregatedCustomization {
  price: number;
  flavor: string;
  colorMap: { [key: string]: string[] }; // key is color, value is an array of personalizations
  count: number;
}

export const defaultPresaleOrderItemCustomization: PresaleOrderItemCustomization =
  {
    price: 0,
    flavor: "",
    color: "",
    personalization: "",
    count: 0,
  };
export interface PresaleOrderItem {
  presaleItemId: string;
  presaleItemName: string;
  price: number;
  // personalization is deprecated, will be removed in the future, use customization instead
  count: number;
  personalization: string[];
  customization: PresaleOrderItemCustomization[];
  itemTotalPrice: number;
  img: string;
}

export interface PresaleOrder {
  presaleId: string;
  orderId: string;
  items: Record<string, PresaleOrderItem>;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  questions: string;
  totalPrice: number;
  paid: boolean;
  submittedDate: string;
  fulfillment: Fulfillment;
  payment: Payment;
  emailSent?: boolean;
  emailSentPayment?: boolean;
}

export function aggregateCustomizations(
  item: PresaleOrderItem
): AggregatedCustomization[] {
  if (item.customization.length === 0) {
    // If no customization, create a default one
    return [
      {
        price: item.price,
        flavor: "",
        colorMap: { "": item.personalization || [] },
        count: item.count,
      },
    ];
  }

  return item.customization.reduce((acc: AggregatedCustomization[], curr) => {
    const existingCustomization = acc.find(
      (customization) =>
        customization.price === curr.price &&
        customization.flavor === curr.flavor
    );

    if (existingCustomization) {
      if (existingCustomization.colorMap[curr.color]) {
        existingCustomization.colorMap[curr.color].push(curr.personalization);
      } else {
        existingCustomization.colorMap[curr.color] = [curr.personalization];
      }
      existingCustomization.count += curr.count;
    } else {
      acc.push({
        price: curr.price,
        flavor: curr.flavor,
        colorMap: { [curr.color]: [curr.personalization] },
        count: curr.count,
      });
    }

    return acc;
  }, []);
}

export const convertCartToPresaleOrder = (
  cart: Cart,
  cartForm: CartForm
): PresaleOrder => {
  const orderItems: Record<string, PresaleOrderItem> = {};

  // Loop through the cart items and convert them to PresaleOrderItem objects
  Object.keys(cart.items).forEach((key: string) => {
    const cartItem: CartItem = cart.items[key];
    if (cartItem.count > 0) {
      const orderItem: PresaleOrderItem = {
        presaleItemId: cartItem.presaleItemId,
        price: cartItem.price,
        count: cartItem.count,
        personalization: cartItem.personalization,
        itemTotalPrice: cartItem.itemTotalPrice,
        presaleItemName: cartItem.presaleItemName,
        customization: cartItem.customization,
        img: cartItem.img,
      };
      orderItems[key] = orderItem;
    }
  });

  // Create the PresaleOrder object
  const order: PresaleOrder = {
    presaleId: cartForm.presaleId,
    orderId: nanoid(), // set orderId as needed
    items: orderItems,
    firstName: cartForm.firstName,
    lastName: cartForm.lastName,
    email: cartForm.email,
    phoneNumber: cartForm.phoneNumber,
    questions: cartForm.questions,
    totalPrice: cart.totalPrice,
    paid: false,
    submittedDate: cartForm.submittedDate,
    fulfillment: cartForm.fulfillment,
    payment: cartForm.payment,
    emailSent: false,
    emailSentPayment: false,
  };

  return order;
};

export function calculateTotalItemsSold(orders: PresaleOrder[]): number {
  let totalItemsSold = 0;

  orders.forEach((order) => {
    Object.values(order.items).forEach((item) => {
      item.customization?.forEach((customization) => {
        totalItemsSold += customization.count;
      });
    });
  });

  return totalItemsSold;
}

export function calculateOrdersTotalPrice(orders: PresaleOrder[]): number {
  let total = 0;

  orders.forEach((order) => {
    Object.values(order.items).forEach((item) => {
      item.customization?.forEach((customization) => {
        total += customization.price * customization.count;
      });
    });
  });

  return total;
}

export function calculateTotalItemsSoldForPresale(
  orderGroups: Record<string, PresaleOrder[]>
): number {
  let totalItemsSold = 0;
  Object.values(orderGroups).forEach((orders) => {
    orders.forEach((order) => {
      Object.values(order.items).forEach((item) => {
        item.customization?.forEach((customization) => {
          totalItemsSold += customization.count;
        });
      });
    });
  });

  return totalItemsSold;
}

export function calculateOrdersTotalPriceForPresale(
  orderGroups: Record<string, PresaleOrder[]>
): number {
  let total = 0;

  Object.values(orderGroups).forEach((orders) => {
    orders.forEach((order) => {
      Object.values(order.items).forEach((item) => {
        item.customization?.forEach((customization) => {
          total += customization.price * customization.count;
        });
      });
    });
  });

  return total;
}

export function calculateTotalOrdersCountForPresale(
  orderGroups: Record<string, PresaleOrder[]>
): number {
  let totalCount = 0;

  Object.values(orderGroups).forEach((orders) => {
    totalCount += orders.length;
  });

  return totalCount;
}

export interface AggregatedOrderItem {
  presaleItemId: string;
  colorMap: { [key: string]: PresaleOrderItemCustomization[] };
  flavor: string;
  price: number;
  count: number;
  title: string;
  img: string;
  aggregatedOrderId: string;
}

export const aggregateOrders = (
  orders: PresaleOrder[],
  sortBy: string
): AggregatedOrderItem[] => {
  const itemMap: Record<string, AggregatedOrderItem> = {};

  orders.forEach((order) => {
    Object.values(order.items).forEach((orderItem: PresaleOrderItem) => {
      orderItem.customization.forEach((customization) => {
        const itemKey = `${orderItem.presaleItemId}:${orderItem.presaleItemName}:${orderItem.img}:${customization.flavor}:${customization.price}`;

        if (itemKey in itemMap) {
          itemMap[itemKey].count += customization.count;
          // Merge colorMap
          if (customization.color in itemMap[itemKey].colorMap) {
            itemMap[itemKey].colorMap[customization.color].push(customization);
          } else {
            itemMap[itemKey].colorMap[customization.color] = [customization];
          }
        } else {
          itemMap[itemKey] = {
            presaleItemId: orderItem.presaleItemId,
            count: customization.count,
            title: orderItem.presaleItemName,
            img: orderItem.img,
            aggregatedOrderId: itemKey,
            flavor: customization.flavor,
            price: customization.price,
            colorMap: {
              [customization.color]: [customization],
            },
          };
        }
      });
    });
  });

  return Object.values(itemMap).sort((a, b) => {
    if (sortBy === "flavor") {
      if (a.flavor && b.flavor) {
        const flavorComparison = a.flavor.localeCompare(b.flavor);
        if (flavorComparison !== 0) {
          return flavorComparison;
        }
      } else if (!a.flavor && b.flavor) {
        return 1;
      } else if (a.flavor && !b.flavor) {
        return -1;
      }
    } else if (sortBy === "title") {
      return a.title.localeCompare(b.title);
    } else if (sortBy === "sold") {
      return b.count - a.count;
    } else if (sortBy === "revenue") {
      return b.price * b.count - a.price * a.count;
    }
    return a.title.localeCompare(b.title);
  });
};
