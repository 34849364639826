import { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  AggregatedOrderItem,
  PresaleOrderItemCustomization,
} from "Model/PresaleOrder";
import {
  Box,
  Typography,
  Stack,
  Chip,
  Button,
  Link,
  Divider,
} from "@mui/material";
import { toast } from "react-toastify";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

type ItemProps = {
  itemInfo: AggregatedOrderItem;
};

export const PresaleOrderItems = ({ itemInfo }: ItemProps) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Stack direction="row" spacing={2} alignItems="flex-start">
      <Box
        sx={{
          width: "64px",
          height: "64px",
          borderRadius: 1,
          backgroundColor: "background.default",
        }}
      >
        {itemInfo.img ? (
          <img
            src={itemInfo.img}
            style={{
              width: "64px",
              height: "64px",
              display: "block",
              borderRadius: "4px",
              objectFit: "cover",
            }}
          />
        ) : null}
      </Box>
      <Stack
        spacing={1}
        flex={1}
        justifyContent="center"
        sx={{ minHeight: "64px" }}
      >
        <Stack direction="row" spacing={1} alignItems="flex-start">
          <Stack flex={1}>
            <Typography variant="subtitle2" flex={1}>
              {itemInfo.title}
            </Typography>
            {itemInfo.flavor ? (
              <Chip
                size="small"
                label={itemInfo.flavor}
                sx={{ width: "fit-content" }}
              />
            ) : null}
          </Stack>
          <Typography variant="body2" color="text.secondary">
            Qty: {itemInfo.count}
          </Typography>
        </Stack>
        <Stack spacing={1} divider={<Divider />}>
          {Object.entries(itemInfo.colorMap)
            .reduce((acc, [color, customizations]) => {
              return acc.concat(customizations);
            }, [] as PresaleOrderItemCustomization[])
            .map((c, index) =>
              c.personalization !== "" ? (
                <Stack direction="row" spacing={1} alignItems="flex-start">
                  <Typography variant="body2" sx={{ minWidth: "12px" }}>
                    {index + 1}.
                  </Typography>
                  <Stack>
                    {c.color ? (
                      <Typography variant="body2" color="text.secondary">
                        Design:{" "}
                        <Typography
                          component="span"
                          variant="body2"
                          color="text.primary"
                          sx={{ fontWeight: 500 }}
                        >
                          {c.color}
                        </Typography>
                      </Typography>
                    ) : (
                      ""
                    )}
                    <Typography variant="body2" color="text.secondary">
                      Personalization:{" "}
                      <Link
                        key={index}
                        variant="body2"
                        color="text.primary"
                        onClick={() => {
                          navigator.clipboard.writeText(c.personalization);
                          toast("Text copied.", {
                            position: "top-center",
                            autoClose: 800,
                          });
                        }}
                        sx={{ fontWeight: 500 }}
                      >
                        {c.personalization}
                      </Link>
                    </Typography>
                  </Stack>
                </Stack>
              ) : null
            )}
        </Stack>
      </Stack>
    </Stack>
  );
};
