import { useState } from "react";
import {
  Stack,
  Chip,
  Dialog,
  Button,
  DialogActions,
  DialogTitle,
  DialogContent,
  InputAdornment,
  Link,
  TextField,
  Typography,
  Popover,
  Box,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import LinkOffIcon from "@mui/icons-material/LinkOff";
import SquareIconSrc from "../Assets/square.svg";
import VenmoIconSrc from "../Assets/venmo.svg";
import PayPalIconSrc from "../Assets/paypal.svg";
import CashAppIconSrc from "../Assets/cashapp.svg";
import {
  PAYMENT_METHOD_CASH_APP,
  PAYMENT_METHOD_CREDIT_CARD,
  PAYMENT_METHOD_PAYPAL,
  PAYMENT_METHOD_VENMO,
  PAYMENT_TYPE_CASH_APP,
  PAYMENT_TYPE_CREDIT_CARD,
  PAYMENT_TYPE_PAYPAL,
  PAYMENT_TYPE_VENMO,
} from "Constants";
import { Payment } from "Model/Payment";
import { FormikProps, setIn } from "formik";
import { useSession } from "Context/userAuthContext";
import LoadingButton from "@mui/lab/LoadingButton";

const SquareIcon = () => (
  <img
    src={SquareIconSrc}
    alt="square"
    style={{ width: 17.5, height: 17.5, borderRadius: 4 }}
  />
);
const VenmoIcon = () => (
  <img
    src={VenmoIconSrc}
    alt="venmo"
    style={{ width: 17.5, height: 17.5, borderRadius: 4 }}
  />
);
const PayPalIcon = () => (
  <img
    src={PayPalIconSrc}
    alt="paypal"
    style={{ width: 17.5, height: 17.5, borderRadius: 4 }}
  />
);
const CashAppIcon = () => (
  <img
    src={CashAppIconSrc}
    alt="cashapp"
    style={{ width: 17.5, height: 17.5, borderRadius: 4 }}
  />
);

type Props = {
  editing?: boolean;
  payment: Payment;
  onSave: Function;
  onDelete: Function;
};

// only four types of payment methods are supported:
// credit card, venmo, paypal, and cash app
export const PaymentMethod = ({
  editing,
  payment,
  onSave,
  onDelete,
}: Props) => {
  const { sellerProfile } = useSession();
  const [openEditPayment, setOpenEditPayment] = useState(false);
  const handleOpenEditPayment = () => {
    setOpenEditPayment(true);
  };
  const handleCloseEditPayment = () => {
    setOpenEditPayment(false);
  };

  const [instruction, setInstruction] = useState(payment.instruction);
  const [error, setError] = useState("");

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const handleClickPopover = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClosePopover = () => {
    setAnchorEl(null);
  };
  const openDeleteRequest = Boolean(anchorEl);

  const [preDefinedPaymentUrl, setPreDefinitPaymentUrl] = useState(
    payment.type === PAYMENT_TYPE_VENMO
      ? "venmo.com/u/"
      : payment.type === PAYMENT_TYPE_PAYPAL
      ? "paypal.me/"
      : payment.type === PAYMENT_TYPE_CASH_APP
      ? "cash.app/$"
      : ""
  );

  const [unlinkingSquare, setUnlinkingSquare] = useState(false);

  return (
    <>
      {payment.type === PAYMENT_TYPE_CREDIT_CARD ? (
        <Chip
          label={payment.method}
          variant="outlined"
          icon={
            <Stack sx={{ pl: 0.5 }}>
              <SquareIcon />
            </Stack>
          }
          onClick={() => {
            if (editing) {
              handleOpenEditPayment();
            }
          }}
          sx={{ mr: 1, mb: 1, backgroundColor: "white", border: "none" }}
        />
      ) : (
        <Chip
          label={payment.method}
          variant="outlined"
          icon={
            <Stack sx={{ pl: 0.5 }}>
              {payment.type === PAYMENT_TYPE_VENMO ? (
                <VenmoIcon />
              ) : payment.type === PAYMENT_TYPE_PAYPAL ? (
                <PayPalIcon />
              ) : payment.type === PAYMENT_TYPE_CASH_APP ? (
                <CashAppIcon />
              ) : null}
            </Stack>
          }
          onClick={() => {
            if (editing) {
              handleOpenEditPayment();
            } else {
              window.open(
                "https://" + preDefinedPaymentUrl + payment.instruction,
                "_blank"
              );
            }
          }}
          sx={{ mr: 1, mb: 1, backgroundColor: "white", border: "none" }}
        />
      )}

      <Dialog
        open={openEditPayment}
        onClose={(event, reason) => {
          if (!unlinkingSquare) {
            handleCloseEditPayment();
          }
        }}
        fullWidth
      >
        <DialogTitle>{payment.method}</DialogTitle>
        <DialogContent>
          {payment.type !== PAYMENT_TYPE_CREDIT_CARD ? (
            <>
              <TextField
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Typography color="text.primary">
                        {preDefinedPaymentUrl}
                      </Typography>
                    </InputAdornment>
                  ),
                }}
                placeholder="username"
                required
                id="instruction"
                value={instruction}
                // value={props.values.username.toLowerCase()}
                onChange={(e) => {
                  setInstruction(e.target.value);
                }}
                onBlur={() => {
                  if (!instruction) {
                    setError("This field is required.");
                  } else {
                    setError("");
                  }
                }}
                error={error !== ""}
                helperText={error}
              />
              <Stack sx={{ mt: 2 }}>
                <Typography>Payment link:</Typography>
                <Link
                  onClick={() => {
                    window.open(
                      "https://" + preDefinedPaymentUrl + instruction,
                      "_blank"
                    );
                  }}
                >
                  {"https://" + preDefinedPaymentUrl + instruction}
                </Link>
              </Stack>
            </>
          ) : (
            <Typography>
              You're currently linked to{" "}
              {sellerProfile.squareState.merchantName}.
            </Typography>
          )}
          {payment.type === PAYMENT_TYPE_CREDIT_CARD ? (
            <LoadingButton
              startIcon={<LinkOffIcon />}
              variant="text"
              loading={unlinkingSquare}
              onClick={handleClickPopover}
              sx={{ width: "fit-content", color: "text.secondary", mt: 2 }}
            >
              Unlink Square account
            </LoadingButton>
          ) : (
            <Button
              startIcon={<DeleteRoundedIcon />}
              variant="text"
              onClick={handleClickPopover}
              sx={{ width: "fit-content", color: "text.secondary", mt: 2 }}
            >
              Delete payment method
            </Button>
          )}
        </DialogContent>
        {payment.type === PAYMENT_TYPE_CREDIT_CARD ? (
          <DialogActions>
            <Button
              onClick={() => {
                setError(""); // Clear the error message
                handleCloseEditPayment();
                setInstruction(payment.instruction);
              }}
              color="secondary"
              disabled={unlinkingSquare}
            >
              Close
            </Button>
          </DialogActions>
        ) : (
          <DialogActions>
            <Button
              onClick={() => {
                setError(""); // Clear the error message
                handleCloseEditPayment();
                setInstruction(payment.instruction);
              }}
              color="secondary"
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                if (payment.type === PAYMENT_TYPE_CREDIT_CARD) {
                  setError("");
                  handleCloseEditPayment();
                  return;
                }
                if (!instruction) {
                  // If the instruction field is empty, set the error message
                  setError("This field is required.");
                } else {
                  // If the instruction field is not empty, close the dialog and save the changes
                  setError(""); // Clear the error message
                  handleCloseEditPayment();
                  onSave({ ...payment, instruction });
                }
              }}
            >
              Save
            </Button>
          </DialogActions>
        )}
      </Dialog>

      <Popover
        id="deletePayment"
        open={openDeleteRequest}
        anchorEl={anchorEl}
        onClose={handleClosePopover}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <Box sx={{ p: 2 }}>
          <Typography sx={{ mb: 1, maxWidth: "400px" }}>
            This action will delete the payment method. Would you like to
            proceed?
          </Typography>
          <Stack
            direction="row"
            spacing={1}
            justifyContent="flex-end"
            alignItems="center"
          >
            <Button
              variant="text"
              color="secondary"
              onClick={handleClosePopover}
            >
              Cancel
            </Button>
            <Button
              variant="outlined"
              color="error"
              onClick={async () => {
                setUnlinkingSquare(true);
                handleClosePopover();
                if (payment) {
                  await onDelete(payment);
                }
                setUnlinkingSquare(false);
              }}
            >
              Yes, delete
            </Button>
          </Stack>
        </Box>
      </Popover>
    </>
  );
};
