import { useSession } from "Context/userAuthContext";
import {
  dueDateStringForm,
  getMinMaxDueDate,
  getNewDateWithoutHours,
} from "Util/dateUtil";

// ui related
import { Box, Stack, Button, Typography, TextField } from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { FormSection } from "Components/BusinessView/FormSection";
import { FormEditor } from "Components/BusinessView/FormEditor";
import { InfoBox } from "Components/Containers/InfoBox";
import { Availability } from "Components/BusinessView/Availability";
import WorkHistoryOutlinedIcon from "@mui/icons-material/WorkHistoryOutlined";

export const DateNeeded = () => {
  const { user, sellerProfile } = useSession();
  const minMaxDue = getMinMaxDueDate(sellerProfile.availability);

  return (
    <FormSection id="date" sectionName={"Date needed"}>
      <Stack spacing={2}>
        <FormEditor visible>
          <Availability>
            <Button variant="outlined" startIcon={<WorkHistoryOutlinedIcon />}>
              Update availability
            </Button>
          </Availability>
        </FormEditor>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            disabled
            format="MM/dd/yyyy"
            value={
              new Date(
                minMaxDue.start === undefined
                  ? getNewDateWithoutHours()
                  : minMaxDue.start
              )
            }
            onChange={(e) => {}}
            slotProps={{
              textField: {
                required: true,
                fullWidth: true,
                helperText: "Submitting this form doesn't guarantee a date.",
              },
            }}
          />
        </LocalizationProvider>
      </Stack>
    </FormSection>
  );
};
