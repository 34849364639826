export const firebaseProdConfig = {
  apiKey: "AIzaSyC1Y9eL9BGS8ImVC8SgjWVmlOObdvxHWa0",
  authDomain: "www.flowlylink.com",
  projectId: "flowlylink",
  storageBucket: "flowlylink.appspot.com",
  messagingSenderId: "741786747328",
  appId: "1:741786747328:web:12bd82fbeadb9fcd0a4825",
  measurementId: "G-1V0Z0WDSBJ",
};

export const firebaseDevConfig = {
  apiKey: "AIzaSyCCIzGuCXYIr1cTxBUrr4YDZCv4xzcrRFA",
  authDomain: "flowlylink-dev.firebaseapp.com",
  projectId: "flowlylink-dev",
  storageBucket: "flowlylink-dev.appspot.com",
  messagingSenderId: "43654240547",
  appId: "1:43654240547:web:67ce8c2ebdca7a0c3e1496",
  measurementId: "G-8NS1FJLCL8",
};
