import { FormikProps } from "formik";
import { BusinessInfo } from "Model/BusinessInfo";
import _ from "lodash";

// ui related
import {
  FormControl,
  InputAdornment,
  List,
  ListItem,
  Button,
  OutlinedInput,
  IconButton,
  FormHelperText,
  Stack,
  Typography,
} from "@mui/material";
import { FormSection } from "Components/BusinessView/FormSection";
import { FormEditor } from "Components/BusinessView/FormEditor";

// icons
import DeleteIcon from "@mui/icons-material/Delete";

export const BucketLists = ({
  formik,
}: {
  formik: FormikProps<BusinessInfo>;
}) => {
  const getErrors = (errors: any) => {
    return Array.isArray(errors)
      ? errors.filter(
          (email: string, i: number, arr: any) => arr.indexOf(email) === i
        )
      : errors;
  };

  if (formik.values.showBucketLists) {
    return (
      <FormSection id="bucket-list" sectionName="My bucket list">
        <Stack spacing={3}>
          <FormEditor visible>
            <Typography
              variant="body1"
              color="text.primary"
              sx={{ fontWeight: 500, mb: 0.5 }}
            >
              A list of themes/designs you would be thrilled to receive requests
              for:
            </Typography>
            <List disablePadding>
              {formik.values.bucketLists.map((bucketList, index) => (
                <ListItem key={index} disableGutters>
                  <FormControl variant="outlined" fullWidth>
                    <OutlinedInput
                      required
                      multiline
                      placeholder="Option"
                      size="small"
                      value={formik.values.bucketLists[index]}
                      onChange={(e) => {
                        let tempBucketLists = _.cloneDeep(
                          formik.values.bucketLists
                        );
                        tempBucketLists[index] = e.target.value;
                        formik.setFieldValue("bucketLists", tempBucketLists);
                      }}
                      error={
                        formik.values.bucketLists[index] === "" &&
                        formik.touched.bucketLists &&
                        Boolean(formik.errors.bucketLists)
                      }
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() => {
                              let tempBucketLists = _.cloneDeep(
                                formik.values.bucketLists
                              );
                              tempBucketLists.splice(index, 1);
                              formik.setFieldValue(
                                "bucketLists",
                                tempBucketLists
                              );
                            }}
                            disabled={formik.values.bucketLists.length === 1}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                    {formik.values.bucketLists[index] === "" &&
                      formik.touched.bucketLists &&
                      Boolean(formik.errors.bucketLists) && (
                        <FormHelperText error>
                          {formik.values.bucketLists[index] === "" &&
                            formik.touched.bucketLists &&
                            getErrors(formik.errors.bucketLists as any)}
                        </FormHelperText>
                      )}
                  </FormControl>
                </ListItem>
              ))}
            </List>
            <Button
              variant="text"
              onClick={() => {
                let tempBucketLists = formik.values.bucketLists;
                formik.setFieldValue(
                  "bucketLists",
                  tempBucketLists.concat([""])
                );
              }}
              sx={{ width: "fit-content" }}
            >
              Add option
            </Button>
          </FormEditor>
        </Stack>
      </FormSection>
    );
  } else {
    return null;
  }
};
