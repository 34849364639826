import { useDispatch, useSelector } from "react-redux";
import type { TypedUseSelectorHook } from "react-redux";
import type { RootState, AppDispatch } from "./store";
import { useMemo } from "react";
import {
  canceledOrdersSelector,
  completedOrdersSelector,
  ordersSelector,
} from "./selectors";
import { OrderInfo } from "Model/OrderInfo";

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export const useCompletedOrders = (): OrderInfo[] => {
  const completedOrdersId = useAppSelector(completedOrdersSelector);
  const allOrders = useAppSelector(ordersSelector);

  const completedOrders = useMemo(() => {
    if (!completedOrdersId || !allOrders) {
      return [] as OrderInfo[];
    }
    const filteredOrders = completedOrdersId
      .map((id) => allOrders.find((order) => order.orderId === id))
      .filter(Boolean) as OrderInfo[];

    return filteredOrders.sort((a, b) =>
      b.customerDate.localeCompare(a.customerDate)
    );
  }, [completedOrdersId, allOrders]);

  return completedOrders;
};

export const useCanceledOrders = (): OrderInfo[] => {
  const canceledOrdersId = useAppSelector(canceledOrdersSelector);
  const allOrders = useAppSelector(ordersSelector);

  const canceledOrders = useMemo(() => {
    if (!canceledOrdersId || !allOrders) {
      return [] as OrderInfo[];
    }
    const filteredOrders = canceledOrdersId
      .map((id) => allOrders.find((order) => order.orderId === id))
      .filter(Boolean) as OrderInfo[];

    return filteredOrders.sort((a, b) =>
      b.customerDate.localeCompare(a.customerDate)
    );
  }, [canceledOrdersId, allOrders]);

  return canceledOrders;
};
