// utils/encoding.ts
import CryptoJS from "crypto-js";
import { CustomerRequestInfo } from "Model/CustomerRequestInfo";
import { OrderInfo } from "Model/OrderInfo";

export const encodeRequestInfo = (
  formId: string,
  requestId: string,
  phoneNumber: string
): string => {
  const requestInfoString = JSON.stringify({ formId, requestId });
  const encrypted = CryptoJS.AES.encrypt(
    requestInfoString,
    phoneNumber
  ).toString();
  return encodeURIComponent(encrypted);
};

export const decodeRequestInfo = (
  encoded: string,
  phoneNumber: string
): { formId: string; requestId: string } => {
  try {
    const decrypted = CryptoJS.AES.decrypt(
      decodeURIComponent(encoded),
      phoneNumber
    );
    const requestInfoString = decrypted.toString(CryptoJS.enc.Utf8);
    return JSON.parse(requestInfoString) as {
      formId: string;
      requestId: string;
    };
  } catch (error) {
    return { formId: "", requestId: "" };
  }
};
