import { useState } from "react";
import {
  Box,
  Button,
  Chip,
  Divider,
  FormControlLabel,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  Switch,
  TextField,
  ToggleButton,
  Typography,
} from "@mui/material";
import { InfoBox } from "Components/AllComponents";
import {
  PERSONALIZATION_TYPE_CUSTOM_ONLY,
  PERSONALIZATION_TYPE_BOTH,
  PERSONALIZATION_TYPE_PRESET_ONLY,
} from "Constants";
import { updatePresaleItemAsync } from "Redux/Reducers/presales";
import { PersonalizationSettings } from "./PersonalizationSettings";
import { FormikProps } from "formik";
import { PresaleInfo } from "Model/PresaleInfo";
import { useAppDispatch } from "Redux/hooks";
import { PresaleItem } from "Model/PresaleItem";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";

type FormSectionProps = {
  index: number;
  presaleId: string;
  presaleItem: PresaleItem;
  formik: FormikProps<PresaleInfo>;
};

export const Personalized = ({
  index,
  presaleId,
  presaleItem,
  formik,
}: FormSectionProps) => {
  const dispatch = useAppDispatch();

  const [addingPersonalizationOptions, setAddingPersonalizationOptions] =
    useState(false);
  const [editingPersonalizationOptions, setEditingPersonalizationOptions] =
    useState(false);
  const [localPersonalizationOptions, setLocalPersonalizationOptions] =
    useState<string[]>(presaleItem.personalizationOptions);
  const [
    editingPersonalizationOptionsIndex,
    setEditingPersonalizationOptionsIndex,
  ] = useState(0);
  const [personalizationOption, setPersonalizationOption] = useState("");

  return (
    <Stack spacing={1}>
      <FormControlLabel
        sx={{ flex: 1, paddingLeft: 1.35 }}
        control={
          <Switch
            id={`presaleItems[${index}].personalizable`}
            name={`presaleItems[${index}].personalizable`}
            size="small"
            checked={formik.values.presaleItems[index]?.personalizable}
            onChange={async (event: React.ChangeEvent<HTMLInputElement>) => {
              formik.setFieldValue(
                `presaleItems[${index}].personalizable`,
                event.target.checked
              );
              if (!event.target.checked) {
                setAddingPersonalizationOptions(false);
                setEditingPersonalizationOptions(false);
              }
              dispatch(
                updatePresaleItemAsync({
                  presaleId,
                  presaleItem: {
                    ...formik.values.presaleItems[index],
                    personalizable: event.target.checked,
                  },
                })
              );
            }}
          />
        }
        label="Personalized"
        labelPlacement="end"
      />

      {formik.values.presaleItems[index]?.personalizable ? (
        <InfoBox sx={{ p: 1.5 }}>
          <Stack spacing={1}>
            <Stack flexDirection="row" alignItems="flex-end">
              <Stack flex={1}>
                <Typography
                  flex={1}
                  variant="caption"
                  color="text.secondary"
                  sx={{ fontWeight: 500 }}
                >
                  Type
                </Typography>
                <Typography variant="body2">
                  {formik.values.presaleItems[index]?.personalizationType ===
                  PERSONALIZATION_TYPE_CUSTOM_ONLY
                    ? "Free text"
                    : formik.values.presaleItems[index]?.personalizationType ===
                      PERSONALIZATION_TYPE_BOTH
                    ? "Single select or free text"
                    : "Single select"}
                </Typography>
              </Stack>
              <PersonalizationSettings
                initialValue={
                  formik.values.presaleItems[index]?.personalizationType
                }
                onChanged={(e: string) => {
                  formik.setFieldValue(
                    `presaleItems[${index}].personalizationType`,
                    e
                  );
                  dispatch(
                    updatePresaleItemAsync({
                      presaleId,
                      presaleItem: {
                        ...formik.values.presaleItems[index],
                        personalizationType: e,
                      },
                    })
                  );
                  if (e === PERSONALIZATION_TYPE_CUSTOM_ONLY) {
                    setAddingPersonalizationOptions(false);
                    setEditingPersonalizationOptions(false);
                    setPersonalizationOption("");
                  } else if (
                    e === PERSONALIZATION_TYPE_BOTH ||
                    e === PERSONALIZATION_TYPE_PRESET_ONLY
                  ) {
                    if (
                      formik.values.presaleItems[index]?.personalizationOptions
                        .length === 0
                    ) {
                      setAddingPersonalizationOptions(true);
                    }
                  }
                }}
              />
            </Stack>

            {formik.values.presaleItems[index]?.personalizationType !==
            PERSONALIZATION_TYPE_CUSTOM_ONLY ? (
              <Stack>
                <Typography
                  flex={1}
                  variant="caption"
                  color="text.secondary"
                  sx={{ fontWeight: 500 }}
                >
                  Options
                </Typography>
                <List
                  dense
                  disablePadding
                  sx={{
                    width: "100%",
                  }}
                  aria-label="options"
                >
                  {formik.values.presaleItems[
                    index
                  ]?.personalizationOptions.map((p: string, pIndex: number) => (
                    <ListItem key={pIndex} disablePadding>
                      {
                        <ListItemButton
                          disableGutters
                          disabled={
                            addingPersonalizationOptions ||
                            (editingPersonalizationOptions &&
                              pIndex !== editingPersonalizationOptionsIndex)
                          }
                          onClick={() => {
                            setEditingPersonalizationOptions(true);
                            setEditingPersonalizationOptionsIndex(pIndex);
                            setPersonalizationOption(p);
                          }}
                        >
                          <ListItemText
                            primary={p === "" ? "Missing value" : p}
                            primaryTypographyProps={{
                              color: p === "" ? "warning.main" : "text.primary",
                            }}
                          />
                        </ListItemButton>
                      }
                    </ListItem>
                  ))}
                  {addingPersonalizationOptions ||
                  editingPersonalizationOptions ? null : (
                    <ListItemButton
                      disableGutters
                      onClick={() => {
                        if (!addingPersonalizationOptions) {
                          setAddingPersonalizationOptions(true);
                        }
                      }}
                    >
                      <ListItemText
                        primaryTypographyProps={{
                          fontSize: "0.85rem",
                          fontWeight: 600,
                          color: "primary.main",
                        }}
                      >
                        Add option
                      </ListItemText>
                    </ListItemButton>
                  )}
                </List>

                {addingPersonalizationOptions ||
                editingPersonalizationOptions ? (
                  <Stack spacing={0.5}>
                    <TextField
                      autoFocus
                      fullWidth
                      required
                      size="small"
                      margin="dense"
                      label="Option"
                      value={personalizationOption}
                      onChange={(e) => {
                        setPersonalizationOption(e.target.value);
                      }}
                    />
                    <Stack
                      direction="row"
                      alignItems="center"
                      justifyContent="flex-end"
                      spacing={1}
                    >
                      {editingPersonalizationOptions ? (
                        <Box flex={1}>
                          {formik.values.presaleItems[index]
                            ?.personalizationOptions.length === 1 ? null : (
                            <Button
                              sx={{
                                color: "text.secondary",
                              }}
                              onClick={() => {
                                const updatedPersonalizationOptions = [
                                  ...formik.values.presaleItems[index]
                                    ?.personalizationOptions,
                                ];
                                updatedPersonalizationOptions.splice(
                                  editingPersonalizationOptionsIndex,
                                  1
                                );
                                formik.setFieldValue(
                                  `presaleItems[${index}].personalizationOptions`,
                                  updatedPersonalizationOptions
                                );
                                setLocalPersonalizationOptions(
                                  updatedPersonalizationOptions
                                );
                                dispatch(
                                  updatePresaleItemAsync({
                                    presaleId,
                                    presaleItem: {
                                      ...formik.values.presaleItems[index],
                                      personalizationOptions:
                                        updatedPersonalizationOptions,
                                    },
                                  })
                                );
                                setAddingPersonalizationOptions(false);
                                setEditingPersonalizationOptions(false);
                                setPersonalizationOption("");
                              }}
                            >
                              Remove
                            </Button>
                          )}
                        </Box>
                      ) : null}
                      <ToggleButton
                        size="small"
                        value="check"
                        onClick={() => {
                          setAddingPersonalizationOptions(false);
                          setEditingPersonalizationOptions(false);
                          setPersonalizationOption("");
                        }}
                      >
                        <CloseRoundedIcon />
                      </ToggleButton>
                      <ToggleButton
                        size="small"
                        value="check"
                        type="submit"
                        onClick={() => {
                          const updatedPersonalizationOptions =
                            addingPersonalizationOptions
                              ? [
                                  ...formik.values.presaleItems[index]
                                    ?.personalizationOptions,
                                  personalizationOption,
                                ]
                              : formik.values.presaleItems[
                                  index
                                ]?.personalizationOptions.map((p, i) =>
                                  i === editingPersonalizationOptionsIndex
                                    ? personalizationOption
                                    : p
                                );
                          setAddingPersonalizationOptions(false);
                          setEditingPersonalizationOptions(false);
                          formik.setFieldValue(
                            `presaleItems[${index}].personalizationOptions`,
                            updatedPersonalizationOptions
                          );
                          setLocalPersonalizationOptions(
                            updatedPersonalizationOptions
                          );
                          dispatch(
                            updatePresaleItemAsync({
                              presaleId,
                              presaleItem: {
                                ...formik.values.presaleItems[index],
                                personalizationOptions:
                                  updatedPersonalizationOptions,
                              },
                            })
                          );
                          setPersonalizationOption("");
                        }}
                      >
                        <CheckRoundedIcon />
                      </ToggleButton>
                    </Stack>
                  </Stack>
                ) : null}
              </Stack>
            ) : null}
          </Stack>
        </InfoBox>
      ) : null}
    </Stack>
  );
};
