import { BusinessInfo } from "Model/BusinessInfo";
import { PresaleOrder } from "Model/PresaleOrder";
import { PresaleInfo } from "Model/PresaleInfo";
import { Profile } from "Model/Profile";

export const SITE_URL = "flowlylink.com";

export const PRESALE_ORDER_SELLER_EMAIL_TEMPLATE = (
  order: PresaleOrder,
  sellerProfile: Profile,
  presale: PresaleInfo
) =>
  `<!doctype html>
  <html>
    <head>
      <meta name="viewport" content="width=device-width">
      <meta http-equiv="Content-Type" content="text/html; charset=UTF-8">
      <title>New presale order</title>
    <style>
  @media only screen and (max-width: 620px) {
    table[class=body] h1 {
      font-size: 28px !important;
      margin-bottom: 10px !important;
    }
  
    table[class=body] p,
  table[class=body] ul,
  table[class=body] ol,
  table[class=body] td,
  table[class=body] span,
  table[class=body] a {
      font-size: 16px !important;
    }
  
    table[class=body] .wrapper,
  table[class=body] .article {
      padding: 10px !important;
    }
  
    table[class=body] .content {
      padding: 0 !important;
    }
  
    table[class=body] .container {
      padding: 0 !important;
      width: 100% !important;
    }
  
    table[class=body] .main {
      border-left-width: 0 !important;
      border-radius: 0 !important;
      border-right-width: 0 !important;
    }
  
    table[class=body] .btn table {
      width: 100% !important;
    }
  
    table[class=body] .btn a {
      width: 100% !important;
    }
  
    table[class=body] .img-responsive {
      height: auto !important;
      max-width: 100% !important;
      width: auto !important;
    }
  }
  @media all {
    .ExternalClass {
      width: 100%;
    }
  
    .ExternalClass,
  .ExternalClass p,
  .ExternalClass span,
  .ExternalClass font,
  .ExternalClass td,
  .ExternalClass div {
      line-height: 100%;
    }
  
    .apple-link a {
      color: inherit !important;
      font-family: inherit !important;
      font-size: inherit !important;
      font-weight: inherit !important;
      line-height: inherit !important;
      text-decoration: none !important;
    }
  
    .btn-primary table td:hover {
      background-color: #000000 !important;
    }
  
    .btn-primary a:hover {
      background-color: #000000 !important;
      border-color: #000000 !important;
    }
  }
  </style></head>
    <body class style="background-color: #ffffff; font-family: sans-serif; -webkit-font-smoothing: antialiased; font-size: 15px; line-height: 1.4; margin: 0; padding: 0; -ms-text-size-adjust: 100%; -webkit-text-size-adjust: 100%;">
      <table role="presentation" border="0" cellpadding="0" cellspacing="0" class="body" style="border-collapse: separate; mso-table-lspace: 0pt; mso-table-rspace: 0pt; min-width: 100%; background-color: #ffffff; width: 100%;" width="100%" bgcolor="#ffffff">
        <tr>
          <td style="font-family: sans-serif; font-size: 15px; vertical-align: top;" valign="top">&nbsp;</td>
          <td class="container" style="font-family: sans-serif; font-size: 15px; vertical-align: top; display: block; max-width: 580px; padding: 10px; width: 580px; Margin: 0 auto;" width="580" valign="top">
            <div class="content" style="box-sizing: border-box; display: block; Margin: 0 auto; max-width: 580px;">
              <span class="preheader" style="color: transparent; display: none; height: 0; max-height: 0; max-width: 0; opacity: 0; overflow: hidden; mso-hide: all; visibility: hidden; width: 0;">New presale order</span>
              
              <table role="presentation" class="main" style="border-collapse: separate; mso-table-lspace: 0pt; mso-table-rspace: 0pt; min-width: 100%; width: 100%;" width="100%">
                <tr>
                  <td class="wrapper" style="font-family: sans-serif; font-size: 15px; vertical-align: top; box-sizing: border-box;" valign="top">
                    <a href="https://flowlylink.com/${sellerProfile.username}" target="_blank" style="color: #252525; text-decoration: none;">
                      <img class="profile-pic" src="${sellerProfile.sellerPhoto}" style="border: none; -ms-interpolation-mode: bicubic; max-width: 100%; background-color: #F8F8F7; width: 48px; height: 48px; border-radius: 50%; margin-bottom: 2px; margin-top: 24px;" width="56" height="56">
                      <h3 style="color: #252525; font-family: sans-serif; margin: 0; font-size: 15px; margin-bottom: 16px; font-weight: 600;">${sellerProfile.businessName}
                      </h3>
                    </a>
                  </td>
                </tr>
              </table>

              <table role="presentation" class="main" style="border-collapse: separate; mso-table-lspace: 0pt; mso-table-rspace: 0pt; min-width: 100%; width: 100%;" width="100%">
                <tr>
                  <td class="wrapper" style="font-family: sans-serif; font-size: 15px; vertical-align: top; box-sizing: border-box;" valign="top">
                    <h1 style="color: #252525; font-family: sans-serif; margin: 0; font-size: 20px; font-weight: 600;">You received a presale order.</h1>
                  </td>
                </tr>
              </table>
              
              <table role="presentation" class="main" style="border-collapse: separate; mso-table-lspace: 0pt; mso-table-rspace: 0pt; min-width: 100%; width: 100%;" width="100%">
                <tr>
                  <td class="wrapper" style="font-family: sans-serif; font-size: 15px; vertical-align: top; box-sizing: border-box;" valign="top">
                    
                  <hr style="border: 0; border-bottom: 1px solid #d1d1d1; Margin: 20px 0;">
  
                    <div class="info-with-label" style="margin-bottom: 8px;">
                      <p class="secondary-text" style="font-family: sans-serif; font-size: 15px; font-weight: normal; margin: 0; color: #494949;">Presale name</p>
                      <p style="font-family: sans-serif; font-size: 15px; font-weight: normal; margin: 0;"><b>${presale.presaleName}</b></p>
                    </div>
                    <div class="info-with-label" style="margin-bottom: 8px;">
                      <p class="secondary-text" style="font-family: sans-serif; font-size: 15px; font-weight: normal; margin: 0; color: #494949;">Customer</p>
                      <p style="font-family: sans-serif; font-size: 15px; font-weight: normal; margin: 0;"><b>${order.firstName} ${order.lastName}</b></p>
                    </div>
                    <div class="info-with-label" style="margin-bottom: 8px;">
                      <p class="secondary-text" style="font-family: sans-serif; font-size: 15px; font-weight: normal; margin: 0; color: #494949;">Order total</p>
                      <p style="font-family: sans-serif; font-size: 15px; font-weight: normal; margin: 0;"><b>$${order.totalPrice}</b></p>
                    </div>
                    <div class="info-with-label" style="margin-bottom: 8px;">
                      <p class="secondary-text" style="font-family: sans-serif; font-size: 15px; font-weight: normal; margin: 0; color: #494949;">Pickup date</p>
                      <p style="font-family: sans-serif; font-size: 15px; font-weight: normal; margin: 0;"><b>${order.fulfillment.date} ${order.fulfillment.range}</b></p>
                    </div>
  
                    <table role="presentation" border="0" cellpadding="0" cellspacing="0" class="btn btn-primary" style="border-collapse: separate; mso-table-lspace: 0pt; mso-table-rspace: 0pt; box-sizing: border-box; min-width: 100%; width: 100%;" width="100%">
                        <tbody>
                          <tr>
                            <td align="left" style="font-family: sans-serif; font-size: 15px; vertical-align: top; padding-bottom: 15px;" valign="top">
                              <table role="presentation" border="0" cellpadding="0" cellspacing="0" style="border-collapse: separate; mso-table-lspace: 0pt; mso-table-rspace: 0pt; min-width: auto; width: auto;">
                                <tbody>
                                  <tr>
                                    <td style="font-family: sans-serif; font-size: 15px; vertical-align: top; border-radius: 5px; text-align: center; background-color: #14192d;" valign="top" align="center" bgcolor="#14192d"><a href=\"${SITE_URL}/admin/presales/${presale.presaleId}#orders\" target="_blank" style="border: solid 1px #14192d; border-radius: 5px; box-sizing: border-box; cursor: pointer; display: inline-block; font-size: 15px; font-weight: bold; margin: 0; padding: 8px 12px; text-decoration: none; background-color: #14192d; border-color: #14192d; color: #ffffff;">View details</a></td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                  </td>
                  
                </tr>
  
                
              <!-- END MAIN CONTENT AREA -->
              </table>
  
              <!-- START FOOTER -->
              <div class="footer" style="clear: both; Margin-top: 10px; width: 100%;">
                <table role="presentation" border="0" cellpadding="0" cellspacing="0" style="border-collapse: separate; mso-table-lspace: 0pt; mso-table-rspace: 0pt; min-width: 100%; width: 100%;" width="100%">
                  <tr>
                    <td class="content-block" style="font-family: sans-serif; vertical-align: top; padding-bottom: 10px; padding-top: 10px; color: #9a9ea6; font-size: 12px;" valign="top">
                      <hr style="border: 0; border-bottom: 1px solid #d1d1d1; Margin: 20px 0;">
                      © 2024 Flowly LLC. All rights reserved.
                    </td>
                  </tr>
                </table>
              </div>
              <!-- END FOOTER -->
  
            <!-- END CENTERED WHITE CONTAINER -->
            </div>
          </td>
          <td style="font-family: sans-serif; font-size: 15px; vertical-align: top;" valign="top">&nbsp;</td>
        </tr>
      </table>
    </body>
  </html>  
  `;
