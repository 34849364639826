import React, { useState, useRef } from "react";
import Hammer from "react-hammerjs";

export const HammerItem: React.FC = ({ children }) => {
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [startPosition, setStartPosition] = useState({ x: 0, y: 0 });
  const [rotation, setRotation] = useState(0);
  const [startRotation, setStartRotation] = useState(0);
  const [scale, setScale] = useState({ x: 1, y: 1 });
  const [startScale, setStartScale] = useState({ x: 1, y: 1 });
  const moveRef = useRef < HTMLDivElement > null;

  const onPanStart = () => {
    setStartPosition({
      x: position.x,
      y: position.y,
    });
  };

  const onPan = (e: HammerInput) => {
    // prevent triggering pan after pinch and rotate
    if (e.maxPointers > 1) {
      return;
    }
    setPosition({
      x: startPosition.x + e.deltaX,
      y: startPosition.y + e.deltaY,
    });
  };

  const onPanEnd = () => {
    setStartPosition({
      x: position.x,
      y: position.y,
    });
  };

  const onPinchStart = () => {
    setStartScale({
      x: scale.x,
      y: scale.y,
    });
  };

  const onPinch = (e: HammerInput) => {
    setScale({
      x: startScale.x * e.scale,
      y: startScale.y * e.scale,
    });
  };

  const onPinchEnd = () => {
    setStartScale({
      x: scale.x,
      y: scale.y,
    });
  };

  const onRotateStart = (e: HammerInput) => {
    setStartRotation(rotation - e.rotation);
  };

  const onRotateMove = (e: HammerInput) => {
    setRotation(startRotation + e.rotation);
  };

  const onRotateEnd = (e: HammerInput) => {
    setStartRotation(rotation - e.rotation);
  };

  return (
    <Hammer
      onPan={onPan}
      onPanStart={onPanStart}
      onPanEnd={onPanEnd}
      onPinch={onPinch}
      onPinchStart={onPinchStart}
      onPinchEnd={onPinchEnd}
      onRotateStart={onRotateStart}
      onRotateMove={onRotateMove}
      onRotateEnd={onRotateEnd}
      options={{
        recognizers: {
          pinch: { enable: true },
          rotate: { enable: true },
        },
      }}
    >
      <div
        ref={moveRef}
        style={{
          marginTop: "48px",
          position: "absolute",
          zIndex: 9,
          backgroundColor: "transparent",
          textAlign: "center",
          touchAction: "none",
          left: `${position.x}px`,
          top: `${position.y}px`,
          transform: `rotate(${rotation}deg) scale(${scale.x}, ${scale.y})`,
        }}
      >
        {children}
      </div>
    </Hammer>
  );
};
