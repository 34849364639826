import { FormikProps } from "formik";
import { BusinessInfo } from "Model/BusinessInfo";

// ui related
import {
  TextField,
  Stack,
  Typography,
  Grid,
  Box,
  RadioGroup,
  FormControl,
  FormLabel,
  InputAdornment,
  FormControlLabel,
  Radio,
  Checkbox,
} from "@mui/material";
import { FormEditor } from "Components/BusinessView/FormEditor";
import { InfoBox, CardStyle } from "Components/AllComponents";

export const FormIntro = ({
  formik,
}: {
  formik: FormikProps<BusinessInfo>;
}) => {
  return (
    <CardStyle>
      <Typography variant="h5" sx={{ textAlign: "center", mb: 2 }}>
        Order Request Form
      </Typography>

      <FormEditor visible>
        <TextField
          fullWidth
          multiline
          label="Form intro"
          margin="dense"
          id="formIntro"
          name="formIntro"
          placeholder="E.g. Once I receive your request, I’ll send you a quote and confirm my availability within 24 hours."
          value={formik.values.formIntro}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          // error={formik.touched.formIntro && Boolean(formik.errors.formIntro)}
          // helperText={formik.touched.formIntro && formik.errors.formIntro}
        />

        <Grid container rowSpacing={1} columnSpacing={2}>
          <Grid item xs={6}>
            <TextField
              label="Starting price"
              fullWidth
              margin="normal"
              required
              type="number"
              id="price"
              onWheel={(e: any) => e.target.blur()}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">/ doz</InputAdornment>
                ),
                inputProps: { min: 1 },
              }}
              value={formik.values.price}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.price && Boolean(formik.errors.price)}
              helperText={formik.touched.price && formik.errors.price}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Minimum order"
              fullWidth
              margin="normal"
              required
              id="minOrder"
              type="number"
              onWheel={(e: any) => e.target.blur()}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">doz</InputAdornment>
                ),
                inputProps: { min: 0.5, step: 0.5 },
              }}
              value={formik.values.minOrder}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.minOrder && Boolean(formik.errors.minOrder)}
              helperText={formik.touched.minOrder && formik.errors.minOrder}
            />
          </Grid>
        </Grid>
      </FormEditor>
    </CardStyle>
  );
};
