import { useEffect } from "react";
import { BusinessInfo } from "Model/BusinessInfo";
import _ from "lodash";
import { FormikProps } from "formik";
import { useAppDispatch } from "Redux/hooks";
import { setBusinessInfoDirty } from "Redux/actionCreators";
import { useConfirmBrowserExit } from "Util/confirmLeave";

// ui related
import { Button, Box, FormControlLabel, Switch } from "@mui/material";
import "react-datepicker/dist/react-datepicker.css";
import { FormSection } from "Components/AllComponents";

// form sections
import { DateNeeded } from "./FormComponents/DateNeeded";
import { Quantity } from "./FormComponents/Quantity";
import { ContactInfo } from "./FormComponents/ContactInfo";
import { Fulfillment } from "./FormComponents/Fulfillment";
import { OrderDetail } from "./FormComponents/OrderDetail";
import { Agreements } from "./FormComponents/Agreements";
import { Flavor } from "./FormComponents/Flavor";
import { BucketLists } from "./FormComponents/BucketLists";
import { Packaging } from "./FormComponents/Packaging";
import { DesignInspirations } from "./FormComponents/DesignInspirations";
import { FormIntro } from "./FormComponents/FormIntro";
import { Questions } from "./FormComponents/Questions";
import { JoinEmail } from "./FormComponents/JoinEmail";

export const QuoteRequestForm = ({
  formik,
  formPublished,
}: {
  formik: FormikProps<BusinessInfo>;
  formPublished: boolean;
}) => {
  const { setEnabled } = useConfirmBrowserExit(
    true,
    "please publish before leaving"
  );
  const dispatch = useAppDispatch();

  useEffect(() => {
    // Submit the form imperatively as an effect as soon as form values.token are 6 digits long
    setEnabled(formik.dirty);
    dispatch(setBusinessInfoDirty(formik.dirty));
  }, [formik.dirty]);

  return (
    <form onSubmit={formik.handleSubmit}>
      <FormIntro formik={formik} />

      {/* ------------- form sections  ------------- */}
      <DateNeeded />
      <Quantity formik={formik} />
      <ContactInfo />
      <Fulfillment />
      <BucketLists formik={formik} />
      <OrderDetail formik={formik} />
      <DesignInspirations />
      <Flavor formik={formik} />
      <Packaging formik={formik} />
      <Agreements formik={formik} />
      <Questions />
      <JoinEmail />

      <Box sx={{ mt: 3, width: "100%", textAlign: "center" }}>
        <Button
          variant="contained"
          type="submit"
          disabled={!formik.dirty && formPublished}
        >
          Publish form
        </Button>
      </Box>
    </form>
  );
};
